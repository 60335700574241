import React, { createContext, useContext, useReducer, useEffect } from 'react';

const GameContext = createContext();

const initialState = {
  players: Array(6).fill().map(() => ({ 
    chips: 1000, 
    cards: [] 
  })),
  communityCards: [],
  pot: 0,
  currentPlayer: 0,
  dealerPosition: 0,
  gamePhase: 'idle',
  deck: []
};

function gameReducer(state, action) {
  console.log('Reducer called with action:', action.type);
  
  switch (action.type) {
    case 'DEAL_CARDS': {
      console.log('Dealing cards...');
      // Create new players array with cards
      const newPlayers = state.players.map(player => ({
        ...player,
        cards: ['back', 'back']  // Give each player 2 cards
      }));
      
      const newState = {
        ...state,
        gamePhase: 'preflop',
        pot: 0,
        communityCards: [],
        players: newPlayers
      };
      
      console.log('Players after deal:', newPlayers);
      console.log('New state:', newState);
      return newState;
    }

    case 'NEXT_PHASE': {
      let newCommunityCards = [...state.communityCards];
      switch (action.phase) {
        case 'flop':
          newCommunityCards = ['back', 'back', 'back'];
          break;
        case 'turn':
          newCommunityCards.push('back');
          break;
        case 'river':
          newCommunityCards.push('back');
          break;
        default:
          break;
      }
      
      const newState = {
        ...state,
        gamePhase: action.phase,
        communityCards: newCommunityCards
      };
      
      console.log('State after phase change:', newState);
      return newState;
    }

    case 'PLACE_BET': {
      return {
        ...state,
        players: state.players.map((player, i) => 
          i === action.playerId 
            ? { ...player, chips: player.chips - action.amount }
            : player
        ),
        pot: state.pot + action.amount
      };
    }

    default:
      return state;
  }
}

export function GameProvider({ children }) {
  const [state, dispatch] = useReducer(gameReducer, initialState);

  // Debug state changes
  useEffect(() => {
    console.log('Game state updated:', state);
    console.log('Current players:', state.players);
    console.log('Current phase:', state.gamePhase);
  }, [state]);

  return (
    <GameContext.Provider value={{ state, dispatch }}>
      {children}
    </GameContext.Provider>
  );
}

export const useGame = () => {
  const context = useContext(GameContext);
  if (context === undefined) {
    throw new Error('useGame must be used within a GameProvider');
  }
  return context;
}; 