import React from 'react';
import * as THREE from 'three';
import { Text } from '@react-three/drei';
import { Shape } from 'three';
import { useSpring, animated } from '@react-spring/three';

function Card({ card, position, delay }) {
  const { pos, rot } = useSpring({
    from: { 
      pos: [position[0], position[1] + 5, position[2]],
      rot: [Math.PI * 0.1, 0, Math.PI * 4]
    },
    to: { 
      pos: position,
      rot: [Math.PI * 0.3, 0, 0]
    },
    config: { 
      mass: 2,
      tension: 200,
      friction: 20,
      bounce: 0.5
    },
    delay: delay
  });

  const getCardColor = () => {
    if (!card) return '#808080';  // Gray for face-down or empty cards
    if (card.suit === 'hearts' || card.suit === 'diamonds') {
      return '#fff5f5';  // Very slight pink tint for red cards
    }
    return '#ffffff';  // White background for black cards
  };

  const getTextAndSuitColor = () => {
    if (!card) return '#000000';
    return card.suit === 'hearts' || card.suit === 'diamonds' ? '#800000' : '#000000';  // Changed to maroon (#800000)
  };

  const createSuitShape = (suit) => {
    const shape = new Shape();
    
    switch (suit) {
      case 'hearts':
        // Heart shape path
        shape.moveTo(0, 0.5);
        shape.bezierCurveTo(-0.5, 0, -0.5, -0.8, 0, -0.5);
        shape.bezierCurveTo(0.5, -0.8, 0.5, 0, 0, 0.5);
        break;
        
      case 'diamonds':
        // Diamond shape path
        shape.moveTo(0, 0.5);
        shape.lineTo(0.5, 0);
        shape.lineTo(0, -0.5);
        shape.lineTo(-0.5, 0);
        shape.lineTo(0, 0.5);
        break;
        
      case 'spades':
        // New normalized SVG path for spades (original coordinates divided by 16)
        shape.moveTo(8/16, 0);  // Top point
        shape.lineTo(1.03553/16, 6.96447/16);
        shape.bezierCurveTo(
          0.372492/16, 7.62751/16,
          0/16, 8.52678/16,
          0/16, 9.46447/16
        );
        shape.lineTo(0/16, 9.54584/16);
        shape.bezierCurveTo(
          0/16, 11.4535/16,
          1.54648/16, 13/16,
          3.45416/16, 13/16
        );
        shape.bezierCurveTo(
          4.1361/16, 13/16,
          4.80278/16, 12.7981/16,
          5.37019/16, 12.4199/16
        );
        shape.lineTo(7.125/16, 11.25/16);
        shape.lineTo(6/16, 15/16);
        shape.lineTo(6/16, 16/16);
        shape.lineTo(10/16, 16/16);
        shape.lineTo(10/16, 15/16);
        shape.lineTo(8.875/16, 11.25/16);
        shape.lineTo(10.6298/16, 12.4199/16);
        shape.bezierCurveTo(
          11.1972/16, 12.7981/16,
          11.8639/16, 13/16,
          12.5458/16, 13/16
        );
        shape.bezierCurveTo(
          14.4535/16, 13/16,
          16/16, 11.4535/16,
          16/16, 9.54584/16
        );
        shape.lineTo(16/16, 9.46447/16);
        shape.bezierCurveTo(
          16/16, 8.52678/16,
          15.6275/16, 7.62751/16,
          14.9645/16, 6.96447/16
        );
        shape.lineTo(8/16, 0);
        shape.closePath();
        break;
        
      case 'clubs':
        // Normalized SVG path for clubs
        shape.moveTo(47/62, 18.875/62);
        shape.bezierCurveTo(
          46.956/62, 18.875/62,
          46.914/62, 18.883/62,
          46.87/62, 18.883/62
        );
        shape.bezierCurveTo(
          46.946/62, 18.264/62,
          47/62, 17.639/62,
          47/62, 17/62
        );
        shape.bezierCurveTo(
          47/62, 8.717/62,
          40.284/62, 2/62,
          32/62, 2/62
        );
        shape.bezierCurveTo(
          23.714/62, 2/62,
          17/62, 8.717/62,
          17/62, 17/62
        );
        shape.bezierCurveTo(
          17/62, 17.639/62,
          17.053/62, 18.264/62,
          17.13/62, 18.883/62
        );
        shape.bezierCurveTo(
          17.086/62, 18.883/62,
          17.044/62, 18.875/62,
          17/62, 18.875/62
        );
        shape.bezierCurveTo(
          8.714/62, 18.875/62,
          2/62, 25.592/62,
          2/62, 33.875/62
        );
        shape.bezierCurveTo(
          2/62, 42.158/62,
          8.714/62, 48.875/62,
          17/62, 48.875/62
        );
        shape.bezierCurveTo(
          21.807/62, 48.875/62,
          26.075/62, 46.604/62,
          28.819/62, 43.087/62
        );
        shape.lineTo(28.25/62, 47.938/62);
        shape.bezierCurveTo(
          27.745/62, 51.527/62,
          25.256/62, 54.81/62,
          21.687/62, 55.438/62
        );
        shape.lineTo(17/62, 56.375/62);
        shape.lineTo(17/62, 62/62);
        shape.lineTo(47/62, 62/62);
        shape.lineTo(47/62, 56.375/62);
        shape.lineTo(42.312/62, 55.437/62);
        shape.bezierCurveTo(
          38.742/62, 54.809/62,
          36.252/62, 51.528/62,
          35.749/62, 47.937/62
        );
        shape.lineTo(35.178/62, 43.086/62);
        shape.bezierCurveTo(
          37.922/62, 46.604/62,
          42.191/62, 48.874/62,
          46.999/62, 48.874/62
        );
        shape.bezierCurveTo(
          55.283/62, 48.874/62,
          61.999/62, 42.157/62,
          61.999/62, 33.874/62
        );
        shape.bezierCurveTo(
          61.999/62, 25.591/62,
          55.283/62, 18.874/62,
          46.999/62, 18.874/62
        );
        break;
        
      default:
        return null;
    }
    
    return shape;
  };

  const SuitShape = ({ suit, position, scale = 1, rotation = [0, 0, 0], color }) => {
    const shape = createSuitShape(suit);
    
    if (!shape) return null;

    return (
      <group position={position} rotation={rotation}>
        <mesh scale={[0.3 * scale, 0.3 * scale, 0.001]}>
          <shapeGeometry args={[shape]} />
          <meshBasicMaterial color={color} side={THREE.DoubleSide} />
        </mesh>
      </group>
    );
  };

  const cardWidth = 0.875;
  const cardHeight = 1.25;

  return (
    <animated.group 
      position={pos}
      rotation={rot}
    >
      {/* Card base with border */}
      <mesh rotation={[-Math.PI / 2, 0, 0]}>
        <planeGeometry args={[cardWidth, cardHeight]} />
        <meshStandardMaterial 
          color="#000000" 
          side={THREE.DoubleSide}
        />
      </mesh>
      
      {/* Card face */}
      <mesh position={[0, 0.001, 0]} rotation={[-Math.PI / 2, 0, 0]}>
        <planeGeometry args={[cardWidth - 0.05, cardHeight - 0.05]} />
        <meshStandardMaterial 
          color={getCardColor()} 
          side={THREE.DoubleSide}
          roughness={0.2}
          metalness={0.1}
        />
      </mesh>

      {/* Card Text and Suits */}
      <group rotation={[-Math.PI / 2, 0, 0]}>
        {/* Top Left rank */}
        <group position={[-0.25, 0.35, 0.002]}>
          <Text
            fontSize={card.rank.length > 1 ? 0.15 : 0.17}
            color={getTextAndSuitColor()}
            anchorX="center"
            anchorY="middle"
            maxWidth={0.15}
            font="/fonts/Arial-Bold.ttf"
            fontWeight="bold"
            strokeWidth={0.005}
            strokeColor={getTextAndSuitColor()}
          >
            {card.rank}
          </Text>
        </group>

        {/* Bottom Right rank */}
        <group position={[0.25, -0.35, 0.002]} rotation={[0, 0, Math.PI]}>
          <Text
            fontSize={card.rank.length > 1 ? 0.15 : 0.17}
            color={getTextAndSuitColor()}
            anchorX="center"
            anchorY="middle"
            maxWidth={0.15}
            font="/fonts/Arial-Bold.ttf"
            fontWeight="bold"
            strokeWidth={0.005}
            strokeColor={getTextAndSuitColor()}
          >
            {card.rank}
          </Text>
        </group>

        {/* Top Suit */}
        <SuitShape 
          suit={card.suit}
          position={[0, 0.15, 0.002]}
          scale={0.7}
          color={getTextAndSuitColor()}
        />

        {/* Bottom Suit */}
        <SuitShape 
          suit={card.suit}
          position={[0, -0.15, 0.002]}
          scale={0.7}
          rotation={[0, 0, Math.PI]}
          color={getTextAndSuitColor()}
        />
      </group>

      {/* Shadow */}
      <mesh
        rotation={[-Math.PI / 2, 0, 0]}
        position={[0, -0.001, 0]}
        receiveShadow
      >
        <planeGeometry args={[cardWidth + 0.1, cardHeight + 0.1]} />
        <shadowMaterial opacity={0.2} />
      </mesh>
    </animated.group>
  );
}

export const Cards = ({ gameState }) => {
  if (!gameState) return null;

  const getCommunityCardPosition = (index) => {
    const totalWidth = 3.5;
    const startX = -totalWidth / 2 - 0.5;
    const spacing = 1.1;
    return [
      startX + (index * spacing), 
      0.5, // Increased from 0.11 to 0.5 to move cards up
      0
    ];
  };

  return (
    <group>
      {/* Community Cards with Enhanced Animation */}
      {gameState.communityCards?.map((card, index) => (
        <Card
          key={`community-${index}`}
          card={card}
          position={getCommunityCardPosition(index)}
          delay={index * 300}
        />
      ))}
    </group>
  );
}; 