import React, { useRef, useEffect } from 'react';
import { Text } from '@react-three/drei';
import * as THREE from 'three';
import { useFrame, useThree } from '@react-three/fiber';
import HandEvaluator from '../../utils/HandEvaluator';
import gsap from 'gsap';
import { useNavigate } from 'react-router-dom';

// Define fonts with Casino theme
const FONTS = {
  TITLE: '/fonts/Quicksand-Bold.ttf',      // For player names
  NUMBERS: '/fonts/Casino3DFilled.ttf',    // For chip counts only
  ACTION: '/fonts/Quicksand-SemiBold.ttf', // For status messages
  HAND: '/fonts/Quicksand-Medium.ttf',     // For hand types
  WIN: '/fonts/Quicksand-Regular.ttf'      // For win probability
};

// Create a single instance of HandEvaluator
const handEvaluator = new HandEvaluator();

// Add this near the top of the file
const VIEW_LAYOUTS = {
  CIRCULAR: 'CIRCULAR',
  LINEAR: 'LINEAR',
  HOST_2D: '2D VIEW'
};

function createShieldShape() {
  const shape = new THREE.Shape();
  
  // Shield/hexagonal shape path - slightly wider
  shape.moveTo(0, 0.8);    // Top point
  shape.lineTo(0.6, 0.6);  // Top right - wider
  shape.lineTo(0.6, -0.4); // Bottom right - wider
  shape.lineTo(0, -0.8);   // Bottom point - longer
  shape.lineTo(-0.6, -0.4); // Bottom left - wider
  shape.lineTo(-0.6, 0.6);  // Top left - wider
  shape.lineTo(0, 0.8);    // Back to top
  
  return shape;
}

function HoleCard({ card, position, rotation }) {
  const cardWidth = 0.7;
  const cardHeight = 1.0;

  const getCardColor = () => {
    if (!card) return '#808080';  // Gray for face-down or empty cards
    if (card.suit === 'hearts' || card.suit === 'diamonds') {
      return '#fff5f5';  // Very slight pink tint for red cards
    }
    return '#ffffff';  // White background for black cards
  };

  const getTextAndSuitColor = () => {
    if (!card) return '#000000';
    return card.suit === 'hearts' || card.suit === 'diamonds' ? '#800000' : '#000000';
  };

  const createSuitShape = (suit) => {
    const shape = new THREE.Shape();
    
    switch (suit) {
      case 'hearts':
        // Heart shape path
        shape.moveTo(0, 0.5);
        shape.bezierCurveTo(-0.5, 0, -0.5, -0.8, 0, -0.5);
        shape.bezierCurveTo(0.5, -0.8, 0.5, 0, 0, 0.5);
        break;
        
      case 'diamonds':
        // Diamond shape path
        shape.moveTo(0, 0.5);
        shape.lineTo(0.5, 0);
        shape.lineTo(0, -0.5);
        shape.lineTo(-0.5, 0);
        shape.lineTo(0, 0.5);
        break;
        
      case 'spades':
        // New normalized SVG path for spades (original coordinates divided by 16)
        shape.moveTo(8/16, 0);  // Top point
        shape.lineTo(1.03553/16, 6.96447/16);
        shape.bezierCurveTo(
          0.372492/16, 7.62751/16,
          0/16, 8.52678/16,
          0/16, 9.46447/16
        );
        shape.lineTo(0/16, 9.54584/16);
        shape.bezierCurveTo(
          0/16, 11.4535/16,
          1.54648/16, 13/16,
          3.45416/16, 13/16
        );
        shape.bezierCurveTo(
          4.1361/16, 13/16,
          4.80278/16, 12.7981/16,
          5.37019/16, 12.4199/16
        );
        shape.lineTo(7.125/16, 11.25/16);
        shape.lineTo(6/16, 15/16);
        shape.lineTo(6/16, 16/16);
        shape.lineTo(10/16, 16/16);
        shape.lineTo(10/16, 15/16);
        shape.lineTo(8.875/16, 11.25/16);
        shape.lineTo(10.6298/16, 12.4199/16);
        shape.bezierCurveTo(
          11.1972/16, 12.7981/16,
          11.8639/16, 13/16,
          12.5458/16, 13/16
        );
        shape.bezierCurveTo(
          14.4535/16, 13/16,
          16/16, 11.4535/16,
          16/16, 9.54584/16
        );
        shape.lineTo(16/16, 9.46447/16);
        shape.bezierCurveTo(
          16/16, 8.52678/16,
          15.6275/16, 7.62751/16,
          14.9645/16, 6.96447/16
        );
        shape.lineTo(8/16, 0);
        shape.closePath();
        break;
        
      case 'clubs':
        // Normalized SVG path for clubs
        shape.moveTo(47/62, 18.875/62);
        shape.bezierCurveTo(
          46.956/62, 18.875/62,
          46.914/62, 18.883/62,
          46.87/62, 18.883/62
        );
        shape.bezierCurveTo(
          46.946/62, 18.264/62,
          47/62, 17.639/62,
          47/62, 17/62
        );
        shape.bezierCurveTo(
          47/62, 8.717/62,
          40.284/62, 2/62,
          32/62, 2/62
        );
        shape.bezierCurveTo(
          23.714/62, 2/62,
          17/62, 8.717/62,
          17/62, 17/62
        );
        shape.bezierCurveTo(
          17/62, 17.639/62,
          17.053/62, 18.264/62,
          17.13/62, 18.883/62
        );
        shape.bezierCurveTo(
          17.086/62, 18.883/62,
          17.044/62, 18.875/62,
          17/62, 18.875/62
        );
        shape.bezierCurveTo(
          8.714/62, 18.875/62,
          2/62, 25.592/62,
          2/62, 33.875/62
        );
        shape.bezierCurveTo(
          2/62, 42.158/62,
          8.714/62, 48.875/62,
          17/62, 48.875/62
        );
        shape.bezierCurveTo(
          21.807/62, 48.875/62,
          26.075/62, 46.604/62,
          28.819/62, 43.087/62
        );
        shape.lineTo(28.25/62, 47.938/62);
        shape.bezierCurveTo(
          27.745/62, 51.527/62,
          25.256/62, 54.81/62,
          21.687/62, 55.438/62
        );
        shape.lineTo(17/62, 56.375/62);
        shape.lineTo(17/62, 62/62);
        shape.lineTo(47/62, 62/62);
        shape.lineTo(47/62, 56.375/62);
        shape.lineTo(42.312/62, 55.437/62);
        shape.bezierCurveTo(
          38.742/62, 54.809/62,
          36.252/62, 51.528/62,
          35.749/62, 47.937/62
        );
        shape.lineTo(35.178/62, 43.086/62);
        shape.bezierCurveTo(
          37.922/62, 46.604/62,
          42.191/62, 48.874/62,
          46.999/62, 48.874/62
        );
        shape.bezierCurveTo(
          55.283/62, 48.874/62,
          61.999/62, 42.157/62,
          61.999/62, 33.874/62
        );
        shape.bezierCurveTo(
          61.999/62, 25.591/62,
          55.283/62, 18.874/62,
          46.999/62, 18.874/62
        );
        break;
        
      default:
        return null;
    }
    
    return shape;
  };

  function SuitShape({ suit, position, rotation, scale, color }) {
    if (!suit) return null;
    const shape = createSuitShape(suit);
    if (!shape) return null;

    return (
      <group position={position} rotation={rotation}>
        <mesh scale={[0.3 * (scale || 1), 0.3 * (scale || 1), 0.001]}>
          <shapeGeometry args={[shape]} />
          <meshBasicMaterial color={color} side={THREE.DoubleSide} />
        </mesh>
      </group>
    );
  }

  return (
    <group position={position} rotation={rotation}>
      {/* Card base */}
      <mesh>
        <planeGeometry args={[cardWidth, cardHeight]} />
        <meshStandardMaterial 
          color={getCardColor()} 
          side={THREE.DoubleSide}
          roughness={0.2}
          metalness={0.1}
        />
      </mesh>

      {card && (
        <group rotation={[0, 0, 0]}>
          {/* Card Text and Suits */}
          <group>
            {/* Top Left rank */}
            <Text
              position={[-0.25, 0.35, 0.002]}
              fontSize={card.rank.length > 1 ? 0.15 : 0.17}
              color={getTextAndSuitColor()}
              anchorX="center"
              anchorY="middle"
              maxWidth={0.15}
              font="/fonts/Arial-Bold.ttf"
              fontWeight="bold"
            >
              {card.rank}
            </Text>

            {/* Bottom Right rank */}
            <group position={[0.25, -0.35, 0.002]} rotation={[0, 0, Math.PI]}>
              <Text
                fontSize={card.rank.length > 1 ? 0.15 : 0.17}
                color={getTextAndSuitColor()}
                anchorX="center"
                anchorY="middle"
                maxWidth={0.15}
                font="/fonts/Arial-Bold.ttf"
                fontWeight="bold"
              >
                {card.rank}
              </Text>
            </group>

            {/* Center Suits - Reversed orientation */}
            <SuitShape 
              suit={card.suit}
              position={[0, 0.15, 0.002]}
              scale={0.7}
              rotation={[0, 0, Math.PI + (-rotation[2])]}  // Add 180 degrees (Math.PI)
              color={getTextAndSuitColor()}
            />
            <SuitShape 
              suit={card.suit}
              position={[0, -0.15, 0.002]}
              scale={0.7}
              rotation={[0, 0, 2 * Math.PI + (-rotation[2])]}  // Add 360 degrees (2 * Math.PI)
              color={getTextAndSuitColor()}
            />
          </group>
        </group>
      )}

      {/* Shadow */}
      <mesh position={[0, 0, -0.001]} receiveShadow>
        <planeGeometry args={[cardWidth + 0.1, cardHeight + 0.1]} />
        <shadowMaterial opacity={0.2} />
      </mesh>
    </group>
  );
}

function WinnerIndicator({ isWinner }) {
  const shieldRef = useRef();
  
  // Create shield shape for the indicator
  const createIndicatorShape = () => {
    const shape = new THREE.Shape();
    
    // Shield outline (simplified version of the player card shield)
    shape.moveTo(0, 1);
    shape.bezierCurveTo(-0.8, 1, -1.1, 0.2, -1.1, -0.3);
    shape.bezierCurveTo(-1.1, -0.8, -0.6, -1, 0, -1);
    shape.bezierCurveTo(0.6, -1, 1.1, -0.8, 1.1, -0.3);
    shape.bezierCurveTo(1.1, 0.2, 0.8, 1, 0, 1);
    
    return shape;
  };

  // Animate the shield
  useFrame((state) => {
    if (shieldRef.current && isWinner) {
      // Faster pulse scale animation with larger range
      const scale = 1.15 + Math.sin(state.clock.elapsedTime * 6) * 0.15;
      shieldRef.current.scale.set(scale, scale, 1);
      
      // Faster rotation
      shieldRef.current.rotation.z += 0.03;
    }
  });

  if (!isWinner) return null;

  return (
    <group position={[0, 0, 0.05]}>
      {/* Outer glowing shield */}
      <mesh ref={shieldRef}>
        <shapeGeometry args={[createIndicatorShape()]} />
        <meshBasicMaterial 
          color="#ffd700" // Gold color
          transparent
          opacity={0.4}
          side={THREE.DoubleSide}
        />
      </mesh>
      
      {/* Inner solid shield */}
      <mesh scale={[0.95, 0.95, 1]}>
        <shapeGeometry args={[createIndicatorShape()]} />
        <meshBasicMaterial 
          color="#ff6b00" // Orange color
          transparent
          opacity={0.6}
          side={THREE.DoubleSide}
        />
      </mesh>

      {/* Additional glow effect */}
      <mesh scale={[1.05, 1.05, 1]}>
        <shapeGeometry args={[createIndicatorShape()]} />
        <meshBasicMaterial 
          color="#ffd700" // Gold color
          transparent
          opacity={0.3}
          side={THREE.DoubleSide}
        />
      </mesh>
    </group>
  );
}

const calculateChipStacks = (amount) => {
  const chipTypes = [
    { value: 100, color: 0x000000 }, // Black chips
    { value: 25, color: 0x00ff00 },  // Green chips
    { value: 10, color: 0x0000ff },  // Blue chips
    { value: 5, color: 0xff0000 }    // Red chips
  ];

  const stacks = [];
  let remainingAmount = amount;

  chipTypes.forEach(({ value, color }) => {
    const count = Math.floor(remainingAmount / value);
    if (count > 0) {
      stacks.push({
        value,
        color,
        count: Math.min(count, 10) // Limit stack height
      });
      remainingAmount -= count * value;
    }
  });

  return stacks;
};

export function PlayerCard({ player, position, isCurrentPlayer, isDealer, gameState }) {
  const cardDepth = 0.08;
  const cardRef = useRef();
  const chipsRef = useRef();
  const lastWinTimeRef = useRef(null);
  const { camera } = useThree();

  // Debug log for every render
  useEffect(() => {
    console.log('PlayerCard render:', {
      phase: gameState?.phase,
      winners: gameState?.winners,
      playerId: player?.userId,
      isShowdown: gameState?.phase === 'SHOWDOWN',
      hasWinners: Boolean(gameState?.winners),
    });
  });

  // Handle winning animation
  useEffect(() => {
    if (gameState?.phase !== 'SHOWDOWN') return;
    if (!gameState.winners) return;
    
    const winner = gameState.winners.find(w => w.userId === player.userId);
    if (!winner) return;

    const currentTime = Date.now();
    if (lastWinTimeRef.current && currentTime - lastWinTimeRef.current < 10000) {
      return;
    }
    
    lastWinTimeRef.current = currentTime;

    // Store original camera state
    const originalPosition = camera.position.clone();

    // Calculate target position for camera
    const distance = 8; // Increased distance to see full card
    const targetPosition = new THREE.Vector3(
      position[0],
      position[1] + 3, // Higher elevation
      position[2] + distance // Move camera straight back
    );

    // Animate camera
    gsap.timeline()
      .to(camera.position, {
        x: targetPosition.x,
        y: targetPosition.y,
        z: targetPosition.z,
        duration: 1,
        ease: "power2.inOut",
        onUpdate: () => {
          camera.lookAt(position[0], position[1], position[2]);
        }
      })
      // Hold view
      .to({}, { duration: 2 })
      // Return to original position
      .to(camera.position, {
        x: originalPosition.x,
        y: originalPosition.y,
        z: originalPosition.z,
        duration: 1,
        ease: "power2.inOut",
        onComplete: () => {
          // Reset camera look at original scene center
          camera.lookAt(0, 0, 0);
        }
      });

    // Create winning chips with improved animation
    const winAmount = winner.amount;
    const chipCount = Math.min(Math.ceil(winAmount / 10), 20);

    for (let i = 0; i < chipCount; i++) {
      const chip = new THREE.Mesh(
        new THREE.CylinderGeometry(0.15, 0.15, 0.03, 32),
        new THREE.MeshStandardMaterial({
          color: 0xffd700, // Gold color
          metalness: 0.8,
          roughness: 0.2,
          emissive: 0x996515, // Subtle glow
          emissiveIntensity: 0.2
        })
      );

      // More spread out starting positions
      const angle = (Math.random() * Math.PI * 2);
      const radius = 1 + Math.random() * 0.5;
      chip.position.set(
        Math.cos(angle) * radius,  // Circular spread
        4 + Math.random(),         // Higher starting point
        Math.sin(angle) * radius   // Circular spread
      );

      // Add random rotation
      chip.rotation.set(
        Math.random() * Math.PI,
        Math.random() * Math.PI,
        Math.random() * Math.PI
      );

      if (chipsRef.current) {
        chipsRef.current.add(chip);

        // More dynamic animation
        gsap.to(chip.position, {
          x: 0.8,
          y: 0.03 + (i * 0.05),
          z: 0,
          duration: 1.5 + Math.random() * 0.5, // Varied duration
          delay: i * 0.05,                     // Faster sequence
          ease: "bounce.out",
          onComplete: () => {
            // Add settling rotation
            gsap.to(chip.rotation, {
              x: 0,
              y: 0,
              z: 0,
              duration: 0.3,
              ease: "power2.out"
            });
          }
        });

        // Animate rotation during fall
        gsap.to(chip.rotation, {
          x: Math.random() * Math.PI * 4,
          y: Math.random() * Math.PI * 4,
          z: Math.random() * Math.PI * 4,
          duration: 1.5 + Math.random() * 0.5,
          delay: i * 0.05,
          ease: "power1.inOut"
        });
      }
    }

    // Cleanup chips after animation
    setTimeout(() => {
      if (chipsRef.current) {
        gsap.to(chipsRef.current.children, {
          opacity: 0,
          duration: 0.5,
          stagger: 0.02,
          onComplete: () => {
            while(chipsRef.current?.children.length > 0) {
              chipsRef.current.remove(chipsRef.current.children[0]);
            }
          }
        });
      }
    }, 4000);

  }, [gameState?.phase, gameState?.winners, player.userId, position, camera]);

  const [x, y, z] = position;

  // Get hand display
  const getHandDisplay = (playerCards, communityCards = []) => {
    if (!playerCards || playerCards.length !== 2) return '';
    
    try {
      const handEvaluation = handEvaluator.evaluatePlayerHand(playerCards, communityCards);
      return handEvaluation.name;
    } catch (error) {
      console.error("Error getting hand display:", error);
      return '';
    }
  };

  // Calculate win probability with null checks
  const calculateWinProbability = (playerCards, communityCards = []) => {
    if (!playerCards || !Array.isArray(playerCards) || playerCards.length !== 2) {
      return 0;
    }

    try {
      // Add null checks for gameState
      if (!gameState?.players) {
        return 0;
      }

      const allPlayerCards = gameState.players
        .filter(p => p.cards && p.cards.length === 2)
        .map(p => p.cards);

      return handEvaluator.calculateWinProbability(
        playerCards, 
        communityCards || [], 
        allPlayerCards
      );
    } catch (error) {
      console.error("Error calculating win probability:", error);
      return 0;
    }
  };

  // Animation for active player
  useFrame((state) => {
    if (cardRef.current && isCurrentPlayer) {
      const hoverOffset = Math.sin(state.clock.elapsedTime * 3) * 0.5;
      cardRef.current.position.set(x, y + hoverOffset, z);
    } else if (cardRef.current) {
      cardRef.current.position.set(x, y, z);
    }
  });

  // Debug log to verify isCurrentPlayer
  console.log('Player:', player.username, 'isCurrentPlayer:', isCurrentPlayer);

  // Update card positions to overlap like held cards
  const getHoleCardPositions = () => {
    const cardSpacing = 0.6; // Reduced spacing for overlap
    const heightOffset = 0.9; // Small lift above the shield
    const angleOffset = 0.15; // Angle of the cards
    
    return [
      {
        position: [-cardSpacing/2, heightOffset, cardDepth + 0.001],
        rotation: [0, 0, angleOffset]  // Tilt right
      },
      {
        position: [cardSpacing/2, heightOffset, cardDepth],  // Slightly behind first card
        rotation: [0, 0, -angleOffset] // Tilt left
      }
    ];
  };

  // Generate gradient colors based on player's seat
  const getGradientColors = () => {
    const colors = [
      "#ff6b00", // Orange
      "#4CAF50", // Green
      "#2196F3", // Blue
      "#9C27B0", // Purple
      "#f44336", // Red
      "#795548"  // Brown
    ];
    return colors[player.seatIndex % colors.length];
  };

  const getPlayerStatus = () => {
    if (player.folded) return "FOLD";
    if (player.isAllIn) return "ALL IN";
    if (isCurrentPlayer) return "ACTIVE";
    return "";
  };

  // Create scaled shield shape based on win probability
  const createScaledShieldShape = (probability) => {
    const shape = new THREE.Shape();
    const scale = probability / 100;  // Convert probability to 0-1 scale
    
    // Shield/hexagonal shape path - scaled vertically based on probability
    shape.moveTo(0, 0.8 * scale);    // Top point - scaled
    shape.lineTo(0.6, 0.6 * scale);  // Top right - scaled
    shape.lineTo(0.6, -0.4);         // Bottom right - fixed
    shape.lineTo(0, -0.8);           // Bottom point - fixed
    shape.lineTo(-0.6, -0.4);        // Bottom left - fixed
    shape.lineTo(-0.6, 0.6 * scale); // Top left - scaled
    shape.lineTo(0, 0.8 * scale);    // Back to top - scaled
    
    return shape;
  };

  // Check if player is a winner
  const isWinner = gameState?.phase === 'SHOWDOWN' && 
    gameState?.winners?.some(w => w.userId === player.userId);

  return (
    <group position={position} ref={cardRef}>
      {/* Winner Indicator */}
      <WinnerIndicator isWinner={isWinner} />

      {/* Player Info Card (existing shield shape) */}
      <mesh rotation={[0, 0, 0]} castShadow>
        <extrudeGeometry 
          args={[
            createShieldShape(),
            {
              depth: cardDepth,
              bevelEnabled: true,
              bevelThickness: 0.02,
              bevelSize: 0.02,
              bevelSegments: 3
            }
          ]}
        />
        <meshPhongMaterial 
          color={isCurrentPlayer ? "#2a2a2a" : "#1a1a1a"}
          shininess={100}
          specular="#333333"
        />
      </mesh>

      {/* Top Colored Section */}
      <mesh position={[0, 0.2, cardDepth/2 + 0.001]} scale={[0.95, 0.95, 1]}>
        <shapeGeometry args={[createShieldShape()]} />
        <meshPhongMaterial 
          color={getGradientColors()}
          shininess={50}
          opacity={0.9}
          transparent
        />
      </mesh>

      {/* Player Info - Increased Y positions */}
      <group position={[0, 0, cardDepth/2 + 0.05]}>
        {/* Player Name - Reduced outline */}
        <Text
          position={[0, 0.3, 0.06]}
          fontSize={0.18}
          color="#ffffff"
          anchorX="center"
          anchorY="middle"
          font={FONTS.TITLE}
          outlineWidth={0.02}
          outlineColor="#000000"
        >
          {(player.username || 'Unknown').toUpperCase()}
        </Text>

        {/* Win Probability Shield Fill - Updated position to align with main shield */}
        <mesh 
          position={[0, 0, cardDepth/2 + 0.002]}  // Removed -0.8 offset to align with main shield
        >
          <shapeGeometry args={[createScaledShieldShape(
            calculateWinProbability(player.cards, gameState?.communityCards || [])
          )]} />
          <meshPhongMaterial 
            color={
              calculateWinProbability(player.cards, gameState?.communityCards || []) > 75 ? "#00ff00" :
              calculateWinProbability(player.cards, gameState?.communityCards || []) > 50 ? "#ffff00" :
              calculateWinProbability(player.cards, gameState?.communityCards || []) > 25 ? "#ffa500" :
              "#ff4500"
            }
            opacity={0.6}
            transparent
            shininess={50}
          />
        </mesh>

        {/* Win Probability Text - Reduced outline */}
        <Text
          position={[-0.4, -0.4, cardDepth/2 + 0.06]}
          fontSize={0.15}
          color="#ffffff"
          anchorX="right"
          anchorY="middle"
          font={FONTS.TITLE}
          outlineWidth={0.02}
          outlineColor="#000000"
        >
          {`${calculateWinProbability(player.cards, gameState?.communityCards || []).toFixed(1)}%`}
        </Text>

        {/* Hand Type - Reduced outline */}
        <Text
          position={[0, -0.1, 0.06]}
          fontSize={0.15}
          color="#ffffff"
          anchorX="center"
          anchorY="middle"
          font={FONTS.HAND}
          outlineWidth={0.02}
          outlineColor="#000000"
        >
          {getHandDisplay(player.cards, gameState?.communityCards || [])}
        </Text>

        {/* Player Status - Reduced outline */}
        <Text
          position={[0, -0.45, 0.05]}
          fontSize={0.15}
          color={player.folded ? "#ff0000" : "#ffffff"}
          anchorX="center"
          anchorY="middle"
          font={FONTS.ACTION}
          outlineWidth={0.02}
          outlineColor="#000000"
        >
          {getPlayerStatus()}
        </Text>
      </group>

      {/* Hole Cards */}
      {player.cards && getHoleCardPositions().map((cardPos, index) => (
        <HoleCard
          key={`hole-card-${index}`}
          card={player.cards[index]}
          position={cardPos.position}
          rotation={cardPos.rotation}
        />
      ))}

      {/* Regular chip stacks */}
      {player.chips > 0 && (
        <group>
          {/* Chip stack */}
          <group position={[0.8, 0, 0]}>
            {calculateChipStacks(player.chips).map((stack, stackIndex) => (
              Array(stack.count).fill(null).map((_, chipIndex) => (
                <mesh
                  key={`chip-${stackIndex}-${chipIndex}`}
                  position={[0, 0.03 + chipIndex * 0.05, 0]}
                >
                  <cylinderGeometry args={[0.15, 0.15, 0.03, 32]} />
                  <meshStandardMaterial 
                    color={stack.color}
                    metalness={0.8}
                    roughness={0.2}
                  />
                </mesh>
              ))
            ))}
          </group>

          {/* Chip count text - Reduced outline */}
          <Text
            position={[0.8, -0.2, 0.06]}
            fontSize={0.15}
            color="#00ff00"
            anchorX="center"
            anchorY="middle"
            font={FONTS.NUMBERS}
            outlineWidth={0.02}
            outlineColor="#000000"
          >
            {player.chips?.toLocaleString() || 0}
          </Text>
        </group>
      )}

      {/* Container for animated winning chips */}
      <group position={[0.8, 0, 0]} ref={chipsRef} />
    </group>
  );
}

export function PlayerCards({ gameState, viewLayout = VIEW_LAYOUTS.CIRCULAR }) {
  const navigate = useNavigate();

  // Fix the path to use host/table
  useEffect(() => {
    if (viewLayout === VIEW_LAYOUTS.HOST_2D && gameState?.gameId) {
      navigate(`/host/table/${gameState.gameId}`);
    }
  }, [viewLayout, gameState?.gameId, navigate]);

  if (!gameState || !gameState.players) return null;
  if (viewLayout === VIEW_LAYOUTS.HOST_2D) return null;  // Don't render while redirecting

  const getPlayerPosition = (seatIndex) => {
    if (viewLayout === VIEW_LAYOUTS.LINEAR) {
      const playerCount = Object.keys(gameState.players).length;
      
      // Adjust spacing and starting position based on player count
      const baseSpacing = 2.5;  // Base spacing between players
      const spacing = playerCount <= 3 ? baseSpacing * 1.5 : baseSpacing; // Wider spacing for fewer players
      const totalWidth = (playerCount - 1) * spacing;
      const startX = -totalWidth / 2; // Center the line of players
      
      // Base position values
      const baseY = 1.5;
      const baseZ = -2;
      
      // Calculate position with dynamic arc
      const playerX = startX + (parseInt(seatIndex) * spacing);
      
      // Calculate arc height based on player count and position
      const maxArcHeight = playerCount <= 3 ? 0.3 : 0.5; // Less arc for fewer players
      const normalizedPosition = playerX / (totalWidth / 2); // Position from -1 to 1
      const arcHeight = maxArcHeight * (1 - Math.pow(normalizedPosition, 2));
      
      // Adjust Y and Z based on arc
      const adjustedY = baseY + arcHeight;
      const adjustedZ = baseZ + (arcHeight * 0.5);

      return [
        playerX,
        adjustedY,
        adjustedZ
      ];
    }

    // Original circular layout logic
    let angle, radiusX, radiusZ;
    const baseRadius = 3 * 1.25;
    const playerOffset = 1.2;
    const heightOffset = 1;
    
    // Create elliptical shape by using different X and Z multipliers
    const xMultiplier = 1.1;  // Wider horizontally
    const zMultiplier = 1.0;  // Standard depth
    
    switch(parseInt(seatIndex)) {
      case 0: // Leftmost position
        angle = (130 * Math.PI) / 180;  // 130°
        radiusX = (baseRadius + playerOffset) * xMultiplier;
        radiusZ = (baseRadius + playerOffset) * zMultiplier;
        return [
          radiusX * Math.cos(angle),
          heightOffset,
          radiusZ * Math.sin(angle)
        ];
      case 1: // Center-left (higher position)
        angle = (155 * Math.PI) / 180;  // 155°
        radiusX = (baseRadius + playerOffset * 1.3) * xMultiplier;
        radiusZ = (baseRadius + playerOffset * 1.3) * zMultiplier;
        return [
          radiusX * Math.cos(angle),
          heightOffset * 2.5,  // Increased height for P1
          radiusZ * Math.sin(angle)
        ];
      case 2: // Center-left of middle
        angle = (175 * Math.PI) / 180;  // 175°
        radiusX = (baseRadius + playerOffset) * xMultiplier;
        radiusZ = (baseRadius + playerOffset) * zMultiplier;
        return [
          radiusX * Math.cos(angle),
          heightOffset,
          radiusZ * Math.sin(angle)
        ];
      case 3: // Center-right of middle
        angle = (190 * Math.PI) / 180;  // 190°
        radiusX = (baseRadius + playerOffset) * xMultiplier;
        radiusZ = (baseRadius + playerOffset) * zMultiplier;
        return [
          radiusX * Math.cos(angle),
          heightOffset,
          radiusZ * Math.sin(angle)
        ];
      case 4: // Between center-right and right (higher position)
        angle = (210 * Math.PI) / 180;  // 210°
        radiusX = (baseRadius + playerOffset * 1.3) * xMultiplier;
        radiusZ = (baseRadius + playerOffset * 1.3) * zMultiplier;
        return [
          radiusX * Math.cos(angle),
          heightOffset * 2.5,  // Increased height for P4
          radiusZ * Math.sin(angle)
        ];
      case 5: // Far right (last position)
        angle = (230 * Math.PI) / 180;  // 230°
        radiusX = (baseRadius + playerOffset) * xMultiplier;
        radiusZ = (baseRadius + playerOffset) * zMultiplier;
        return [
          radiusX * Math.cos(angle),
          heightOffset,
          radiusZ * Math.sin(angle)
        ];
      default:
        angle = (180 * Math.PI) / 180;
        radiusX = (baseRadius + playerOffset) * xMultiplier;
        radiusZ = (baseRadius + playerOffset) * zMultiplier;
        return [
          radiusX * Math.cos(angle),
          heightOffset,
          radiusZ * Math.sin(angle)
        ];
    }
  };

  return (
    <group>
      {Object.entries(gameState.players).map(([seatIndex, player]) => (
        <PlayerCard
          key={seatIndex}
          player={{
            ...player,
            seatIndex: parseInt(seatIndex)
          }}
          position={getPlayerPosition(seatIndex)}
          isCurrentPlayer={parseInt(seatIndex) === gameState.activePlayerIndex}
          isDealer={parseInt(seatIndex) === gameState.dealerPosition}
          gameState={gameState}
        />
      ))}
    </group>
  );
}

// Export the view layout options
export { VIEW_LAYOUTS }; 