import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { Modal } from '../ui/Modal';
import config from '../../config';
import Layout from '../Layout';
import GameCard from '../GameCard/GameCard';

// Use config.apiUrl instead of API_URL
const apiUrl = config.apiUrl;

const ChipIcon = () => (
  <svg 
    width="24" 
    height="24" 
    viewBox="0 0 24 24" 
    xmlns="http://www.w3.org/2000/svg" 
    fill="currentColor"
  >
    <path d="M12,0A12,12,0,1,0,24,12,12.013,12.013,0,0,0,12,0ZM22,12a10,10,0,0,1-.186,1.9l-1.932-.58a7.442,7.442,0,0,0,0-2.64l1.932-.58A10,10,0,0,1,22,12Zm-.76-3.816-1.93.579A8.048,8.048,0,0,0,15.237,4.69l.579-1.93A10.058,10.058,0,0,1,21.24,8.184ZM12,18a6,6,0,1,1,6-6A6.006,6.006,0,0,1,12,18ZM13.9,2.186l-.58,1.932a7.442,7.442,0,0,0,2.64,0L10.1,2.186a9.8,9.8,0,0,1,3.8,0ZM8.184,2.76l.579,1.93A8.048,8.048,0,0,0,4.69,8.763L2.76,8.184A10.058,10.058,0,0,1,8.184,2.76ZM2,12a10,10,0,0,1,.186-1.9l1.932.58a7.442,7.442,0,0,0,0,2.64l-1.932.58A10,10,0,0,1,2,12Zm.76,3.816,1.93-.579A8.048,8.048,0,0,0,8.763,19.31l-.579,1.93A10.058,10.058,0,0,1,2.76,15.816Zm7.34,6,.58-1.932a7.442,7.442,0,0,0,2.64,0l.58,1.932a9.8,9.8,0,0,1-3.8,0Zm5.716-.575-.579-1.929a8.048,8.048,0,0,0,4.073-4.073l1.93.579A10.053,10.053,0,0,1,15.816,21.239ZM14.631,10.892a1.848,1.848,0,0,1,0,2.216l-1.893,2.523a.922.922,0,0,1-1.476,0L9.369,13.108a1.848,1.848,0,0,1,0-2.216l1.893-2.523a.922.922,0,0,1,1.476,0Z" />
  </svg>
);

const InviteIcon = () => (
  <svg 
    width="16" 
    height="16" 
    viewBox="0 0 24 24" 
    fill="none" 
    stroke="currentColor" 
    strokeWidth="2" 
    strokeLinecap="round" 
    strokeLinejoin="round"
  >
    <circle cx="18" cy="5" r="3"/>
    <circle cx="6" cy="12" r="3"/>
    <circle cx="18" cy="19" r="3"/>
    <line x1="8.59" y1="13.51" x2="15.42" y2="17.49"/>
    <line x1="15.41" y1="6.51" x2="8.59" y2="10.49"/>
  </svg>
);

const TimeIcon = () => (
  <svg 
    width="16" 
    height="16" 
    viewBox="0 0 24 24" 
    fill="currentColor"
  >
    <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm.5-13H11v6l5.25 3.15.75-1.23-4.5-2.67z"/>
  </svg>
);

const CommunityCard = ({ community, onClick, isCreateCard = false }) => {
  const { user } = useAuth();

  if (isCreateCard) {
    return (
      <StyledCommunityCard onClick={onClick}>
        <CreateCardContent>
          <PlusIcon>+</PlusIcon>
          <CreateCardTitle>Create New Community</CreateCardTitle>
          <CreateCardDescription>Start a new poker community</CreateCardDescription>
        </CreateCardContent>
      </StyledCommunityCard>
    );
  }

  const getRoleDisplay = () => {
    if (user?.isAdmin) return 'Admin';
    if (community.isOwner) return 'Owner';
    return 'Member';
  };

  const isAdminOrOwner = user?.isAdmin || community.isOwner;

  return (
    <StyledCommunityCard onClick={onClick}>
      <CommunityHeader>
        <CommunityName>{community.name}</CommunityName>
        <RoleBadge $ownerStatus={isAdminOrOwner}>
          {getRoleDisplay()}
        </RoleBadge>
      </CommunityHeader>
      <CommunityDescription>{community.description}</CommunityDescription>
      <CommunityFooter>
        <CommunityStats>
          <StatItem>
            <UserGroupIcon />
            {community.memberCount || 0} members
          </StatItem>
          <StatItem>
            <GameIcon />
            {community.activeGames || 0} active games
          </StatItem>
        </CommunityStats>
      </CommunityFooter>
    </StyledCommunityCard>
  );
};

const RoleBadge = styled.span`
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.8rem;
  font-weight: 500;
  background: ${props => props.isOwner ? 'rgba(46, 204, 113, 0.2)' : 'rgba(255, 255, 255, 0.1)'};
  color: ${props => props.isOwner ? '#2ecc71' : 'rgba(255, 255, 255, 0.6)'};
  border: 1px solid ${props => props.isOwner ? 'rgba(46, 204, 113, 0.3)' : 'rgba(255, 255, 255, 0.2)'};
`;

const DatePickerContainer = styled.div`
  margin-bottom: 20px;
`;

const DatePickerHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
`;

const DatePickerLabel = styled.label`
  color: #fff;
  font-size: 0.95rem;
  display: flex;
  align-items: center;
  gap: 8px;

  svg {
    width: 16px;
    height: 16px;
    opacity: 0.7;
  }
`;

const DateTimeInput = styled.input`
  width: 100%;
  padding: 12px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background: rgba(255, 255, 255, 0.15);
    border-color: rgba(255, 255, 255, 0.3);
  }

  &:focus {
    outline: none;
    border-color: #2ecc71;
    background: rgba(46, 204, 113, 0.1);
  }

  &::-webkit-calendar-picker-indicator {
    filter: invert(1);
    cursor: pointer;
    opacity: 0.6;
    transition: opacity 0.2s;

    &:hover {
      opacity: 1;
    }
  }
`;

const Lobby = () => {
  const [chipBalance, setChipBalance] = useState(0);
  const { user, logout, updateUser } = useAuth();
  const navigate = useNavigate();
  const [showCreateGameModal, setShowCreateGameModal] = useState(false);
  const [selectedBuyIn, setSelectedBuyIn] = useState('');
  const [createGameError, setCreateGameError] = useState('');
  const [communities, setCommunities] = useState([]);
  const [showCreateCommunityModal, setShowCreateCommunityModal] = useState(false);
  const [communityName, setCommunityName] = useState('');
  const [communityDescription, setCommunityDescription] = useState('');
  const [createCommunityError, setCreateCommunityError] = useState('');
  const [scheduledTime, setScheduledTime] = useState('');
  const [isScheduled, setIsScheduled] = useState(false);
  const [myGames, setMyGames] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (user) {
      fetchUserDetails();
      fetchMyGames();
      fetchMyCommunities();
      const interval = setInterval(fetchMyGames, 15000);
      return () => clearInterval(interval);
    }
  }, [user]);

  const fetchUserDetails = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/games/user/details`, {
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      if (data.success) {
        setChipBalance(data.user.balance);
        if (updateUser) {
          updateUser({
            ...user,
            ...data.user,
            isAdmin: data.user.isAdmin
          });
        }
      }
    } catch (error) {
      console.error('Failed to fetch user details:', error);
    }
  };

  const fetchMyGames = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/games/my-games`, {
        credentials: 'include'
      });
      const data = await response.json();
      setMyGames(data.games || []);
    } catch (error) {
      console.error('Failed to fetch games:', error);
    }
  };

  useEffect(() => {
    fetchMyGames();
  }, []);

  const fetchMyCommunities = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/communities/user/memberships`, {
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const data = await response.json();
      if (data.success && Array.isArray(data.communities)) {
        const processedCommunities = data.communities.map(community => ({
          ...community,
          memberCount: community.members?.length || community.memberCount || 0,
          isOwner: community.ownerId === user?.id || community.owner === user?.id
        }));
        setCommunities(processedCommunities);
      } else if (Array.isArray(data.communities)) {
        const processedCommunities = data.communities.map(community => ({
          ...community,
          memberCount: community.members?.length || community.memberCount || 0,
          isOwner: community.ownerId === user?.id || community.owner === user?.id
        }));
        setCommunities(processedCommunities);
      }
    } catch (error) {
      console.error('Failed to fetch communities:', error);
      setCommunities([]);
    }
  };

  const handleInvite = async (gameId, gameName) => {
    const inviteLink = `${window.location.origin}/table/${gameId}`;
    const shareData = {
      title: 'Join my Poker Game!',
      text: `Hey! Join my poker game at HNI Poker.`,
      url: inviteLink
    };

    try {
      // Try Web Share API first
      if (navigator.share) {
        await navigator.share(shareData);
        console.log('Shared successfully');
      } else {
        // Fallback to clipboard copy
        await navigator.clipboard.writeText(inviteLink);
        alert('Invite link copied to clipboard!');
      }
    } catch (error) {
      console.error('Error sharing:', error);
      // Final fallback for older browsers
      const textArea = document.createElement('textarea');
      textArea.value = inviteLink;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand('copy');
        alert('Invite link copied to clipboard!');
      } catch (err) {
        console.error('Fallback: Failed to copy:', err);
        alert('Failed to copy invite link. Please copy manually: ' + inviteLink);
      }
      document.body.removeChild(textArea);
    }
  };


  const handleCreateGame = async () => {
    if (!selectedBuyIn) {
      setCreateGameError('Please select a buy-in amount');
      return;
    }

    if (scheduledTime && new Date(scheduledTime) <= new Date()) {
      setCreateGameError('Scheduled time must be in the future');
      return;
    }

    const buyInAmount = Number(selectedBuyIn);
    const smallBlind = Math.max(1, Math.floor(buyInAmount * 0.01));
    const bigBlind = smallBlind * 2;

    try {
      const gameData = {
        status: scheduledTime ? 'SCHEDULED' : 'WAITING',
        settings: {
          buyIn: buyInAmount,
          smallBlind,
          bigBlind,
          maxPlayers: 6
        },
        scheduledTime: scheduledTime || null
      };

      console.log('Creating game with data:', gameData); // Debug log

      const response = await fetch(`${apiUrl}/api/games/create-standard`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(gameData),
        credentials: 'include'
      });

      const data = await response.json();
      if (data.success) {
        setShowCreateGameModal(false);
        setSelectedBuyIn('');
        setScheduledTime('');
        setCreateGameError('');
        fetchMyGames();
      } else {
        setCreateGameError(data.message || 'Failed to create game');
      }
    } catch (error) {
      console.error('Failed to create game:', error);
      setCreateGameError('Failed to create game');
    }
  };

  const formatTimeAgo = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    const diffInHours = Math.floor((now - date) / (1000 * 60 * 60));
    
    if (diffInHours < 1) {
      const diffInMinutes = Math.floor((now - date) / (1000 * 60));
      return `${diffInMinutes}m ago`;
    } else if (diffInHours < 24) {
      return `${diffInHours}h ago`;
    } else {
      const diffInDays = Math.floor(diffInHours / 24);
      return `${diffInDays}d ago`;
    }
  };

  const handleCreateCommunity = async () => {
    if (!communityName || !communityDescription) {
      setCreateCommunityError('Name and description are required');
      return;
    }

    try {
      const response = await fetch(`${apiUrl}/api/communities`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          name: communityName,
          description: communityDescription
        }),
        credentials: 'include'
      });

      const data = await response.json();
      if (data.community) {
        setShowCreateCommunityModal(false);
        setCommunityName('');
        setCommunityDescription('');
        setCreateCommunityError('');
        fetchMyCommunities(); // Refresh the communities list
      } else {
        setCreateCommunityError(data.message || 'Failed to create community');
      }
    } catch (error) {
      console.error('Failed to create community:', error);
      setCreateCommunityError('Failed to create community');
    }
  };

  const handleEndGame = async (gameId) => {
    try {
      const response = await fetch(`${apiUrl}/api/games/${gameId}/complete`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      const data = await response.json();
      if (data.success) {
        fetchMyGames(); // Refresh the games list
      } else {
        console.error('Failed to end game:', data.message);
      }
    } catch (error) {
      console.error('Failed to end game:', error);
    }
  };

  return (
    <Layout showFooter={true}>
      <LobbyWrapper>
        <LobbyHeader>
          <HeaderContent>
            <BrandSection>
              <BrandTitle>HNI Poker</BrandTitle>
              <BrandSubtitle>Play with friends</BrandSubtitle>
            </BrandSection>
            <UserSection>
              <ChipBalance>
                <ChipIcon /> {chipBalance.toLocaleString()}
              </ChipBalance>
              <LogoutButton onClick={logout}>Logout</LogoutButton>
            </UserSection>
          </HeaderContent>
        </LobbyHeader>

        <LobbyContent>
          <WelcomeSection>
            Welcome, {user?.username}
          </WelcomeSection>
          <GamesContainer>
            <SectionHeader>
              <HeaderContent>
                <h2>My Tables</h2>
              </HeaderContent>
            </SectionHeader>

            {myGames.length === 0 ? (
              <GamesList>
                <StyledCommunityCard onClick={() => setShowCreateGameModal(true)}>
                  <CreateCardContent>
                    <PlusIcon>+</PlusIcon>
                    <CreateCardTitle>Create New Table</CreateCardTitle>
                    <CreateCardDescription>Start a new poker table</CreateCardDescription>
                  </CreateCardContent>
                </StyledCommunityCard>
              </GamesList>
            ) : (
              <GamesList>
                {myGames.map(game => (
                  <GameCard
                    key={game.gameId}
                    game={game}
                    userId={user?.id}
                    isLoggedIn={!!user}
                    requireAuth={() => navigate('/login')}
                    onGameUpdate={() => fetchMyGames()}
                    onInvite={(gameId) => {
                      // Handle invite logic here
                      console.log('Invite for game:', gameId);
                    }}
                  />
                ))}
                <StyledCommunityCard onClick={() => setShowCreateGameModal(true)}>
                  <CreateCardContent>
                    <PlusIcon>+</PlusIcon>
                    <CreateCardTitle>Create New Table</CreateCardTitle>
                    <CreateCardDescription>Start a new poker table</CreateCardDescription>
                  </CreateCardContent>
                </StyledCommunityCard>
              </GamesList>
            )}
          </GamesContainer>

          <SectionHeader>
            <HeaderContent>
              <h2>My Communities</h2>
            </HeaderContent>
          </SectionHeader>

          {communities.length === 0 ? (
            <CommunitiesGrid>
              <CommunityCard
                isCreateCard={true}
                onClick={() => setShowCreateCommunityModal(true)}
              />
            </CommunitiesGrid>
          ) : (
            <CommunitiesGrid>
              {communities.map(community => (
                <CommunityCard
                  key={community._id}
                  community={community}
                  onClick={() => navigate(`/communities/${community.slug}`)}
                />
              ))}
              <CommunityCard
                isCreateCard={true}
                onClick={() => setShowCreateCommunityModal(true)}
              />
            </CommunitiesGrid>
          )}
        </LobbyContent>

        <Modal
          isOpen={showCreateGameModal}
          onClose={() => {
            setShowCreateGameModal(false);
            setSelectedBuyIn('');
            setScheduledTime('');
            setIsScheduled(false);
            setCreateGameError('');
          }}
          title="Create New Game"
        >
          <div style={{ padding: '20px' }}>
            <div style={{ marginBottom: '20px' }}>
              <label style={{ 
                display: 'block', 
                marginBottom: '10px',
                color: 'white'
              }}>
                Game Type
              </label>
              <div style={{ 
                display: 'flex',
                gap: '10px'
              }}>
                <button
                  onClick={() => setIsScheduled(false)}
                  style={{
                    flex: 1,
                    padding: '12px',
                    background: !isScheduled ? 'rgba(46, 204, 113, 0.3)' : 'rgba(255, 255, 255, 0.1)',
                    border: `2px solid ${!isScheduled ? '#2ecc71' : 'transparent'}`,
                    borderRadius: '8px',
                    color: 'white',
                    cursor: 'pointer'
                  }}
                >
                  Start Now
                </button>
                <button
                  onClick={() => setIsScheduled(true)}
                  style={{
                    flex: 1,
                    padding: '12px',
                    background: isScheduled ? 'rgba(46, 204, 113, 0.3)' : 'rgba(255, 255, 255, 0.1)',
                    border: `2px solid ${isScheduled ? '#2ecc71' : 'transparent'}`,
                    borderRadius: '8px',
                    color: 'white',
                    cursor: 'pointer'
                  }}
                >
                  Schedule
                </button>
              </div>
            </div>

            {isScheduled && (
              <DatePickerContainer>
                <DatePickerHeader>
                  <DatePickerLabel>
                    <TimeIcon />
                    Schedule Game
                  </DatePickerLabel>
                </DatePickerHeader>
                <DateTimeInput
                  type="datetime-local"
                  value={scheduledTime}
                  onChange={(e) => {
                    const selectedDate = new Date(e.target.value);
                    const now = new Date();
                    const minTime = new Date(now.getTime() + 5 * 60000);
                    
                    if (selectedDate < minTime) {
                      setCreateGameError('Please select a time at least 5 minutes in the future');
                      return;
                    }
                    
                    setCreateGameError('');
                    setScheduledTime(e.target.value);
                  }}
                  min={new Date(Date.now() + 5 * 60000).toISOString().slice(0, 16)}
                  max={new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString().slice(0, 16)}
                />
              </DatePickerContainer>
            )}

            <div style={{ marginBottom: '20px' }}>
              <label style={{ 
                display: 'block', 
                marginBottom: '10px',
                color: 'white'
              }}>
                Buy-In Amount
              </label>
              <div style={{ 
                display: 'grid', 
                gridTemplateColumns: 'repeat(2, 1fr)', 
                gap: '12px',
              }}>
                {[500, 1000, 2000, 5000, 10000].map((amount) => (
                  <button
                    key={amount}
                    onClick={() => setSelectedBuyIn(amount.toString())}
                    style={{
                      padding: '16px',
                      background: selectedBuyIn === amount.toString() ? 'rgba(46, 204, 113, 0.3)' : 'rgba(255, 255, 255, 0.1)',
                      border: `2px solid ${selectedBuyIn === amount.toString() ? '#2ecc71' : 'transparent'}`,
                      borderRadius: '8px',
                      color: 'white',
                      cursor: 'pointer'
                    }}
                  >
                    {amount.toLocaleString()} chips
                  </button>
                ))}
              </div>
            </div>

            {createGameError && (
              <div style={{ color: '#e74c3c', marginBottom: '15px' }}>
                {createGameError}
              </div>
            )}
            <div style={{ 
              display: 'flex', 
              gap: '10px',
              marginTop: '20px'
            }}>
              <button
                onClick={() => {
                  setShowCreateGameModal(false);
                  setSelectedBuyIn('');
                  setScheduledTime('');
                  setIsScheduled(false);
                  setCreateGameError('');
                }}
                style={{
                  flex: 1,
                  padding: '12px',
                  background: 'rgba(255, 255, 255, 0.1)',
                  color: 'white',
                  border: 'none',
                  borderRadius: '8px',
                  cursor: 'pointer'
                }}
              >
                Cancel
              </button>
              <button
                onClick={handleCreateGame}
                disabled={!selectedBuyIn}
                style={{
                  flex: 1,
                  padding: '12px',
                  background: 'rgba(46, 204, 113, 0.2)',
                  color: '#2ecc71',
                  border: '2px solid rgba(46, 204, 113, 0.3)',
                  borderRadius: '8px',
                  cursor: 'pointer',
                  opacity: !selectedBuyIn ? 0.5 : 1
                }}
              >
                Create Game
              </button>
            </div>
          </div>
        </Modal>

        <Modal
          isOpen={showCreateCommunityModal}
          onClose={() => {
            setShowCreateCommunityModal(false);
            setCommunityName('');
            setCommunityDescription('');
            setCreateCommunityError('');
          }}
          title="Create Community"
        >
          <div style={{ padding: '20px' }}>
            <div style={{ marginBottom: '20px' }}>
              <div style={{ marginBottom: '10px' }}>
                <label style={{ display: 'block', marginBottom: '5px', color: 'white' }}>
                  Community Name
                </label>
                <input
                  type="text"
                  value={communityName}
                  onChange={(e) => setCommunityName(e.target.value)}
                  style={{
                    width: '100%',
                    padding: '8px',
                    background: 'rgba(255, 255, 255, 0.1)',
                    border: '1px solid rgba(255, 255, 255, 0.2)',
                    borderRadius: '4px',
                    color: 'white'
                  }}
                />
              </div>
              <div style={{ marginBottom: '10px' }}>
                <label style={{ display: 'block', marginBottom: '5px', color: 'white' }}>
                  Description
                </label>
                <textarea
                  value={communityDescription}
                  onChange={(e) => setCommunityDescription(e.target.value)}
                  style={{
                    width: '100%',
                    padding: '8px',
                    background: 'rgba(255, 255, 255, 0.1)',
                    border: '1px solid rgba(255, 255, 255, 0.2)',
                    borderRadius: '4px',
                    color: 'white',
                    minHeight: '100px'
                  }}
                />
              </div>
            </div>
            {createCommunityError && (
              <div style={{ color: '#e74c3c', marginBottom: '15px' }}>
                {createCommunityError}
              </div>
            )}
            <div style={{ 
              display: 'flex', 
              gap: '10px',
              marginTop: '20px'
            }}>
              <button
                onClick={() => {
                  setShowCreateCommunityModal(false);
                  setCommunityName('');
                  setCommunityDescription('');
                  setCreateCommunityError('');
                }}
                style={{
                  flex: 1,
                  padding: '12px',
                  background: 'rgba(255, 255, 255, 0.1)',
                  color: 'white',
                  border: 'none',
                  borderRadius: '8px',
                  cursor: 'pointer'
                }}
              >
                Cancel
              </button>
              <button
                onClick={handleCreateCommunity}
                disabled={!communityName || !communityDescription}
                style={{
                  flex: 1,
                  padding: '12px',
                  background: 'rgba(46, 204, 113, 0.2)',
                  color: '#2ecc71',
                  border: '2px solid rgba(46, 204, 113, 0.3)',
                  borderRadius: '8px',
                  cursor: 'pointer',
                  opacity: (!communityName || !communityDescription) ? 0.5 : 1
                }}
              >
                Create Community
              </button>
            </div>
          </div>
        </Modal>
      </LobbyWrapper>
    </Layout>
  );
};

// Updated and new styled components
const LobbyWrapper = styled.div`
  min-height: 100vh;
  background: #1a1a1a;
  color: white;
  padding: 20px;
`;

const LobbyHeader = styled.header`
  background: rgba(0, 0, 0, 0.85);
  backdrop-filter: blur(10px);
  padding: 15px 25px;
  border-radius: 15px;
  margin: 20px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
`;

const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
`;

const BrandSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
`;

const BrandTitle = styled.h1`
  margin: 0;
  font-size: 2rem;
  font-weight: 700;
  color: #fff;
  line-height: 1;
`;

const BrandSubtitle = styled.span`
  color: rgba(255, 255, 255, 0.6);
  font-size: 1rem;
`;

const UserSection = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

const ChipBalance = styled.div`
  background: rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  gap: 6px;
  color: #ffd700;
  font-size: 1.1rem;
  
  svg {
    width: 18px;
    height: 18px;
    fill: currentColor;
  }
`;

const LogoutButton = styled.button`
  padding: 8px 16px;
  background: rgba(255, 68, 68, 0.1);
  color: #ff4444;
  border: 1px solid rgba(255, 68, 68, 0.3);
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s;
  
  &:hover {
    background: rgba(255, 68, 68, 0.2);
  }
`;

const LobbyContent = styled.main`
  max-width: 1200px;
  margin: 0 auto;
`;

const WelcomeSection = styled.div`
  padding: 20px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 1.1rem;
  margin-bottom: 10px;
  
  @media (max-width: 768px) {
    padding: 10px;
    font-size: 1rem;
  }
`;

const GamesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const GamesSection = styled.section`
  background: rgba(0, 0, 0, 0.85);
  backdrop-filter: blur(10px);
  border-radius: 12px;
  padding: 20px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
`;

const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  
  h2 {
    margin: 0;
    font-size: 1.8rem;
    color: #fff;
  }
`;

const NoGamesMessage = styled.div`
  text-align: center;
  padding: 40px;
  color: #888;
  font-size: 1.1rem;
`;

const GamesList = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
`;

const StyledCommunityCard = styled.div`
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  padding: 20px;
  cursor: pointer;
  transition: all 0.2s ease;
  border: 1px solid rgba(255, 255, 255, 0.1);

  &:hover {
    transform: translateY(-2px);
    background: rgba(255, 255, 255, 0.08);
    border-color: rgba(255, 255, 255, 0.2);
  }
`;

const CommunityHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`;

const CommunityName = styled.h3`
  margin: 0;
  font-size: 1.2rem;
  color: #fff;
`;

const MemberCount = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  color: rgba(255, 255, 255, 0.6);
  font-size: 0.9rem;
`;

const CommunityDescription = styled.p`
  color: rgba(255, 255, 255, 0.8);
  margin: 0 0 16px 0;
  font-size: 0.95rem;
  line-height: 1.4;
`;

const CommunityFooter = styled.div`
  margin-top: auto;
`;

const CommunityStats = styled.div`
  display: flex;
  gap: 16px;
`;

const StatItem = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.9rem;
`;

const UserGroupIcon = () => (
  <svg width="16" height="16" viewBox="0 0 24 24" fill="currentColor">
    <path d="M12 12.75c1.63 0 3.07.39 4.24.9 1.08.48 1.76 1.56 1.76 2.73V18H6v-1.62c0-1.17.68-2.25 1.76-2.73 1.17-.51 2.61-.9 4.24-.9zM4 13a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm18 0a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"/>
    <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4z"/>
  </svg>
);

const GameIcon = () => (
  <svg width="16" height="16" viewBox="0 0 24 24" fill="currentColor">
    <path d="M17 4h2v2h-2V4zm-2 6h2v2h-2v-2zm-8 8h2v2H7v-2zM5 4h2v2H5V4zm0 8h2v2H5v-2zm-2 4h2v2H3v-2zM3 8h2v2H3V8zm2 8h2v2H5v-2zm8-12h2v2h-2V4zm4 8h2v2h-2v-2zm0 4h2v2h-2v-2zm-8-8h2v2h-2V8zm0 8h2v2h-2v-2z"/>
  </svg>
);

const CreateButton = styled.button`
  padding: 8px 16px;
  background: rgba(46, 204, 113, 0.2);
  color: #2ecc71;
  border: 2px solid rgba(46, 204, 113, 0.3);
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background: rgba(46, 204, 113, 0.3);
  }
`;

const CommunitiesGrid = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
`;

const EmptyState = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
`;

const EmptyStateContent = styled.div`
  text-align: center;
  max-width: 400px;
`;

const EmptyStateIcon = styled.div`
  font-size: 48px;
  margin-bottom: 16px;
`;

const EmptyStateText = styled.h3`
  margin: 0 0 8px 0;
  color: #fff;
  font-size: 1.4rem;
`;

const EmptyStateDescription = styled.p`
  color: rgba(255, 255, 255, 0.6);
  margin: 0 0 24px 0;
`;

const CreateCardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 15px;
  height: 60px;
`;

const PlusIcon = styled.div`
  font-size: 2rem;
  color: #2ecc71;
  margin-bottom: 10px;
`;

const CreateCardTitle = styled.h3`
  margin: 0 0 8px 0;
  color: #2ecc71;
  font-size: 1.2rem;
`;

const CreateCardDescription = styled.p`
  color: rgba(255, 255, 255, 0.6);
  margin: 0;
  font-size: 0.9rem;
`;

export default Lobby; 