import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useAuth } from '../contexts/AuthContext';
import { Modal } from '../components/ui/Modal';
import config from '../config';
import Layout from '../components/Layout';
import GameCard from '../components/GameCard/GameCard';
import Pagination from '../components/ui/Pagination';
import { Helmet } from 'react-helmet-async';

const PageWrapper = styled.div`
  min-height: 100vh;
  background: #1a1a1a;
  color: white;
`;

const ContentWrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: 16px;
  
  @media (max-width: 768px) {
    padding: 12px;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 24px;
  flex-wrap: wrap;
  
  h1 {
    margin: 0;
    flex: 1;
  }
  
  @media (max-width: 768px) {
    gap: 8px;
  }
`;

const CommunityInfo = styled.div`
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(5px);
  border-radius: 12px;
  padding: 30px;
  border: 1.5px solid rgba(255, 255, 255, 0.2);
`;

const Description = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  color: #ccc;
  margin-bottom: 30px;
`;

const InfoSection = styled.div`
  margin-bottom: 30px;
  
  h3 {
    color: #2ecc71;
    margin-bottom: 10px;
  }
`;

const MembersList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 12px;
  
  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  }
`;

const MemberItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(255, 255, 255, 0.1);
  padding: 10px 15px;
  border-radius: 8px;
`;

const RoleBadge = styled.span`
  background: rgba(46, 204, 113, 0.2);
  color: #2ecc71;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.8rem;
`;

const AdminSection = styled.div`
  margin-top: 30px;
  padding: 20px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 12px;
`;

const Button = styled.button`
  padding: 12px 24px;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.2s;
  
  &:hover {
    transform: translateY(-1px);
  }
  
  &:active {
    transform: translateY(1px);
  }
`;

const BackButton = styled(Button)`
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  border: 1.5px solid rgba(255, 255, 255, 0.2);
  
  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
`;

const AdminButton = styled.button`
  display: flex;
  align-items: center;
  padding: 12px 20px;
  background: rgba(46, 204, 113, 0.2);
  color: #2ecc71;
  border: 1.5px solid rgba(46, 204, 113, 0.3);
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s;
  
  &:hover {
    background: rgba(46, 204, 113, 0.3);
    transform: translateY(-1px);
  }
`;

const LoadingMessage = styled.div`
  text-align: center;
  font-size: 1.2rem;
  color: #ccc;
`;

const ErrorMessage = styled.div`
  color: #e74c3c;
  background: rgba(231, 76, 60, 0.1);
  border: 1px solid rgba(231, 76, 60, 0.3);
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 20px;
`;

const MainSection = styled.div`
  margin-bottom: 40px;
`;

const StatsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 20px;
  margin-top: 30px;
`;

const StatBox = styled.div`
  background: rgba(255, 255, 255, 0.05);
  padding: 20px;
  border-radius: 12px;
  text-align: center;
`;

const StatLabel = styled.div`
  color: #888;
  font-size: 0.9rem;
  margin-bottom: 8px;
`;

const StatValue = styled.div`
  font-size: 1.5rem;
  color: #2ecc71;
  font-weight: 600;
`;

const ActivityList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const ActivityItem = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 15px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
`;

const ActivityIcon = styled.div`
  font-size: 1.2rem;
`;

const ActivityContent = styled.div`
  flex: 1;
`;

const ActivityText = styled.div`
  color: #fff;
  margin-bottom: 5px;
`;

const ActivityTime = styled.div`
  font-size: 0.8rem;
  color: #888;
`;

const MemberInfo = styled.div`
  flex: 1;
`;

const MemberName = styled.div`
  font-weight: 500;
  margin-bottom: 4px;
`;

const MemberStats = styled.div`
  font-size: 0.8rem;
  color: #888;
`;

const AdminBadge = styled.span`
  background: rgba(46, 204, 113, 0.2);
  color: #2ecc71;
  padding: 6px 12px;
  border-radius: 20px;
  font-size: 0.9rem;
  margin-left: 15px;
`;

const AdminActions = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 15px;
`;

const IconWrapper = styled.span`
  margin-right: 8px;
`;

const LobbyWrapper = styled.div`
  min-height: 100vh;
  background: #1a1a1a;
  color: white;
  padding: 20px;
`;

const LobbyHeader = styled.header`
  background: rgba(0, 0, 0, 0.85);
  backdrop-filter: blur(10px);
  padding: 15px 25px;
  border-radius: 15px;
  margin: 20px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
`;

const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
`;

const BrandSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
`;

const BrandTitle = styled.h1`
  margin: 0;
  font-size: 2rem;
  font-weight: 700;
  color: #fff;
  line-height: 1;
`;

const BrandSubtitle = styled.span`
  color: rgba(255, 255, 255, 0.6);
  font-size: 1rem;
`;

const UserSection = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

const HeaderBackButton = styled.button`
  padding: 8px 16px;
  background: rgba(52, 152, 219, 0.2);
  color: #3498db;
  border: 1.5px solid rgba(52, 152, 219, 0.3);
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s;
  backdrop-filter: blur(5px);
  
  &:hover {
    background: rgba(52, 152, 219, 0.3);
    transform: translateY(-1px);
  }
  
  &:active {
    transform: translateY(1px);
  }
`;

const LogoutButton = styled.button`
  padding: 8px 16px;
  background: rgba(255, 68, 68, 0.1);
  color: #ff4444;
  border: 1px solid rgba(255, 68, 68, 0.3);
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s;
  
  &:hover {
    background: rgba(255, 68, 68, 0.2);
  }
`;

const CommunityHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  
  h1 {
    margin: 0;
    font-size: 2rem;
    font-weight: 600;
  }
`;

const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  
  h2 {
    margin: 0;
    font-size: 1.8rem;
    color: #fff;
  }
`;

const ModalContent = styled.div`
  padding: 20px;
`;

const SettingsSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const SettingsButton = styled.button`
  display: flex;
  align-items: center;
  padding: 12px;
  background: rgba(255, 255, 255, 0.1);
  border: none;
  border-radius: 8px;
  color: white;
  cursor: pointer;
  transition: all 0.2s;
  width: 100%;
  text-align: left;
  
  &:hover {
    background: rgba(255, 255, 255, 0.15);
  }
`;

const DangerButton = styled(SettingsButton)`
  color: #ff4444;
  background: rgba(255, 68, 68, 0.1);
  
  &:hover {
    background: rgba(255, 68, 68, 0.2);
  }
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  color: #fff;
`;

const CommunitySettingsSelect = styled.select`
  padding: 8px;
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  cursor: pointer;

  option {
    background: #1a1a1a;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
`;

const CancelButton = styled.button`
  padding: 8px 16px;
  background: rgba(255, 255, 255, 0.1);
  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  
  &:hover {
    background: rgba(255, 255, 255, 0.15);
  }
`;

const CreateButton = styled.button`
  padding: 8px 16px;
  background: #2ecc71;
  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  &:hover:not(:disabled) {
    background: #27ae60;
  }
`;

const ModalErrorMessage = styled.div`
  color: #ff4444;
  margin-top: 10px;
`;

const GamesSection = styled.section`
  background: rgba(0, 0, 0, 0.85);
  backdrop-filter: blur(10px);
  border-radius: 12px;
  padding: 20px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  margin-top: 20px;
  overflow: hidden;
  max-width: 100%; // Add this to prevent overflow
`;

const GamesList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 16px;
  
  @media (min-width: 769px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    justify-content: start;
  }
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const NoGamesMessage = styled.div`
  text-align: center;
  padding: 40px;
  color: #888;
  font-size: 1.1rem;
`;

const CreateGameButton = styled.button`
  padding: 10px 20px;
  background: rgba(40, 167, 69, 0.2);
  color: #2ecc71;
  border: 1.5px solid rgba(46, 204, 113, 0.3);
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.2s;
  backdrop-filter: blur(5px);
  
  &:hover {
    background: rgba(40, 167, 69, 0.3);
    transform: translateY(-1px);
  }
  
  &:active {
    transform: translateY(1px);
  }
`;

const AdminContent = styled.div`
  margin-top: 15px;
`;

const PendingMembersList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const PendingUserItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.1);
`;

const PendingUserInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const PendingUserName = styled.span`
  font-weight: 500;
  color: #fff;
`;

const PendingRequestDate = styled.span`
  font-size: 0.8rem;
  color: #888;
`;

const PendingApprovalButtons = styled.div`
  display: flex;
  gap: 8px;
`;

const PendingApproveButton = styled.button`
  padding: 6px 12px;
  background: rgba(46, 204, 113, 0.2);
  color: #2ecc71;
  border: 1px solid rgba(46, 204, 113, 0.3);
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background: rgba(46, 204, 113, 0.3);
  }
`;

const PendingRejectButton = styled.button`
  padding: 6px 12px;
  background: rgba(231, 76, 60, 0.2);
  color: #e74c3c;
  border: 1px solid rgba(231, 76, 60, 0.3);
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background: rgba(231, 76, 60, 0.3);
  }
`;

const NoPendingRequestsMessage = styled.div`
  text-align: center;
  padding: 20px;
  color: #888;
  font-style: italic;
`;

const InviteIcon = () => (
  <svg 
    width="16" 
    height="16" 
    viewBox="0 0 24 24" 
    fill="none" 
    stroke="currentColor" 
    strokeWidth="2" 
    strokeLinecap="round" 
    strokeLinejoin="round"
  >
    <circle cx="18" cy="5" r="3"/>
    <circle cx="6" cy="12" r="3"/>
    <circle cx="18" cy="19" r="3"/>
    <line x1="8.59" y1="13.51" x2="15.42" y2="17.49"/>
    <line x1="15.41" y1="6.51" x2="8.59" y2="10.49"/>
  </svg>
);

const formatTimeAgo = (dateString) => {
  const date = new Date(dateString);
  const now = new Date();
  const seconds = Math.floor((now - date) / 1000);

  if (seconds < 60) return 'just now';
  const minutes = Math.floor(seconds / 60);
  if (minutes < 60) return `${minutes}m ago`;
  const hours = Math.floor(minutes / 60);
  if (hours < 24) return `${hours}h ago`;
  const days = Math.floor(hours / 24);
  if (days < 30) return `${days}d ago`;
  return date.toLocaleDateString();
};

const handleInvite = async (gameId, gameName) => {
  const inviteLink = `${window.location.origin}/table/${gameId}`;
  const shareData = {
    title: 'Join my Poker Game!',
    text: `Hey! Join my poker game at HNI Poker.`,
    url: inviteLink
  };

  try {
    if (navigator.share) {
      await navigator.share(shareData);
      console.log('Shared successfully');
    } else {
      await navigator.clipboard.writeText(inviteLink);
      alert('Invite link copied to clipboard!');
    }
  } catch (error) {
    console.error('Error sharing:', error);
    const textArea = document.createElement('textarea');
    textArea.value = inviteLink;
    document.body.appendChild(textArea);
    textArea.select();
    try {
      document.execCommand('copy');
      alert('Invite link copied to clipboard!');
    } catch (err) {
      console.error('Fallback: Failed to copy:', err);
      alert('Failed to copy invite link. Please copy manually: ' + inviteLink);
    }
    document.body.removeChild(textArea);
  }
};

const SettingsIcon = () => (
  <svg 
    width="16" 
    height="16" 
    viewBox="0 0 24 24" 
    fill="none" 
    stroke="currentColor" 
    strokeWidth="2"
  >
    <circle cx="12" cy="12" r="3"/>
    <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2 2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33 1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1 2 2h-.09a1.65 1.65 0 0 0-1.51 1z"/>
  </svg>
);

const AdminSettingsButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background: rgba(46, 204, 113, 0.2);
  color: #2ecc71;
  border: 1.5px solid rgba(46, 204, 113, 0.3);
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s;
  
  @media (max-width: 768px) {
    width: 100%;
    justify-content: center;
  }
`;

const AdminSettingsForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
`;

const AdminSettingsGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const AdminSettingsLabel = styled.label`
  color: #fff;
  font-size: 0.9rem;
`;

const AdminSettingsSelect = styled.select`
  padding: 8px;
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  cursor: pointer;

  option {
    background: #1a1a1a;
  }
`;

const AdminControlsWrapper = styled.div`
  display: flex;
  gap: 12px;
`;

const SaveButton = styled.button`
  padding: 8px 16px;
  background: rgba(46, 204, 113, 0.2);
  color: #2ecc71;
  border: 1px solid rgba(46, 204, 113, 0.3);
  border-radius: 4px;
  cursor: pointer;
  
  &:hover {
    background: rgba(46, 204, 113, 0.3);
  }
`;

const HeaderControls = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const AdminSettingsInput = styled.input`
  width: 100%;
  padding: 8px;
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  font-size: 1rem;

  &:focus {
    outline: none;
    border-color: #2ecc71;
  }

  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
`;

const AdminSettingsTextarea = styled.textarea`
  width: 100%;
  padding: 8px;
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  font-size: 1rem;
  resize: vertical;
  min-height: 100px;

  &:focus {
    outline: none;
    border-color: #2ecc71;
  }

  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
`;

const WhatsAppSection = styled.div`
  margin: 20px 0;
  padding: 15px 20px;
  background: rgba(37, 211, 102, 0.1);
  border: 1px solid rgba(37, 211, 102, 0.2);
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 20px;
  cursor: pointer;
  transition: all 0.2s;
  
  &:hover {
    background: rgba(37, 211, 102, 0.15);
  }
`;

const WhatsAppIconWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const NotificationBadge = styled.span`
  position: absolute;
  top: -8px;
  right: -8px;
  background: #ff4444;
  color: white;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  font-size: 0.9rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #1a1a1a;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
`;

const WhatsAppLink = styled.a`
  color: #25d366;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 8px;
  
  &:hover {
    text-decoration: underline;
  }
`;

const WhatsAppIcon = () => (
  <svg width="48" height="48" viewBox="0 0 24 24" fill="#25d366">
    <path d="M12.031 6.172c-3.181 0-5.767 2.586-5.768 5.766-.001 1.298.38 2.27 1.019 3.287l-.582 2.128 2.182-.573c.978.58 1.911.928 3.145.929 3.178 0 5.767-2.587 5.768-5.766.001-3.187-2.575-5.77-5.764-5.771zm3.392 8.244c-.144.405-.837.774-1.17.824-.299.045-.677.063-1.092-.069-.252-.08-.575-.187-.988-.365-1.739-.751-2.874-2.502-2.961-2.617-.087-.116-.708-.94-.708-1.793s.448-1.273.607-1.446c.159-.173.346-.217.462-.217l.332.006c.106.005.249-.04.39.298.144.347.491 1.2.534 1.287.043.087.072.188.014.304-.058.116-.087.188-.188.289l-.26.304c-.087.086-.177.18-.076.354.101.174.449.741.964 1.201.662.591 1.221.774 1.394.86s.274.072.376-.043c.101-.116.433-.506.549-.68.116-.173.231-.145.39-.087s1.011.477 1.184.564.289.13.332.202c.045.072.045.419-.1.824zm-3.423-14.416c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm.029 18.88c-1.161 0-2.305-.292-3.318-.844l-3.677.964.984-3.595c-.607-1.052-.927-2.246-.926-3.468.001-3.825 3.113-6.937 6.937-6.937 1.856.001 3.598.723 4.907 2.034 1.31 1.311 2.031 3.054 2.03 4.908-.001 3.825-3.113 6.938-6.937 6.938z"/>
  </svg>
);

const WhatsAppText = styled.span`
  font-size: 1.1rem;
  color: #25d366;
  font-weight: 500;
`;

const CommunitySettingsForm = ({ community, onSave, onCancel }) => {
  const [formData, setFormData] = useState({
    name: community.name || '',
    description: community.description || '',
    whatsappGroup: community.whatsappGroup || '',
    joinType: community.settings?.joinType || 'open',
    visibility: community.settings?.visibility || 'public'
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Submitting form data:', formData);
    onSave(formData);
  };

  return (
    <AdminSettingsForm onSubmit={handleSubmit}>
      <AdminSettingsGroup>
        <AdminSettingsLabel>Community Name</AdminSettingsLabel>
        <AdminSettingsInput
          type="text"
          value={formData.name}
          onChange={(e) => setFormData(prev => ({
            ...prev,
            name: e.target.value
          }))}
          placeholder="Enter community name"
        />
      </AdminSettingsGroup>

      <AdminSettingsGroup>
        <AdminSettingsLabel>Description</AdminSettingsLabel>
        <AdminSettingsTextarea
          value={formData.description}
          onChange={(e) => setFormData(prev => ({
            ...prev,
            description: e.target.value
          }))}
          placeholder="Enter community description"
          rows={4}
        />
      </AdminSettingsGroup>

      <AdminSettingsGroup>
        <AdminSettingsLabel>WhatsApp Group Link (Optional)</AdminSettingsLabel>
        <AdminSettingsInput
          type="url"
          value={formData.whatsappGroup}
          onChange={(e) => setFormData(prev => ({
            ...prev,
            whatsappGroup: e.target.value
          }))}
          placeholder="https://chat.whatsapp.com/..."
        />
        <small style={{ color: '#888' }}>
          Enter the invite link to your WhatsApp group
        </small>
      </AdminSettingsGroup>

      <AdminSettingsGroup>
        <AdminSettingsLabel>Join Type</AdminSettingsLabel>
        <AdminSettingsSelect
          value={formData.joinType}
          onChange={(e) => setFormData(prev => ({
            ...prev,
            joinType: e.target.value
          }))}
        >
          <option value="open">Open (Anyone can join)</option>
          <option value="approval">Approval Required</option>
        </AdminSettingsSelect>
      </AdminSettingsGroup>

      <AdminSettingsGroup>
        <AdminSettingsLabel>Visibility</AdminSettingsLabel>
        <AdminSettingsSelect
          value={formData.visibility}
          onChange={(e) => setFormData(prev => ({
            ...prev,
            visibility: e.target.value
          }))}
        >
          <option value="public">Public</option>
          <option value="private">Private</option>
        </AdminSettingsSelect>
      </AdminSettingsGroup>

      <ButtonGroup>
        <CancelButton type="button" onClick={onCancel}>
          Cancel
        </CancelButton>
        <SaveButton type="button" onClick={handleSubmit}>
          Save Changes
        </SaveButton>
      </ButtonGroup>
    </AdminSettingsForm>
  );
};

const StyledModal = styled(Modal)`
  @media (max-width: 768px) {
    width: 90%;
    max-width: none;
    margin: 16px;
    
    .modal-content {
      padding: 16px;
    }
  }
`;

const JoinCommunityButton = styled.button`
  padding: 8px 16px;
  background: rgba(46, 204, 113, 0.2);
  color: #2ecc71;
  border: 1.5px solid rgba(46, 204, 113, 0.3);
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s;
  font-size: 1rem;
  display: flex;
  align-items: center;
  gap: 8px;
  
  &:hover {
    background: rgba(46, 204, 113, 0.3);
    transform: translateY(-1px);
  }
  
  &:active {
    transform: translateY(1px);
  }

  @media (max-width: 768px) {
    padding: 6px 12px;
    font-size: 0.9rem;
  }
`;

const InviteButton = styled.button`
  padding: 8px 16px;
  background: rgba(52, 152, 219, 0.2);
  color: #3498db;
  border: 1.5px solid rgba(52, 152, 219, 0.3);
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s;
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  gap: 6px;
  
  &:hover {
    background: rgba(52, 152, 219, 0.3);
    transform: translateY(-1px);
  }
  
  &:active {
    transform: translateY(1px);
  }

  @media (hover: none) {
    &:hover {
      transform: none;
    }
  }
`;

const ShareButton = styled.button`
  padding: 8px 16px;
  background: rgba(52, 152, 219, 0.2);
  color: #3498db;
  border: 1.5px solid rgba(52, 152, 219, 0.3);
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s;
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  gap: 6px;
  
  &:hover {
    background: rgba(52, 152, 219, 0.3);
    transform: translateY(-1px);
  }
  
  &:active {
    transform: translateY(1px);
  }

  @media (hover: none) {
    &:hover {
      transform: none;
    }
  }
`;

const ShareIcon = () => (
  <svg 
    width="16" 
    height="16" 
    viewBox="0 0 24 24" 
    fill="none" 
    stroke="currentColor" 
    strokeWidth="2" 
    strokeLinecap="round" 
    strokeLinejoin="round"
  >
    <circle cx="18" cy="5" r="3"/>
    <circle cx="6" cy="12" r="3"/>
    <circle cx="18" cy="19" r="3"/>
    <line x1="8.59" y1="13.51" x2="15.42" y2="17.49"/>
    <line x1="15.41" y1="6.51" x2="8.59" y2="10.49"/>
  </svg>
);

const AdminButtonGroup = styled.div`
  display: flex;
  gap: 8px;
  
  @media (max-width: 768px) {
    flex: 1;
    justify-content: flex-end;
  }
`;

const MembersContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 24px;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const SectionCard = styled.div`
  background: rgba(0, 0, 0, 0.4);
  border-radius: 12px;
  padding: 20px;
  border: 1.5px solid rgba(255, 255, 255, 0.1);
`;

const MemberSectionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  padding-bottom: 12px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  
  h3 {
    color: #2ecc71;
    margin: 0;
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

const MemberCount = styled.span`
  background: rgba(46, 204, 113, 0.1);
  color: #2ecc71;
  padding: 4px 10px;
  border-radius: 12px;
  font-size: 0.9rem;
  border: 1px solid rgba(46, 204, 113, 0.2);
`;

const PendingCount = styled(MemberCount)`
  background: rgba(241, 196, 15, 0.1);
  color: #f1c40f;
  border-color: rgba(241, 196, 15, 0.2);
`;

const MemberCard = styled(MemberItem)`
  background: rgba(255, 255, 255, 0.05);
  transition: all 0.2s;
  
  &:hover {
    background: rgba(255, 255, 255, 0.08);
    transform: translateY(-1px);
  }
`;

const PendingActions = styled.div`
  display: flex;
  gap: 8px;
`;

const ActionButton = styled.button`
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.8rem;
  cursor: pointer;
  transition: all 0.2s;
  
  &:hover {
    transform: translateY(-1px);
  }
`;

const AcceptButton = styled(ActionButton)`
  background: rgba(46, 204, 113, 0.1);
  color: #2ecc71;
  border: 1px solid rgba(46, 204, 113, 0.2);
  
  &:hover {
    background: rgba(46, 204, 113, 0.2);
  }
`;

const RejectButton = styled(ActionButton)`
  background: rgba(231, 76, 60, 0.1);
  color: #e74c3c;
  border: 1px solid rgba(231, 76, 60, 0.2);
  
  &:hover {
    background: rgba(231, 76, 60, 0.2);
  }
`;

const TabsContainer = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 24px;
`;

const Tab = styled.button`
  background: ${props => props.active ? 'rgba(255, 255, 255, 0.1)' : 'transparent'};
  color: ${props => props.active ? '#fff' : 'rgba(255, 255, 255, 0.6)'};
  border: none;
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.2s;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
    color: #fff;
  }
`;

const StatsContainer = styled.div`
  margin: 24px 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 16px;
`;

const StatCard = styled.div`
  background: rgba(0, 0, 0, 0.4);
  border-radius: 12px;
  padding: 20px;
  border: 1.5px solid rgba(255, 255, 255, 0.1);
  text-align: center;
`;

const Podium = styled.div`
  display: flex;
  gap: 16px;
  justify-content: center;
  align-items: flex-end;
  margin: 20px 0;
  padding: 0 24px;
`;

const PodiumPlace = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${props => props.position === 1 ? '160px' : '140px'};

  .platform {
    background: ${props => {
      if (props.position === 1) return 'linear-gradient(to bottom, #f1c40f, #f39c12)';
      if (props.position === 2) return 'linear-gradient(to bottom, #bdc3c7, #95a5a6)';
      return 'linear-gradient(to bottom, #d35400, #e67e22)';
    }};
    height: ${props => props.position === 1 ? '100px' : props.position === 2 ? '80px' : '60px'};
    width: 100%;
    border-radius: 8px 8px 0 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding-top: 12px;
    
    .position {
      font-size: 20px;
      font-weight: bold;
      color: rgba(0, 0, 0, 0.5);
    }
  }

  .player-info {
    background: rgba(255, 255, 255, 0.05);
    width: 100%;
    padding: 12px;
    border-radius: 0 0 8px 8px;
    text-align: center;

    .name {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 6px;
      color: white;
    }

    .profit {
      font-size: 18px;
      font-weight: 600;
      color: #2ecc71;
    }
  }
`;

const PlayerGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 24px;
  margin-top: 24px;
`;

const PlayerCard = styled.div`
  background: rgba(255, 255, 255, 0.05);
  border-radius: 16px;
  padding: 24px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 4px;
    background: ${props => {
      if (props.position === 1) return '#f1c40f';
      if (props.position === 2) return '#bdc3c7';
      if (props.position === 3) return '#d35400';
      return 'rgba(255, 255, 255, 0.1)';
    }};
  }
`;

const PlayerHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 20px;

  .position {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    background: ${props => {
      if (props.position === 1) return '#f1c40f';
      if (props.position === 2) return '#bdc3c7';
      if (props.position === 3) return '#d35400';
      return 'rgba(255, 255, 255, 0.1)';
    }};
    color: ${props => props.position <= 3 ? '#000' : '#fff'};
  }

  .name {
    font-size: 18px;
    font-weight: 500;
    color: white;
  }
`;

const PlayerStats = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;

  .stat {
    .label {
      color: #888;
      font-size: 13px;
      margin-bottom: 4px;
    }
    
    .value {
      color: white;
      font-size: 16px;
      font-weight: 500;
    }
  }
`;

const LoadingState = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  color: #888;
  font-size: 1.1rem;
`;

const ErrorState = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  color: #e74c3c;
  font-size: 1.1rem;
`;

const PublicHeader = styled.header`
  background: rgba(0, 0, 0, 0.85);
  backdrop-filter: blur(10px);
  padding: 15px 25px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  position: sticky;
  top: 0;
  z-index: 100;
`;

const PublicHeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
`;

const LogoLink = styled.a`
  text-decoration: none;
  color: white;
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 600;
`;

const AuthButton = styled.button`
  padding: 8px 16px;
  background: rgba(46, 204, 113, 0.2);
  color: #2ecc71;
  border: 1.5px solid rgba(46, 204, 113, 0.3);
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s;
  
  &:hover {
    background: rgba(46, 204, 113, 0.3);
    transform: translateY(-1px);
  }
`;

const VerifiedBadge = styled.div`
  display: inline-flex;
  align-items: center;
  margin-left: 16px;
  background: rgba(52, 152, 219, 0.15);
  padding: 6px 16px;
  border-radius: 30px;
  border: 2px solid rgba(52, 152, 219, 0.3);
  gap: 8px;
  box-shadow: 0 2px 8px rgba(52, 152, 219, 0.2);
  transition: all 0.2s ease;
  
  &:hover {
    background: rgba(52, 152, 219, 0.2);
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(52, 152, 219, 0.3);
  }
  
  span {
    color: #3498db;
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.5px;
  }
`;

const VerifiedIcon = () => (
  <svg 
    width="24" 
    height="24" 
    viewBox="0 0 24 24" 
    fill="#3498db"
  >
    <path d="M23 12l-2.44-2.79.34-3.69-3.61-.82-1.89-3.2L12 2.96 8.6 1.5 6.71 4.69 3.1 5.5l.34 3.7L1 12l2.44 2.79-.34 3.7 3.61.82L8.6 22.5l3.4-1.47 3.4 1.46 1.89-3.19 3.61-.82-.34-3.69L23 12zm-12.91 4.72l-3.8-3.81 1.48-1.48 2.32 2.33 5.85-5.87 1.48 1.48-7.33 7.35z"/>
  </svg>
);

const CommunityTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  
  h1 {
    margin: 0;
    font-size: 2.2rem;
    font-weight: 700;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;
  }
`;

const DatePickerContainer = styled.div`
  margin-bottom: 20px;
`;

const DatePickerHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
`;

const DatePickerLabel = styled.label`
  color: #fff;
  font-size: 0.95rem;
`;

const DateTimeInput = styled.input`
  width: 100%;
  padding: 12px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  color: white;
  font-size: 1rem;
`;

const TimeIcon = () => (
  <svg width="16" height="16" viewBox="0 0 24 24" fill="currentColor">
    <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm.5-13H11v6l5.25 3.15.75-1.23-4.5-2.67z"/>
  </svg>
);

const CommunityDetailsPage = () => {
  const { id, name } = useParams();
  const navigate = useNavigate();
  const { user, logout, requireAuth } = useAuth();
  const [community, setCommunity] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [showCreateGameModal, setShowCreateGameModal] = useState(false);
  const [selectedBuyIn, setSelectedBuyIn] = useState('');
  const [createGameError, setCreateGameError] = useState('');
  const [games, setGames] = useState([]);
  const [pendingMembers, setPendingMembers] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isMember, setIsMember] = useState(false);
  const [pendingUsers, setPendingUsers] = useState({});
  const [activeTab, setActiveTab] = useState('active');
  const [completedGames, setCompletedGames] = useState([]);
  const [completedGamesPage, setCompletedGamesPage] = useState(1);
  const [completedGamesTotalPages, setCompletedGamesTotalPages] = useState(1);
  const [isLoadingCompleted, setIsLoadingCompleted] = useState(false);
  const ITEMS_PER_PAGE = 10;
  const [latestGameStandings, setLatestGameStandings] = useState(null);
  const [loadingStandings, setLoadingStandings] = useState(true);
  const [standingsError, setStandingsError] = useState(null);
  const [isScheduled, setIsScheduled] = useState(false);
  const [scheduledTime, setScheduledTime] = useState('');
  const [scheduledGames, setScheduledGames] = useState([]);

  useEffect(() => {
    const fetchCommunityData = async () => {
      try {
        const response = await fetch(`${config.apiUrl}/api/communities/by-name/${encodeURIComponent(name)}`, {
          credentials: 'include',
          headers: {
            'Accept': 'application/json'
          }
        });

      if (!response.ok) {
          throw new Error('Community not found');
      }

      const data = await response.json();
        if (data.success) {
          setCommunity(data.community);
          
          if (user) {
            const adminStatus = checkAdminStatus(data.community, user);
            setIsAdmin(adminStatus);
          }
          
          if (data.community?.members && user?.id) {
            const memberStatus = data.community.members.some(
              member => {
                const memberId = member.userId?._id || member.userId;
                return memberId === user.id;
              }
            );
            setIsMember(memberStatus);
          }
          
          if (data.community?._id) {
            fetchGames(data.community._id);
          }
          
          document.title = `${data.community.name} | Poker Community`;
        } else {
          throw new Error(data.message || 'Failed to fetch community');
        }
      } catch (err) {
        setError(err.message);
    } finally {
      setLoading(false);
    }
    };

    fetchCommunityData();
  }, [id, user?.id]);

  const fetchGames = async (communityId) => {
    if (!communityId) return;

    try {
      const response = await fetch(`${config.apiUrl}/api/games/community/${communityId}`, {
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const data = await response.json();
      if (data.success && Array.isArray(data.games)) {
        // Separate games by status
        const active = data.games.filter(game => 
          game.status === 'WAITING' || game.status === 'PLAYING'
        );
        const scheduled = data.games.filter(game => 
          game.status === 'SCHEDULED'
        );
        
        setGames(active);
        setScheduledGames(scheduled);
        setCommunity(prev => ({
          ...prev,
          activeGames: active.length
        }));
      }
    } catch (error) {
      console.error('Failed to fetch games:', error);
      setGames([]);
      setScheduledGames([]);
    }
  };

  const fetchCompletedGames = async () => {
    try {
      setIsLoadingCompleted(true);
      const response = await fetch(
        `${config.apiUrl}/api/games/community/${community._id}/completed?page=${completedGamesPage}&limit=${ITEMS_PER_PAGE}`
      );
      const data = await response.json();
      
      if (data.success) {
        setCompletedGames(data.games);
        setCompletedGamesTotalPages(data.pagination.pages);
      }
    } catch (error) {
      console.error('Error fetching completed games:', error);
    } finally {
      setIsLoadingCompleted(false);
    }
  };

  // Fetch both active and completed games when community data is loaded
  useEffect(() => {
    if (community?._id) {
      fetchGames(community._id);
      fetchCompletedGames();
    }
  }, [community?._id, completedGamesPage]);

  const handleCreateGameClick = () => {
    setShowCreateGameModal(true);
  };

  const handleCreateGame = async () => {
    if (!selectedBuyIn) {
      setCreateGameError('Please select a buy-in amount');
      return;
    }

    if (isScheduled && !scheduledTime) {
      setCreateGameError('Please select a scheduled time');
      return;
    }

    const buyInAmount = Number(selectedBuyIn);
    const smallBlind = Math.max(1, Math.floor(buyInAmount * 0.01));
    const bigBlind = smallBlind * 2;

    try {
      const response = await fetch(`${config.apiUrl}/api/games/create-standard`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          settings: {
            buyIn: buyInAmount,
            smallBlind,
            bigBlind,
            maxPlayers: 6
          },
          communityId: community._id,
          status: isScheduled ? 'SCHEDULED' : 'WAITING',  // Set the correct initial status
          scheduledTime: isScheduled ? new Date(scheduledTime).toISOString() : null
        }),
        credentials: 'include'
      });

      const data = await response.json();
      if (data.success) {
        setShowCreateGameModal(false);
        setSelectedBuyIn('');
        setScheduledTime('');
        setIsScheduled(false);
        setCreateGameError('');
        await fetchGames(community._id);
      } else {
        setCreateGameError(data.message || 'Failed to create game');
      }
    } catch (error) {
      console.error('Failed to create game:', error);
      setCreateGameError('Failed to create game');
    }
  };

  const fetchPendingMembers = async () => {
    try {
      const response = await fetch(
        `${config.apiUrl}/api/communities/${community._id}/pending-members`,
        {
          credentials: 'include'
        }
      );

      if (!response.ok) {
        throw new Error('Failed to fetch pending members');
      }

      const data = await response.json();
      setPendingMembers(data.pendingMembers || []);
    } catch (error) {
      console.error('Error fetching pending members:', error);
    }
  };

  const handleMemberApproval = async (userId, approved) => {
    try {
      const response = await fetch(`${config.apiUrl}/api/communities/${community._id}/approve-member`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          userId,
          approved
        })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      if (data.success && data.community) {
        setCommunity(data.community);
      }
    } catch (error) {
      console.error('Failed to process member approval:', error);
      alert('Failed to process member approval');
    }
  };

  const handleUpdateSettings = async (formData) => {
    try {
      const response = await fetch(`${config.apiUrl}/api/communities/${community._id}/settings`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({
          name: formData.name,
          description: formData.description,
          whatsappGroup: formData.whatsappGroup,
          settings: {
            joinType: formData.joinType,
            visibility: formData.visibility
          }
        })
      });

      if (!response.ok) {
        throw new Error('Failed to update settings');
      }
      
      const data = await response.json();
      
      // Update community state with new values
      setCommunity(prev => ({
        ...prev,
        name: formData.name,
        description: formData.description,
        whatsappGroup: formData.whatsappGroup,
        settings: {
          joinType: formData.joinType,
          visibility: formData.visibility
        }
      }));
    } catch (error) {
      console.error('Failed to update settings:', error);
    }
  };

  const checkAdminStatus = useCallback((communityData, userData) => {
    if (!communityData?.members || !userData?.id) return false;

    return communityData.members.some(member => {
      const memberId = member.userId?._id || member.userId;
      return memberId?.toString() === userData.id.toString() && member.role === 'admin';
    });
  }, []);

  useEffect(() => {
    if (community) {
      if (user) {
        const adminStatus = checkAdminStatus(community, user);
        setIsAdmin(adminStatus);
      } else {
        setIsAdmin(false);
      }
      fetchGames();
    }
  }, [community?._id, user?.id, checkAdminStatus]);

  useEffect(() => {
    console.log('Admin status updated:', isAdmin);
  }, [isAdmin]);

  const handleSettingsClick = () => {
    setShowSettingsModal(true);
  };

  useEffect(() => {
    if (community?.members && user?.id) {
      const memberStatus = community.members.some(
        member => {
          const memberId = member.userId?._id || member.userId;
          return memberId === user.id;
        }
      );
      setIsMember(memberStatus);
    } else {
      setIsMember(false);
    }
  }, [community?.members, user?.id]);

  const handleJoinRequest = async () => {
    if (!user) {
      // Save the current URL to localStorage before redirecting
      localStorage.setItem('redirectAfterLogin', `/communities/${community._id}`);
      navigate('/auth');
      return;
    }

    try {
      const response = await fetch(
        `${config.apiUrl}/api/communities/${community._id}/join`,
        {
          method: 'POST',
          credentials: 'include'
        }
      );

      if (!response.ok) {
        throw new Error('Failed to send join request');
      }

      // Show success message
      alert('Join request sent successfully!');
    } catch (error) {
      console.error('Error sending join request:', error);
      alert('Failed to send join request');
    }
  };

  useEffect(() => {
    if (isAdmin && community?._id) {
      fetchPendingMembers();
    }
  }, [isAdmin, community?._id]);

  const handleInvite = async () => {
    const inviteLink = `${window.location.origin}/communities/${community._id}`;
    const shareData = {
      title: `Join ${community.name}!`,
      text: `Hey! Join my poker community at HNI Poker.`,
      url: inviteLink
    };

    try {
      // Try Web Share API first
      if (navigator.share) {
        await navigator.share(shareData);
        console.log('Shared successfully');
      } else {
        // Fallback to clipboard copy
        await navigator.clipboard.writeText(inviteLink);
        alert('Invite link copied to clipboard!');
      }
    } catch (error) {
      console.error('Error sharing:', error);
      // Final fallback for older browsers
      const textArea = document.createElement('textarea');
      textArea.value = inviteLink;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand('copy');
        alert('Invite link copied to clipboard!');
      } catch (err) {
        console.error('Fallback: Failed to copy:', err);
        alert('Failed to copy invite link. Please copy manually: ' + inviteLink);
      }
      document.body.removeChild(textArea);
    }
  };

  useEffect(() => {
    if (community) {
      console.log('Community Data:', community);
      console.log('Pending Members:', community.pendingMembers);
    }
  }, [community]);

  useEffect(() => {
    const fetchPendingUsers = async () => {
      if (community?.pendingMembers) {
        const userDetails = {};
        for (const pending of community.pendingMembers) {
          try {
            const response = await fetch(`${config.apiUrl}/users/${pending.userId}`);
            if (response.ok) {
              const userData = await response.json();
              userDetails[pending.userId] = userData;
            }
    } catch (error) {
            console.error('Error fetching user details:', error);
          }
        }
        setPendingUsers(userDetails);
    }
  };

    fetchPendingUsers();
  }, [community?.pendingMembers]);

  const handleEndGame = async (gameId) => {
    try {
      const response = await fetch(`${config.apiUrl}/api/games/${gameId}/complete`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include'
      });

      if (!response.ok) {
        throw new Error('Failed to end game');
      }

      // Refresh games list after ending the game
      await fetchGames(community._id);
      
      // If we're on the completed tab, also refresh completed games
      if (activeTab === 'completed') {
        await fetchCompletedGames();
      }
    } catch (error) {
      console.error('Error ending game:', error);
    }
  };

  // Fetch standings for the latest completed game
  useEffect(() => {
    const fetchLatestGameStandings = async () => {
      if (!completedGames?.[0]?.gameId) return;
      
      try {
        setLoadingStandings(true);
        const response = await fetch(
          `${config.apiUrl}/api/games/${completedGames[0].gameId}/standings`,
          { credentials: 'include' }
        );
        
        const data = await response.json();
        if (data.success) {
          setLatestGameStandings(data.standings);
        } else {
          throw new Error(data.message || 'Failed to fetch standings');
        }
      } catch (err) {
        console.error('Error fetching standings:', err);
        setStandingsError(err.message);
      } finally {
        setLoadingStandings(false);
      }
    };

    fetchLatestGameStandings();
  }, [completedGames]);

  // Add this section after the Active Tables section and before the Completed Tables section
  const ScheduledGamesSection = () => (
    <GamesSection>
      <SectionHeader>
        <h2>Scheduled Tables</h2>
      </SectionHeader>

      <GamesList>
        {scheduledGames.length === 0 ? (
          <NoGamesMessage>No scheduled games</NoGamesMessage>
        ) : (
          scheduledGames.map((game) => (
            <GameCard
              key={game.gameId}
              game={game}
              userId={user?.id}
              isLoggedIn={!!user}
              onGameUpdate={() => fetchGames(community._id)}
              onInvite={handleInvite}
              onEndGame={handleEndGame}
              isScheduled={true}
              requireAuth={() => {
                if (!user) {
                  localStorage.setItem('redirectAfterLogin', `/table/${game.gameId}`);
                  navigate('/auth');
                  return false;
                }
                return true;
              }}
            />
          ))
        )}
      </GamesList>
    </GamesSection>
  );

  if (loading) {
    return (
      <PageWrapper>
        <ContentWrapper>
          <LoadingMessage>Loading community details...</LoadingMessage>
        </ContentWrapper>
      </PageWrapper>
    );
  }

  if (error) {
    return (
      <PageWrapper>
        <ContentWrapper>
      <ErrorMessage>{error}</ErrorMessage>
          <BackButton onClick={() => navigate(-1)}>
            Back to Communities
          </BackButton>
        </ContentWrapper>
      </PageWrapper>
    );
  }

  if (!community) {
    return (
      <PageWrapper>
        <ContentWrapper>
          <ErrorMessage>Community not found</ErrorMessage>
          <BackButton onClick={() => navigate(-1)}>
            Back to Communities
          </BackButton>
        </ContentWrapper>
      </PageWrapper>
    );
  }

  return (
    <>
      <Helmet>
        <title>{community?.name ? `${community.name} | HNI Poker Community` : 'Poker Community'}</title>
        <meta name="description" content={community?.description || 'Join our poker community and play with friends.'} />
        
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content={community?.name ? `${community.name} | HNI Poker Community` : 'Poker Community'} />
        <meta property="og:description" content={community?.description || 'Join our poker community and play with friends.'} />
        <meta property="og:url" content={window.location.href} />
        
        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={community?.name ? `${community.name} | HNI Poker Community` : 'Poker Community'} />
        <meta name="twitter:description" content={community?.description || 'Join our poker community and play with friends.'} />
        
        {/* Additional Meta Tags */}
        <meta name="keywords" content="poker, online poker, poker community, card games, multiplayer poker" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>

      {!user && (
        <PublicHeader>
          <PublicHeaderContent>
            <LogoLink href="/">
              HNI Poker
            </LogoLink>
            <AuthButton onClick={() => navigate('/auth')}>
              Sign In
            </AuthButton>
          </PublicHeaderContent>
        </PublicHeader>
      )}
      <Layout>
        <PageWrapper>
      <ContentWrapper>
        <Header>
              <BackButton onClick={() => window.history.back()}>
                ← Back
              </BackButton>
              <CommunityTitle>
                <h1>
                  {community?.name}
                  {community?.isVerified && (
                    <VerifiedBadge title="Official Verified Community">
                      <VerifiedIcon />
                      <span>Verified</span>
                    </VerifiedBadge>
                  )}
                </h1>
              </CommunityTitle>
              {isAdmin && (
                <AdminButtonGroup>
                  <AdminSettingsButton onClick={handleSettingsClick}>
                    <SettingsIcon />
                    Settings
                  </AdminSettingsButton>
                  <ShareButton onClick={handleInvite}>
                    <ShareIcon />
                    Share
                  </ShareButton>
                </AdminButtonGroup>
              )}
              {!isMember && (
                <JoinCommunityButton onClick={handleJoinRequest}>
                  Join Community
                </JoinCommunityButton>
              )}
        </Header>

        <CommunityInfo>
              <Description>{community?.description}</Description>
              
              {community?.whatsappGroup && (
                <WhatsAppLink 
                  href={community.whatsappGroup} 
                  target="_blank" 
                  rel="noopener noreferrer"
                >
                  <WhatsAppSection>
                    <WhatsAppIconWrapper>
                      <WhatsAppIcon />
                      <NotificationBadge>
                        {Math.floor(Math.random() * 5) + 2}
                      </NotificationBadge>
                    </WhatsAppIconWrapper>
                    <WhatsAppText>Join our WhatsApp group to get updates</WhatsAppText>
                  </WhatsAppSection>
                </WhatsAppLink>
              )}

              <MembersContainer>
                <SectionCard>
                  <MemberSectionHeader>
                    <h3>
                      <svg width="20" height="20" viewBox="0 0 24 24" fill="currentColor">
                        <path d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5s-3 1.34-3 3 1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5 5 6.34 5 8s1.34 3 3 3z"/>
                      </svg>
                      Members
                    </h3>
                    <MemberCount>{community?.members?.length || 0} members</MemberCount>
                  </MemberSectionHeader>
            <MembersList>
                    {community?.members?.map((member) => (
                      <MemberCard key={member.userId._id}>
                        <span>{member.userId.username}</span>
                        <RoleBadge>{member.role}</RoleBadge>
                      </MemberCard>
              ))}
            </MembersList>
                </SectionCard>

        {isAdmin && (
                  <SectionCard>
                    <MemberSectionHeader>
                      <h3>
                        <svg width="20" height="20" viewBox="0 0 24 24" fill="currentColor">
                          <path d="M17 12h-5v5h5v-5zM16 1v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2h-1V1h-2zm3 18H5V8h14v11z"/>
                        </svg>
                        Pending Requests
                      </h3>
                      <PendingCount>{community?.pendingMembers?.length || 0} pending</PendingCount>
                    </MemberSectionHeader>
                    <MembersList>
                      {community?.pendingMembers?.map((pending) => (
                        <MemberCard key={pending._id}>
                          <span>{pending.userId.username}</span>
                          <PendingActions>
                            <AcceptButton onClick={() => handleMemberApproval(pending.userId._id, true)}>
                              Accept
                            </AcceptButton>
                            <RejectButton onClick={() => handleMemberApproval(pending.userId._id, false)}>
                              Reject
                            </RejectButton>
                          </PendingActions>
                        </MemberCard>
                      ))}
                      {(!community?.pendingMembers || community.pendingMembers.length === 0) && (
                        <div style={{ color: '#666', textAlign: 'center', padding: '20px' }}>
                          No pending requests
                        </div>
                      )}
                    </MembersList>
                  </SectionCard>
                )}
              </MembersContainer>
            </CommunityInfo>

            <StatsContainer>
              <StatCard>
                <StatValue>
                  {games.filter(game => game.status === 'WAITING' || game.status === 'PLAYING').length}
                </StatValue>
                <StatLabel>Active Tables</StatLabel>
              </StatCard>
              <StatCard>
                <StatValue>{scheduledGames.length}</StatValue>
                <StatLabel>Scheduled Tables</StatLabel>
              </StatCard>
              <StatCard>
                <StatValue>{completedGames.length}</StatValue>
                <StatLabel>Completed Tables</StatLabel>
              </StatCard>
            </StatsContainer>

            {completedGames.length > 0 && (
              <SectionCard>
                <MemberSectionHeader>
                  <h3>
                    <svg width="20" height="20" viewBox="0 0 24 24" fill="currentColor">
                      <path d="M19 3h-4.18C14.4 1.84 13.3 1 12 1s-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm7 18H5V8h14v11z"/>
                    </svg>
                    Latest Game Result
                  </h3>
                </MemberSectionHeader>

                {loadingStandings ? (
                  <LoadingState>Loading statistics...</LoadingState>
                ) : standingsError ? (
                  <ErrorState>{standingsError}</ErrorState>
                ) : (
                  <>
                    <Podium>
                      {[2, 1, 3].map(position => {
                        const player = latestGameStandings?.[position - 1];
                        if (!player) return null;
                        
                        return (
                          <PodiumPlace key={position} position={position}>
                            <div className="platform">
                              <div className="position">{position}</div>
                            </div>
                            <div className="player-info">
                              <div className="name">{player.username}</div>
                              <div className="profit" style={{
                                color: player.netResult > 0 ? '#2ecc71' : '#e74c3c'
                              }}>
                                {player.netResult > 0 ? '+' : ''}{player.netResult.toLocaleString()}
                              </div>
                            </div>
                          </PodiumPlace>
                        );
                      })}
                    </Podium>

                    <PlayerGrid>
                      {latestGameStandings?.map((player, index) => (
                        <PlayerCard key={player.userId} position={index + 1}>
                          <PlayerHeader position={index + 1}>
                            <div className="position">{index + 1}</div>
                            <div className="name">{player.username}</div>
                          </PlayerHeader>
                          <PlayerStats>
                            <div className="stat">
                              <div className="label">Net Profit</div>
                              <div className="value" style={{ 
                                color: player.netResult > 0 ? '#2ecc71' : '#e74c3c'
                              }}>
                                {player.netResult > 0 ? '+' : ''}{player.netResult.toLocaleString()}
                              </div>
                            </div>
                            <div className="stat">
                              <div className="label">Hands Won</div>
                              <div className="value">{player.handsWon}</div>
                            </div>
                            <div className="stat">
                              <div className="label">Win Rate</div>
                              <div className="value">{player.winRate}%</div>
                            </div>
                            <div className="stat">
                              <div className="label">Total Buyins</div>
                              <div className="value">{player.totalBuyins || 1}</div>
                            </div>
                            <div className="stat">
                              <div className="label">Biggest Pot</div>
                              <div className="value">{player.biggestPot?.toLocaleString() || 0}</div>
                            </div>
                            <div className="stat">
                              <div className="label">Profit/Hand</div>
                              <div className="value">{player.profitPerHand}</div>
                            </div>
                          </PlayerStats>
                        </PlayerCard>
                      ))}
                    </PlayerGrid>
                  </>
                )}

                <ActivityTime>
                  {formatTimeAgo(completedGames[0].endTime)}
                </ActivityTime>
              </SectionCard>
            )}

            <GamesSection>
          <SectionHeader>
                <h2>Active Tables</h2>
          </SectionHeader>

              <GamesList>
                {games.map((game) => (
                  <GameCard
                    key={game.gameId}
                    game={game}
                    userId={user?.id}
                    isLoggedIn={!!user}
                    onGameUpdate={() => fetchGames(community._id)}
                    onInvite={handleInvite}
                    onEndGame={handleEndGame}
                    requireAuth={() => {
                      if (!user) {
                        // Save current location for redirect after login
                        localStorage.setItem('redirectAfterLogin', `/table/${game.gameId}`);
                        navigate('/auth');
                        return false;
                      }
                      return true;
                    }}
                  />
                ))}
                {isAdmin && (
                  <GameCard
                    isCreateCard
                    onCreate={() => setShowCreateGameModal(true)}
                    customTitle="Create New Table"
                    customDescription="Start a new poker table"
                  />
                )}
              </GamesList>
            </GamesSection>

            <ScheduledGamesSection />

            <GamesSection>
              <SectionHeader>
                <h2>Completed Tables</h2>
              </SectionHeader>

              <GamesList>
                {isLoadingCompleted ? (
                  <LoadingMessage>Loading completed games...</LoadingMessage>
                ) : (
                  completedGames.map((game) => (
                    <GameCard
                      key={game.gameId}
                      game={game}
                      userId={user?.id}
                      isCompleted={true}
                      isLoggedIn={true}
                      requireAuth={false}
                    />
                  ))
                )}
                {completedGamesTotalPages > 1 && (
                  <Pagination
                    currentPage={completedGamesPage}
                    totalPages={completedGamesTotalPages}
                    onPageChange={(page) => {
                      setCompletedGamesPage(page);
                      window.scrollTo(0, 0);
                    }}
                  />
                )}
              </GamesList>
          </GamesSection>

            {showSettingsModal && (
              <Modal
                isOpen={showSettingsModal}
                onClose={() => setShowSettingsModal(false)}
                title="Community Settings"
              >
                <CommunitySettingsForm
                  community={community}
                  onSave={async (formData) => {
                    try {
                      const response = await fetch(`${config.apiUrl}/api/communities/${community._id}/settings`, {
                        method: 'PUT',
                        headers: {
                          'Content-Type': 'application/json'
                        },
                        credentials: 'include',
                        body: JSON.stringify({
                          name: formData.name,
                          description: formData.description,
                          whatsappGroup: formData.whatsappGroup,
                          settings: {
                            joinType: formData.joinType,
                            visibility: formData.visibility
                          }
                        })
                      });

                      if (!response.ok) {
                        throw new Error('Failed to update settings');
                      }
                      
                      const data = await response.json();
                      
                      // Update community state with new values
                      setCommunity(prev => ({
                        ...prev,
                        name: formData.name,
                        description: formData.description,
                        whatsappGroup: formData.whatsappGroup,
                        settings: {
                          joinType: formData.joinType,
                          visibility: formData.visibility
                        }
                      }));
                      setShowSettingsModal(false);
                    } catch (error) {
                      console.error('Failed to update settings:', error);
                    }
                  }}
                  onCancel={() => setShowSettingsModal(false)}
                />
      </Modal>
            )}

            {showCreateGameModal && (
              <Modal
                isOpen={showCreateGameModal}
                onClose={() => {
                  setShowCreateGameModal(false);
                  setSelectedBuyIn('');
                  setScheduledTime('');
                  setIsScheduled(false);
                  setCreateGameError('');
                }}
                title="Create New Game"
              >
                <div style={{ padding: '20px' }}>
                  <div style={{ marginBottom: '20px' }}>
                    <label style={{ 
                      display: 'block', 
                      marginBottom: '10px',
                      color: 'white'
                    }}>
                      Game Type
                    </label>
                    <div style={{ 
                      display: 'flex',
                      gap: '10px'
                    }}>
                      <button
                        onClick={() => setIsScheduled(false)}
                        style={{
                          flex: 1,
                          padding: '12px',
                          background: !isScheduled ? 'rgba(46, 204, 113, 0.3)' : 'rgba(255, 255, 255, 0.1)',
                          border: `2px solid ${!isScheduled ? '#2ecc71' : 'transparent'}`,
                          borderRadius: '8px',
                          color: 'white',
                          cursor: 'pointer'
                        }}
                      >
                        Start Now
                      </button>
                      <button
                        onClick={() => setIsScheduled(true)}
                        style={{
                          flex: 1,
                          padding: '12px',
                          background: isScheduled ? 'rgba(46, 204, 113, 0.3)' : 'rgba(255, 255, 255, 0.1)',
                          border: `2px solid ${isScheduled ? '#2ecc71' : 'transparent'}`,
                          borderRadius: '8px',
                          color: 'white',
                          cursor: 'pointer'
                        }}
                      >
                        Schedule
                      </button>
                    </div>
                  </div>

                  {isScheduled && (
            <DatePickerContainer>
              <DatePickerHeader>
                        <DatePickerLabel>
                  <TimeIcon />
                          Schedule Game
                </DatePickerLabel>
              </DatePickerHeader>
              <DateTimeInput
                type="datetime-local"
                        value={scheduledTime}
                        onChange={(e) => {
                          const selectedDate = new Date(e.target.value);
                          const now = new Date();
                          const minTime = new Date(now.getTime() + 5 * 60000);
                          
                          if (selectedDate < minTime) {
                            setCreateGameError('Please select a time at least 5 minutes in the future');
                            return;
                          }
                          
                          setCreateGameError('');
                          setScheduledTime(e.target.value);
                        }}
                        min={new Date(Date.now() + 5 * 60000).toISOString().slice(0, 16)}
                max={new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString().slice(0, 16)}
              />
            </DatePickerContainer>
                  )}

                  <div style={{ marginBottom: '20px' }}>
                    <label style={{ 
                      display: 'block', 
                      marginBottom: '10px',
                      color: 'white',
                      fontWeight: '500'
                    }}>
                      Buy-in Amount
                    </label>
                    <p style={{
                      color: '#888',
                      fontSize: '0.9rem',
                      marginBottom: '15px'
                    }}>
                      Select the buy-in amount for your game. Small blind will be 1% and big blind will be 2% of the buy-in.
                    </p>
                    <div style={{ 
                      display: 'grid', 
                      gridTemplateColumns: 'repeat(2, 1fr)', 
                      gap: '12px',
                    }}>
                      {[500, 1000, 2000, 5000, 10000].map((amount) => (
                        <button
                          key={amount}
                          onClick={() => setSelectedBuyIn(amount.toString())}
                          style={{
                            padding: '16px',
                            background: selectedBuyIn === amount.toString() ? 'rgba(46, 204, 113, 0.3)' : 'rgba(255, 255, 255, 0.1)',
                            border: `2px solid ${selectedBuyIn === amount.toString() ? '#2ecc71' : 'transparent'}`,
                            borderRadius: '8px',
                            color: 'white',
                            cursor: 'pointer'
                          }}
                        >
                          {amount.toLocaleString()} chips
                          <div style={{
                            fontSize: '0.8rem',
                            color: '#888',
                            marginTop: '4px'
                          }}>
                            SB: {Math.floor(amount * 0.01)} / BB: {Math.floor(amount * 0.02)}
                          </div>
                        </button>
                      ))}
                    </div>
                  </div>

                  {createGameError && (
                    <div style={{ color: '#ff4444', marginBottom: '15px', fontSize: '0.9rem' }}>
                      {createGameError}
                    </div>
                  )}

                  <ButtonGroup>
                    <CancelButton onClick={() => setShowCreateGameModal(false)}>
                      Cancel
                    </CancelButton>
                    <CreateButton 
                      onClick={handleCreateGame}
                      disabled={!selectedBuyIn || (isScheduled && !scheduledTime)}
                    >
                      Create Game
                    </CreateButton>
                  </ButtonGroup>
                </div>
      </Modal>
            )}
          </ContentWrapper>
    </PageWrapper>
      </Layout>
    </>
  );
};

export default CommunityDetailsPage;

