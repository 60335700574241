import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useAuth } from '../contexts/AuthContext';
import axios from 'axios';
import Pagination from '../components/ui/Pagination';
import Footer from '../components/Footer';
import GameCard from '../components/GameCard/GameCard';

const TablesWrapper = styled.div`
  min-height: 100vh;
  background: #1a1a1a;
  color: white;
  padding: 20px;
  padding-bottom: 5rem;
`;

const TablesHeader = styled.header`
  background: rgba(0, 0, 0, 0.85);
  backdrop-filter: blur(10px);
  padding: 15px 25px;
  border-radius: 15px;
  margin-bottom: 20px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
`;

const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;

  h1 {
    margin: 0;
    font-size: 2rem;
    font-weight: 700;
    color: #fff;
  }
`;

const TablesContent = styled.main`
  max-width: 1200px;
  margin: 0 auto;
`;

const TabsContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 1.5rem;
`;

const Tab = styled.button`
  padding: 8px 16px;
  background: ${props => props.$active ? 'rgba(46, 204, 113, 0.2)' : 'transparent'};
  border: 2px solid ${props => props.$active ? '#2ecc71' : 'rgba(46, 204, 113, 0.3)'};
  color: ${props => props.$active ? '#2ecc71' : 'rgba(46, 204, 113, 0.8)'};
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: ${props => props.$active ? 'rgba(46, 204, 113, 0.3)' : 'rgba(46, 204, 113, 0.1)'};
  }
`;

const GamesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const EmptyState = styled.div`
  text-align: center;
  padding: 40px;
  color: rgba(255, 255, 255, 0.6);
  font-size: 1.1rem;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 12px;
  margin: 20px 0;
`;

const LoadingState = styled.div`
  text-align: center;
  padding: 40px;
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.1rem;
`;

const ErrorState = styled.div`
  text-align: center;
  padding: 20px;
  color: #e74c3c;
  background: rgba(231, 76, 60, 0.1);
  border: 1px solid rgba(231, 76, 60, 0.3);
  border-radius: 8px;
  margin: 20px 0;
`;

const Tables = () => {
  const [activeTab, setActiveTab] = useState('active');
  const [tables, setTables] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const { user } = useAuth();

  useEffect(() => {
    const fetchTables = async () => {
      try {
        setLoading(true);
        
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/games/user/all-games`,
          {
            params: {
              page: currentPage,
              limit: 10
            },
            withCredentials: true,
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            }
          }
        );

        setTables(response.data.games);
        setTotalPages(response.data.pagination.pages);
        setError(null);
      } catch (err) {
        console.error('Error fetching tables:', err);
        if (err.response) {
          console.log('Error response:', err.response.data);
          console.log('Error status:', err.response.status);
          if (err.response.status === 401) {
            setError('Session expired. Please log in again.');
          } else {
            setError(`Failed to load tables: ${err.response.data.message || 'Unknown error'}`);
          }
        } else {
          setError('Failed to connect to the server. Please try again later.');
        }
      } finally {
        setLoading(false);
      }
    };

    if (user) {
      fetchTables();
    }
  }, [user, currentPage]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const filteredTables = tables.filter(table => {
    switch (activeTab) {
      case 'active':
        return table.status === 'WAITING' || table.status === 'PLAYING';
      case 'scheduled':
        return table.status === 'SCHEDULED';
      case 'completed':
        return table.status === 'COMPLETED';
      default:
        return false;
    }
  });

  return (
    <>
      <TablesWrapper>
        <TablesHeader>
          <HeaderContent>
            <h1>My Tables</h1>
          </HeaderContent>
        </TablesHeader>

        <TablesContent>
          <TabsContainer>
            <Tab 
              $active={activeTab === 'active'} 
              onClick={() => setActiveTab('active')}
            >
              Active Games
            </Tab>
            <Tab 
              $active={activeTab === 'scheduled'} 
              onClick={() => setActiveTab('scheduled')}
            >
              Scheduled Games
            </Tab>
            <Tab 
              $active={activeTab === 'completed'} 
              onClick={() => setActiveTab('completed')}
            >
              Completed Games
            </Tab>
          </TabsContainer>

          {loading && (
            <LoadingState>Loading your games...</LoadingState>
          )}
          
          {error && (
            <ErrorState>{error}</ErrorState>
          )}

          {!loading && !error && (
            <GamesContainer>
              {filteredTables.map(game => (
                <GameCard
                  key={game.gameId}
                  game={game}
                  userId={user?._id}
                  isLoggedIn={!!user}
                  isCompleted={game.status === 'COMPLETED'}
                />
              ))}

              {filteredTables.length === 0 && (
                <EmptyState>
                  No {activeTab} games found
                </EmptyState>
              )}

              {tables.length > 0 && (
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              )}
            </GamesContainer>
          )}
        </TablesContent>
      </TablesWrapper>
      <Footer />
    </>
  );
};

export default Tables; 