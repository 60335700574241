import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { IoHomeOutline, IoHomeSharp } from 'react-icons/io5';
import { IoGameControllerOutline, IoGameController } from 'react-icons/io5';
import { CgProfile } from 'react-icons/cg';
import { HiOutlineUserGroup, HiUserGroup } from 'react-icons/hi';
import { useLocation } from 'react-router-dom';

const FooterNav = styled.footer`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #1a1a1a;
  padding: 0.8rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 1000;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
`;

const NavLink = styled(Link)`
  color: ${props => props.$isActive ? '#2ecc71' : '#888'};
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  font-size: 0.75rem;
  
  svg {
    font-size: 1.5rem;
  }
  
  &:hover {
    color: #2ecc71;
  }
`;

const Footer = ({ visible = true }) => {
  const location = useLocation();

  const isActive = (path) => {
    if (path === '/lobby') {
      return location.pathname === '/' || location.pathname === '/lobby';
    }
    return location.pathname.startsWith(path);
  };

  if (!visible) return null;

  return (
    <FooterNav>
      <NavLink to="/lobby" $isActive={isActive('/lobby')}>
        {isActive('/lobby') ? <IoHomeSharp /> : <IoHomeOutline />}
        Lobby
      </NavLink>
      <NavLink to="/tables" $isActive={isActive('/tables')}>
        {isActive('/tables') ? <IoGameController /> : <IoGameControllerOutline />}
        Tables
      </NavLink>
      <NavLink to="/communities" $isActive={isActive('/communities')}>
        {isActive('/communities') ? <HiUserGroup /> : <HiOutlineUserGroup />}
        Communities
      </NavLink>
      <NavLink to="/profile" $isActive={isActive('/profile')}>
        <CgProfile />
        Profile
      </NavLink>
    </FooterNav>
  );
};

export default Footer; 