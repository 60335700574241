import React from 'react';

export function TableEdge({ texture }) {
  const baseRadius = 3;
  const increasedRadius = baseRadius * 1.25; // Match TableTop radius

  return (
    <mesh castShadow rotation={[Math.PI / 2, 0, 0]}>
      <torusGeometry args={[increasedRadius, 0.2, 16, 32]} />
      <meshPhongMaterial 
        map={texture}
        color={0x4a2105}
      />
    </mesh>
  );
} 