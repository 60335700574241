import React from 'react';

export function TableLegs({ texture }) {
  const legPositions = [
    [2, -1, 2],
    [-2, -1, 2],
    [2, -1, -2],
    [-2, -1, -2]
  ];

  return (
    <>
      {legPositions.map((position, index) => (
        <mesh
          key={index}
          castShadow
          position={position}
        >
          <cylinderGeometry args={[0.1, 0.1, 2, 8]} />
          <meshPhongMaterial 
            map={texture}
            color={0x4a2105}
          />
        </mesh>
      ))}
    </>
  );
} 