import React, { useRef, useEffect } from 'react';
import { Text } from '@react-three/drei';
import * as THREE from 'three';
import gsap from 'gsap';

const chipTypes = [
  { value: 5000, color: 0x800080 }, // Purple chips
  { value: 2000, color: 0xFFA500 }, // Orange chips
  { value: 1000, color: 0xFFD700 }, // Gold chips
  { value: 500, color: 0x4B0082 },  // Indigo chips
  { value: 100, color: 0x000000 },  // Black chips
  { value: 25, color: 0x00ff00 },   // Green chips
  { value: 10, color: 0x0000ff },   // Blue chips
  { value: 5, color: 0xff0000 }     // Red chips
];

// Move getRingColor to module scope
const getRingColor = (value) => {
  switch(value) {
    case 5000: return 0xE6E6FA; // Light purple
    case 2000: return 0xFFE4B5; // Light orange
    case 1000: return 0xFFF8DC; // Light gold
    case 500: return 0x9370DB;  // Light indigo
    default: return 0x999999;   // Default gray
  }
};

function PokerChip({ value, color, position }) {
  return (
    <group position={position}>
      {/* Main chip body (dark outer ring) */}
      <mesh castShadow receiveShadow>
        <cylinderGeometry args={[0.2, 0.2, 0.03, 32]} />
        <meshPhongMaterial color={0x333333} />
      </mesh>

      {/* Middle ring (custom color for high-value chips) */}
      <mesh position={[0, 0.001, 0]}>
        <cylinderGeometry args={[0.18, 0.18, 0.032, 32]} />
        <meshPhongMaterial color={getRingColor(value)} />
      </mesh>

      {/* Inner colored section */}
      <mesh position={[0, 0.002, 0]}>
        <cylinderGeometry args={[0.16, 0.16, 0.034, 32]} />
        <meshPhongMaterial color={color} />
      </mesh>

      {/* Edge notches */}
      {Array.from({ length: 32 }).map((_, i) => (
        <mesh 
          key={i} 
          position={[
            0.19 * Math.cos((i * Math.PI * 2) / 32),
            0,
            0.19 * Math.sin((i * Math.PI * 2) / 32)
          ]}
        >
          <boxGeometry args={[0.02, 0.03, 0.02]} />
          <meshPhongMaterial color={color} />
        </mesh>
      ))}

      {/* Value text */}
      <Text
        position={[0, 0.018, 0]}
        rotation={[-Math.PI / 2, 0, 0]}
        fontSize={value >= 1000 ? 0.06 : 0.08} // Smaller font for larger numbers
        color="#ffffff"
        anchorX="center"
        anchorY="middle"
        font="/fonts/DigitalReadout-Regular.ttf"
      >
        {value >= 1000 ? `${value/1000}K` : value}
      </Text>
    </group>
  );
}

export function ChipStacks({ gameState }) {
  const potRef = useRef();
  const lastPotAmount = useRef(0);

  const calculateChipStacks = (amount) => {
    const stacks = [];
    let remainingAmount = amount;

    chipTypes.forEach(({ value, color }) => {
      const count = Math.floor(remainingAmount / value);
      if (count > 0) {
        stacks.push({
          value,
          color,
          count: Math.min(count, 10) // Limit stack height
        });
        remainingAmount -= count * value;
      }
    });

    return stacks;
  };

  // Handle pot changes
  useEffect(() => {
    if (!potRef.current) return;
    
    const currentPot = gameState?.pot || 0;
    const previousPot = lastPotAmount.current;
    
    if (currentPot > previousPot) {
      const betAmount = currentPot - previousPot;
      const chipCount = Math.min(Math.ceil(betAmount / 100), 15); // Adjusted base value

      for (let i = 0; i < chipCount; i++) {
        const chipGroup = new THREE.Group();
        const chipType = chipTypes[i % chipTypes.length];
        
        // Main chip body (dark outer ring)
        const mainBody = new THREE.Mesh(
          new THREE.CylinderGeometry(0.2, 0.2, 0.03, 32),
          new THREE.MeshPhongMaterial({ color: 0x333333 })
        );
        chipGroup.add(mainBody);

        // Middle ring (custom color for high-value chips)
        const middleRing = new THREE.Mesh(
          new THREE.CylinderGeometry(0.18, 0.18, 0.032, 32),
          new THREE.MeshPhongMaterial({ 
            color: getRingColor(chipType.value) 
          })
        );
        middleRing.position.y = 0.001;
        chipGroup.add(middleRing);

        // Inner colored section
        const innerSection = new THREE.Mesh(
          new THREE.CylinderGeometry(0.16, 0.16, 0.034, 32),
          new THREE.MeshPhongMaterial({ color: chipType.color })
        );
        innerSection.position.y = 0.002;
        chipGroup.add(innerSection);

        // Edge notches
        for (let j = 0; j < 32; j++) {
          const notch = new THREE.Mesh(
            new THREE.BoxGeometry(0.02, 0.03, 0.02),
            new THREE.MeshPhongMaterial({ color: chipType.color })
          );
          notch.position.set(
            0.19 * Math.cos((j * Math.PI * 2) / 32),
            0,
            0.19 * Math.sin((j * Math.PI * 2) / 32)
          );
          chipGroup.add(notch);
        }

        // Random starting positions above the pot
        const angle = (Math.random() * Math.PI * 2);
        const radius = 1 + Math.random() * 0.5;
        chipGroup.position.set(
          Math.cos(angle) * radius,
          4 + Math.random(),
          -1 + Math.sin(angle) * radius
        );

        // Random initial rotation
        chipGroup.rotation.set(
          Math.random() * Math.PI,
          Math.random() * Math.PI,
          Math.random() * Math.PI
        );

        potRef.current.add(chipGroup);

        // Animate falling to pot
        gsap.to(chipGroup.position, {
          x: (i % 3) * 0.3 - 0.3,
          y: 0.1 + Math.floor(i / 3) * 0.05,
          z: 1,
          duration: 1.5 + Math.random() * 0.5,
          delay: i * 0.05,
          ease: "bounce.out",
          onComplete: () => {
            // Settle rotation when landed
            gsap.to(chipGroup.rotation, {
              x: 0,
              y: 0,
              z: 0,
              duration: 0.3,
              ease: "power2.out"
            });
          }
        });

        // Spin while falling
        gsap.to(chipGroup.rotation, {
          x: Math.random() * Math.PI * 4,
          y: Math.random() * Math.PI * 4,
          z: Math.random() * Math.PI * 4,
          duration: 1.5 + Math.random() * 0.5,
          delay: i * 0.05,
          ease: "power1.inOut"
        });
      }

      // Cleanup animated chips
      setTimeout(() => {
        if (potRef.current) {
          gsap.to(potRef.current.children, {
            opacity: 0,
            duration: 0.5,
            stagger: 0.02,
            onComplete: () => {
              while(potRef.current?.children.length > 0) {
                potRef.current.remove(potRef.current.children[0]);
              }
            }
          });
        }
      }, 4000);
    }

    lastPotAmount.current = currentPot;
  }, [gameState?.pot]);

  // Calculate pot stacks
  const potStacks = calculateChipStacks(gameState?.pot || 0);

  return (
    <group>
      {/* Container for animated chips */}
      <group ref={potRef} />

      {/* Static pot display - Updated position */}
      <group position={[0, 0, 1]}>  {/* Moved up and forward */}
        <Text
          position={[0, 0.5, 0]}
          fontSize={0.2}
          color="#ffffff"
          anchorX="center"
          anchorY="middle"
          font="/fonts/DigitalReadout-Regular.ttf"
          outlineWidth={0.02}
          outlineColor="#000000"
        >
          {`POT: ${gameState?.pot?.toLocaleString() || 0}`}
        </Text>

        {potStacks.map((stack, stackIndex) => (
          Array(stack.count).fill(null).map((_, chipIndex) => (
            <PokerChip
              key={`pot-stack${stackIndex}-chip${chipIndex}`}
              value={stack.value}
              color={stack.color}
              position={[
                stackIndex * 0.5 - (potStacks.length * 0.5) / 2,
                0.1 + chipIndex * 0.05,
                0
              ]}
            />
          ))
        ))}
      </group>
    </group>
  );
} 