import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet-async';

const PrivacyPolicyPage = () => {
  return (
    <>
      <Helmet>
        <title>Privacy Policy | HNI Poker</title>
        <meta name="description" content="Privacy Policy for HNI Poker - Learn how we protect your privacy and handle your data." />
        <meta property="og:title" content="Privacy Policy | HNI Poker" />
        <meta property="og:description" content="Privacy Policy for HNI Poker - Learn how we protect your privacy and handle your data." />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <PageWrapper>
        <ContentWrapper>
          <Header>
            <h1>Privacy Policy</h1>
            <p>Last updated: {new Date().toLocaleDateString()}</p>
          </Header>

          <PolicySection>
            <h2>1. Introduction</h2>
            <p>Welcome to HNI Poker ("we," "our," or "us"). We respect your privacy and are committed to protecting your personal data. This privacy policy will inform you about how we handle your personal information when you visit our website (hni.app) and tell you about your privacy rights and how the law protects you.</p>

            <h2>2. Information We Collect</h2>
            <h3>2.1 Account Information</h3>
            <ul>
              <li>Username and email address</li>
              <li>Password (encrypted)</li>
              <li>Profile information (avatar, bio)</li>
              <li>Age verification information</li>
            </ul>

            <h3>2.2 Gaming Information</h3>
            <ul>
              <li>Game play history and statistics</li>
              <li>Virtual currency transactions</li>
              <li>Community participation and interactions</li>
              <li>Chat logs and communications</li>
              <li>Tournament participation records</li>
            </ul>

            <h3>2.3 Technical Information</h3>
            <ul>
              <li>IP address and location data</li>
              <li>Browser type and version</li>
              <li>Device information and identifiers</li>
              <li>Operating system</li>
              <li>Connection information</li>
            </ul>

            <h2>3. How We Use Your Information</h2>
            <h3>3.1 Primary Purposes</h3>
            <ul>
              <li>To provide and maintain our poker platform</li>
              <li>To verify your identity and prevent fraud</li>
              <li>To process your transactions and manage your account</li>
              <li>To ensure fair play and prevent cheating</li>
              <li>To facilitate community features and social interactions</li>
            </ul>

            <h3>3.2 Secondary Purposes</h3>
            <ul>
              <li>To improve our services and user experience</li>
              <li>To provide customer support</li>
              <li>To send you updates and promotional materials (with consent)</li>
              <li>To conduct analytics and research</li>
              <li>To comply with legal obligations</li>
            </ul>

            <h2>4. Legal Basis for Processing</h2>
            <p>We process your personal data on the following legal grounds:</p>
            <ul>
              <li>Performance of the contract with you (our Terms of Service)</li>
              <li>Your consent (for optional features and communications)</li>
              <li>Legal obligations (age verification, fraud prevention)</li>
              <li>Legitimate interests (improving our services, security)</li>
            </ul>

            <h2>5. Data Security</h2>
            <p>We implement appropriate technical and organizational measures to protect your data, including:</p>
            <ul>
              <li>Encryption of sensitive data</li>
              <li>Regular security assessments</li>
              <li>Access controls and authentication</li>
              <li>Secure data storage and transmission</li>
              <li>Regular backups and disaster recovery procedures</li>
            </ul>

            <h2>6. Data Retention</h2>
            <p>We retain your personal data for as long as necessary to:</p>
            <ul>
              <li>Provide our services to you</li>
              <li>Comply with legal obligations</li>
              <li>Resolve disputes</li>
              <li>Enforce our agreements</li>
            </ul>

            <h2>7. Your Rights</h2>
            <p>Under applicable data protection laws, you have the following rights:</p>
            <ul>
              <li>Right to access your personal data</li>
              <li>Right to rectification of inaccurate data</li>
              <li>Right to erasure ("right to be forgotten")</li>
              <li>Right to restrict processing</li>
              <li>Right to data portability</li>
              <li>Right to object to processing</li>
              <li>Right to withdraw consent</li>
            </ul>

            <h2>8. Cookies and Tracking</h2>
            <p>We use cookies and similar tracking technologies to enhance your experience:</p>
            <ul>
              <li>Essential cookies for site functionality</li>
              <li>Analytics cookies to improve our service</li>
              <li>Preference cookies to remember your settings</li>
              <li>Marketing cookies (with consent)</li>
            </ul>

            <h2>9. Third-Party Services</h2>
            <p>We may share data with trusted third parties for:</p>
            <ul>
              <li>Payment processing</li>
              <li>Analytics services</li>
              <li>Customer support tools</li>
              <li>Hosting and infrastructure</li>
            </ul>

            <h2>10. International Transfers</h2>
            <p>Your data may be transferred to and processed in countries outside your residence. We ensure appropriate safeguards are in place for such transfers.</p>

            <h2>11. Children's Privacy</h2>
            <p>Our services are not intended for users under 18 years of age. We do not knowingly collect data from children.</p>

            <h2>12. Changes to Privacy Policy</h2>
            <p>We may update this privacy policy from time to time. We will notify you of any significant changes via email or through our platform.</p>

            <h2>13. Contact Information</h2>
            <ContactInfo>
              <p>For any questions about this Privacy Policy, please contact us at:</p>
              <p>Email: privacy@hni.app</p>
              <p>Website: https://hni.app</p>
              
            </ContactInfo>
          </PolicySection>
        </ContentWrapper>
      </PageWrapper>
    </>
  );
};

// Existing styled components remain the same
const PageWrapper = styled.div`
  min-height: 100vh;
  background: #1a1a1a;
  color: white;
  padding: 20px;
`;

const ContentWrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
`;

const Header = styled.div`
  margin-bottom: 30px;
  
  h1 {
    font-size: 2rem;
    margin: 0;
    margin-bottom: 10px;
  }

  p {
    color: #888;
    margin: 0;
  }
`;

const PolicySection = styled.div`
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(5px);
  border-radius: 12px;
  padding: 30px;
  border: 1.5px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);

  h2 {
    color: #2ecc71;
    margin-top: 30px;
    margin-bottom: 15px;
    font-size: 1.5rem;
    &:first-child {
      margin-top: 0;
    }
  }

  h3 {
    color: #fff;
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 1.2rem;
  }

  p {
    line-height: 1.6;
    color: #ddd;
    margin-bottom: 15px;
  }

  ul {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 20px;
    
    li {
      margin-bottom: 10px;
      color: #ddd;
      padding-left: 20px;
      position: relative;
      
      &:before {
        content: "•";
        color: #2ecc71;
        position: absolute;
        left: 0;
      }
    }
  }
`;

const ContactInfo = styled.div`
  background: rgba(255, 255, 255, 0.05);
  padding: 20px;
  border-radius: 8px;
  margin-top: 15px;

  p {
    margin: 5px 0;
    color: #ddd;
  }
`;

export default PrivacyPolicyPage; 