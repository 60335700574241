import ReactGA from 'react-ga4';

const MEASUREMENT_ID = 'G-F7Q36WMXPC'; // Replace with your actual GA4 measurement ID

export const initGA = () => {
  ReactGA.initialize(MEASUREMENT_ID, {
    debug: process.env.NODE_ENV !== 'production',
    gaOptions: {
      siteSpeedSampleRate: 100
    }
  });
};

// Track page views
export const logPageView = () => {
  ReactGA.send({ hitType: "pageview", page: window.location.pathname });
};

// Track events
export const logEvent = (category, action, label) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  });
};

// Track errors
export const logError = (error, errorInfo) => {
  ReactGA.event({
    category: 'Error',
    action: error.toString(),
    label: errorInfo?.componentStack?.toString(),
  });
}; 