import React from 'react';

export function TableTop({ texture }) {
  const baseRadius = 2.5;
  const increasedRadius = baseRadius * 1.5; // 50% increase

  return (
    <mesh receiveShadow position={[0, 0, 0]}>
      <cylinderGeometry args={[increasedRadius, increasedRadius, 0.2, 32]} />
      <meshPhongMaterial 
        map={texture}
        color={0x009900}
        bumpMap={texture}
        bumpScale={0.1}
      />
    </mesh>
  );
} 