import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import StatsModal from './StatsModal';
import config from '../../config';

const InviteIcon = () => (
  <svg 
    width="16" 
    height="16" 
    viewBox="0 0 24 24" 
    fill="none" 
    stroke="currentColor" 
    strokeWidth="2" 
    strokeLinecap="round" 
    strokeLinejoin="round"
  >
    <circle cx="18" cy="5" r="3"/>
    <circle cx="6" cy="12" r="3"/>
    <circle cx="18" cy="19" r="3"/>
    <line x1="8.59" y1="13.51" x2="15.42" y2="17.49"/>
    <line x1="15.41" y1="6.51" x2="8.59" y2="10.49"/>
  </svg>
);

const ChipsIcon = () => (
  <svg width="16" height="16" viewBox="0 0 24 24" fill="currentColor">
    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"/>
  </svg>
);

const BlindIcon = () => (
  <svg width="16" height="16" viewBox="0 0 24 24" fill="currentColor">
    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"/>
    <path d="M12 6c-3.31 0-6 2.69-6 6s2.69 6 6 6 6-2.69 6-6-2.69-6-6-6zm0 10c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4z"/>
    <path d="M12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"/>
  </svg>
);

const TimeIcon = () => (
  <svg width="16" height="16" viewBox="0 0 24 24" fill="currentColor">
    <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm.5-13H11v6l5.25 3.15.75-1.23-4.5-2.67z"/>
  </svg>
);

const CreateIcon = () => (
  <svg width="20" height="20" viewBox="0 0 24 24" fill="currentColor">
    <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/>
  </svg>
);

const EndIcon = () => (
  <svg 
    width="16" 
    height="16" 
    viewBox="0 0 24 24" 
    fill="none" 
    stroke="currentColor" 
    strokeWidth="2" 
    strokeLinecap="round" 
    strokeLinejoin="round"
  >
    <path d="M18 6L6 18M6 6l12 12"/>
  </svg>
);

const StatsButton = styled.button`
  padding: 8px 16px;
  background: rgba(155, 89, 182, 0.2);
  color: #9b59b6;
  border: 1.5px solid rgba(155, 89, 182, 0.3);
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  white-space: nowrap;
  
  @media (max-width: 480px) {
    width: 100%;
    padding: 12px 16px;
  }
  
  &:hover {
    background: rgba(155, 89, 182, 0.3);
    transform: translateY(-1px);
  }
`;

const StatsIcon = () => (
  <svg 
    width="16" 
    height="16" 
    viewBox="0 0 24 24" 
    fill="none" 
    stroke="currentColor" 
    strokeWidth="2" 
    strokeLinecap="round" 
    strokeLinejoin="round"
  >
    <line x1="18" y1="20" x2="18" y2="10"/>
    <line x1="12" y1="20" x2="12" y2="4"/>
    <line x1="6" y1="20" x2="6" y2="14"/>
  </svg>
);

const formatTimeAgo = (dateString) => {
  const date = new Date(dateString);
  const now = new Date();
  const seconds = Math.floor((now - date) / 1000);

  if (seconds < 60) return 'just now';
  const minutes = Math.floor(seconds / 60);
  if (minutes < 60) return `${minutes}m ago`;
  const hours = Math.floor(minutes / 60);
  if (hours < 24) return `${hours}h ago`;
  const days = Math.floor(hours / 24);
  if (days < 30) return `${days}d ago`;
  return date.toLocaleDateString();
};

const formatDateTime = (date) => {
  return new Date(date).toLocaleString('en-US', {
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  });
};

const formatTimeUntil = (scheduledTime) => {
  const now = new Date();
  const scheduled = new Date(scheduledTime);
  const diffMs = scheduled - now;
  
  // Convert to useful units
  const minutes = Math.floor(diffMs / (1000 * 60));
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  
  if (days > 0) {
    return `Starts in ${days}d`;
  } else if (hours > 0) {
    const remainingMinutes = minutes % 60;
    return remainingMinutes > 0 
      ? `Starts in ${hours}h ${remainingMinutes}m`
      : `Starts in ${hours}h`;
  } else if (minutes > 0) {
    return `Starts in ${minutes}m`;
  } else {
    return 'Starting soon';
  }
};

const Card = styled.div`
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(5px);
  border-radius: 12px;
  padding: 20px;
  border: 1.5px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  transition: all 0.2s;
  
  &:hover {
    background: rgba(0, 0, 0, 0.7);
    transform: translateY(-2px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
    border-color: rgba(255, 255, 255, 0.3);
  }
`;

const GameInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const GameHeaderInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const GameName = styled.div`
  font-size: 1.2rem;
  font-weight: 500;
  color: #fff;
  display: flex;
  align-items: center;
  gap: 8px;
  
  svg {
    opacity: 0.7;
  }
`;

const GameStatus = styled.div`
  padding: 6px 12px;
  border-radius: 8px;
  font-size: 0.9rem;
  background: ${props => {
    switch (props.status) {
      case 'SCHEDULED': return 'rgba(241, 196, 15, 0.2)';
      case 'WAITING': return 'rgba(52, 152, 219, 0.2)';
      case 'PLAYING': return 'rgba(46, 204, 113, 0.2)';
      default: return 'rgba(52, 152, 219, 0.2)';
    }
  }};
  color: ${props => {
    switch (props.status) {
      case 'SCHEDULED': return '#f1c40f';
      case 'WAITING': return '#3498db';
      case 'PLAYING': return '#2ecc71';
      default: return '#3498db';
    }
  }};
  border: 1px solid ${props => {
    switch (props.status) {
      case 'SCHEDULED': return 'rgba(241, 196, 15, 0.3)';
      case 'WAITING': return 'rgba(52, 152, 219, 0.3)';
      case 'PLAYING': return 'rgba(46, 204, 113, 0.3)';
      default: return 'rgba(52, 152, 219, 0.3)';
    }
  }};
`;

const GameDetails = styled.div`
  display: flex;
  gap: 15px;
`;

const SettingItem = styled.span`
  font-size: 0.9rem;
  color: #ddd;
  display: flex;
  align-items: center;
  gap: 6px;
  
  svg {
    opacity: 0.7;
  }
`;

const PlayersList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 12px;
`;

const PlayerChip = styled.div`
  background: rgba(255, 255, 255, 0.1);
  padding: 4px 12px;
  border-radius: 20px;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  gap: 4px;
`;

const GameActions = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  margin-top: 15px;
  flex-wrap: wrap;
  
  @media (max-width: 480px) {
    justify-content: stretch;
    
    > button {
      flex: 1;
      min-width: 100%;
    }
  }
`;

const HostBadge = styled.span`
  margin-left: 8px;
  font-size: 0.8em;
  color: #ffd700;
  background: rgba(255, 215, 0, 0.1);
  padding: 4px 8px;
  border-radius: 4px;
  border: 1px solid rgba(255, 215, 0, 0.3);
`;

const HostIndicator = styled.span`
  margin-left: 4px;
  font-size: 0.8em;
`;

const JoinButton = styled.button`
  padding: 8px 16px;
  background: rgba(40, 167, 69, 0.2);
  color: #2ecc71;
  border: 1.5px solid rgba(46, 204, 113, 0.3);
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s;
  white-space: nowrap;
  
  @media (max-width: 480px) {
    width: 100%;
    padding: 12px 16px;
    justify-content: center;
    display: flex;
    align-items: center;
  }
  
  &:hover {
    background: rgba(40, 167, 69, 0.3);
    transform: translateY(-1px);
  }
`;

const ActionButton = styled.button`
  padding: 8px 16px;
  background: ${props => {
    switch (props.$variant) {
      case 'start': return 'rgba(46, 204, 113, 0.2)';
      case 'reschedule': return 'rgba(241, 196, 15, 0.2)';
      case 'end': return 'rgba(231, 76, 60, 0.2)';
      case 'share': return 'rgba(52, 152, 219, 0.2)';
      default: return 'rgba(52, 152, 219, 0.2)';
    }
  }};
  color: ${props => {
    switch (props.$variant) {
      case 'start': return '#2ecc71';
      case 'reschedule': return '#f1c40f';
      case 'end': return '#e74c3c';
      case 'share': return '#3498db';
      default: return '#3498db';
    }
  }};
  border: 1.5px solid ${props => {
    switch (props.$variant) {
      case 'start': return 'rgba(46, 204, 113, 0.3)';
      case 'reschedule': return 'rgba(241, 196, 15, 0.3)';
      case 'end': return 'rgba(231, 76, 60, 0.3)';
      case 'share': return 'rgba(52, 152, 219, 0.3)';
      default: return 'rgba(52, 152, 219, 0.3)';
    }
  }};
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  white-space: nowrap;
  
  @media (max-width: 480px) {
    justify-content: center;
    width: 100%;
    padding: 12px 16px;
  }
  
  &:hover {
    background: ${props => {
      switch (props.$variant) {
        case 'start': return 'rgba(46, 204, 113, 0.3)';
        case 'reschedule': return 'rgba(241, 196, 15, 0.3)';
        case 'end': return 'rgba(231, 76, 60, 0.3)';
        case 'share': return 'rgba(52, 152, 219, 0.3)';
        default: return 'rgba(52, 152, 219, 0.3)';
      }
    }};
    transform: translateY(-1px);
  }
`;

const ScheduledBadge = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 4px 8px;
  background: rgba(241, 196, 15, 0.1);
  border: 1px solid rgba(241, 196, 15, 0.2);
  border-radius: 4px;
  color: #f1c40f;
  font-size: 0.9rem;
`;

const SettingsButton = styled.button`
  padding: 8px;
  background: rgba(255, 255, 255, 0.1);
  border: none;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &:hover {
    background: rgba(255, 255, 255, 0.2);
    transform: translateY(-1px);
  }
`;

const SettingsMenu = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 8px;
  background: #1a2634;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 8px 0;
  min-width: 180px;
  z-index: 100;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
`;

const MenuItem = styled.button`
  width: 100%;
  padding: 8px 16px;
  background: none;
  border: none;
  color: ${props => props.$color || '#fff'};
  text-align: left;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 0.9rem;
  transition: all 0.2s;

  &:hover {
    background: rgba(255, 255, 255, 0.05);
  }

  svg {
    opacity: 0.7;
  }
`;

const SettingsIcon = () => (
  <svg 
    width="16" 
    height="16" 
    viewBox="0 0 24 24" 
    fill="none" 
    stroke="currentColor" 
    strokeWidth="2" 
    strokeLinecap="round" 
    strokeLinejoin="round"
  >
    <circle cx="12" cy="12" r="3"/>
    <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"/>
  </svg>
);

const DateTimeInput = styled.input`
  width: 100%;
  padding: 12px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background: rgba(255, 255, 255, 0.15);
    border-color: rgba(255, 255, 255, 0.3);
  }

  &:focus {
    outline: none;
    border-color: #2ecc71;
    background: rgba(46, 204, 113, 0.1);
  }

  &::-webkit-calendar-picker-indicator {
    filter: invert(1);
    cursor: pointer;
    opacity: 0.6;
    transition: opacity 0.2s;

    &:hover {
      opacity: 1;
    }
  }
`;

const GameCard = ({ 
  game, 
  userId,
  isLoggedIn,
  requireAuth,
  onGameUpdate,
  onInvite,
  isCreateCard = false,
  onCreate,
  onClick,
  customTitle,
  customDescription,
  isCompleted = false 
}) => {
  const navigate = useNavigate();
  const [showStatsModal, setShowStatsModal] = useState(false);
  const [showSettings, setShowSettings] = useState(false);

  useEffect(() => {
    const handleClickOutside = () => setShowSettings(false);
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  const handleGameUpdate = async (action, scheduledTime = null) => {
    try {
      if (!isLoggedIn) {
        throw new Error('User must be logged in');
      }

      const response = await fetch(`${config.apiUrl}/api/games/${game.gameId}/update`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({ action, scheduledTime })
      });

      if (!response.ok) {
        throw new Error('Failed to update game');
      }

      const data = await response.json();
      if (!data.success) {
        throw new Error(data.message);
      }

      if (onGameUpdate) {
        onGameUpdate();
      }

      return data;
    } catch (error) {
      console.error('Error updating game:', error);
      throw error;
    }
  };

  const handleStartGame = async (e) => {
    e.stopPropagation();
    if (window.confirm('Are you sure you want to start this game now?')) {
      try {
        await handleGameUpdate('START');
      } catch (error) {
        alert(error.message);
      }
    }
  };

  const handleEndGame = async (e) => {
    e.stopPropagation();
    if (window.confirm('Are you sure you want to finish this game?')) {
      try {
        const response = await fetch(`${config.apiUrl}/api/games/${game.gameId}/complete`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          credentials: 'include'
        });

        if (!response.ok) {
          throw new Error('Failed to complete game');
        }

        const data = await response.json();
        if (!data.success) {
          throw new Error(data.message);
        }

        if (onGameUpdate) {
          onGameUpdate();
        }
      } catch (error) {
        console.error('Error completing game:', error);
        alert(error.message);
      }
    }
  };

  const handleReschedule = async (e) => {
    e.stopPropagation();
    try {
      // Create a container for the modal
      const container = document.createElement('div');
      container.style.cssText = `
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #2c2c2c;
        padding: 20px;
        border-radius: 12px;
        z-index: 9999;
        width: 300px;
      `;

      // Create title
      const title = document.createElement('h3');
      title.textContent = 'Reschedule Game';
      title.style.cssText = `
        margin: 0 0 15px 0;
        color: white;
        font-size: 18px;
      `;

      // Create the datetime input
      const input = document.createElement('input');
      input.type = 'datetime-local';
      
      // Set min time (5 minutes from now)
      const minTime = new Date(Date.now() + 5 * 60000);
      input.min = minTime.toISOString().slice(0, 16);
      
      // Set max time (30 days from now)
      const maxTime = new Date(Date.now() + 30 * 24 * 60 * 60 * 1000);
      input.max = maxTime.toISOString().slice(0, 16);

      // Set current scheduled time as default if it exists
      if (game.scheduledTime) {
        const currentScheduled = new Date(game.scheduledTime);
        if (currentScheduled > minTime) {
          input.value = currentScheduled.toISOString().slice(0, 16);
        }
      }

      // Style the input
      input.style.cssText = `
        width: 100%;
        padding: 12px;
        background: rgba(255, 255, 255, 0.1);
        border: 1px solid rgba(255, 255, 255, 0.2);
        border-radius: 8px;
        color: white;
        font-size: 16px;
        margin-bottom: 15px;
      `;

      // Create buttons container
      const buttonContainer = document.createElement('div');
      buttonContainer.style.cssText = `
        display: flex;
        gap: 10px;
        justify-content: flex-end;
      `;

      // Create cancel button
      const cancelButton = document.createElement('button');
      cancelButton.textContent = 'Cancel';
      cancelButton.style.cssText = `
        padding: 8px 16px;
        border-radius: 6px;
        border: 1px solid rgba(255, 255, 255, 0.2);
        background: transparent;
        color: white;
        cursor: pointer;
        font-size: 14px;
        transition: all 0.2s;
      `;

      // Create confirm button
      const confirmButton = document.createElement('button');
      confirmButton.textContent = 'Confirm';
      confirmButton.style.cssText = `
        padding: 8px 16px;
        border-radius: 6px;
        border: none;
        background: #2ecc71;
        color: white;
        cursor: pointer;
        font-size: 14px;
        transition: all 0.2s;
      `;

      // Create overlay
      const overlay = document.createElement('div');
      overlay.style.cssText = `
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.7);
        z-index: 9998;
      `;

      // Add elements to container
      container.appendChild(title);
      container.appendChild(input);
      buttonContainer.appendChild(cancelButton);
      buttonContainer.appendChild(confirmButton);
      container.appendChild(buttonContainer);

      // Add elements to DOM
      document.body.appendChild(overlay);
      document.body.appendChild(container);

      // Cleanup function
      const cleanup = () => {
        document.body.removeChild(container);
        document.body.removeChild(overlay);
      };

      // Handle confirm
      const handleConfirm = async () => {
        const newScheduledTime = new Date(input.value);
        
        if (!input.value) {
          alert('Please select a time');
          return;
        }

        if (newScheduledTime < minTime) {
          alert('Please select a time at least 5 minutes in the future');
          return;
        }

        cleanup();

        try {
          const response = await fetch(`${config.apiUrl}/api/games/${game.gameId}/update`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({
              action: 'RESCHEDULE',
              scheduledTime: newScheduledTime.toISOString()
            })
          });

          if (!response.ok) {
            throw new Error('Failed to reschedule game');
          }

          const data = await response.json();
          if (!data.success) {
            throw new Error(data.message);
          }

          if (onGameUpdate) {
            onGameUpdate();
          }
        } catch (error) {
          console.error('Error rescheduling game:', error);
          alert(error.message);
        }
      };

      // Add event listeners
      cancelButton.addEventListener('click', cleanup);
      confirmButton.addEventListener('click', handleConfirm);
      overlay.addEventListener('click', cleanup);

      // Focus the input
      input.focus();

    } catch (error) {
      console.error('Error showing date picker:', error);
      alert(error.message);
    }
  };

  const handleShare = async (e) => {
    e.stopPropagation();
    
    const shareUrl = `${window.location.origin}/table/${game.gameId}`;
    const shareData = {
      title: `Poker Table #${game.gameId.substring(0, 6)}`,
      text: 'Join my poker game!',
      url: shareUrl
    };

    try {
      // Try native sharing first
      if (navigator.share) {
        await navigator.share(shareData);
      } else {
        // Fallback to clipboard
        await navigator.clipboard.writeText(shareUrl);
        alert('Link copied to clipboard!');
      }
    } catch (error) {
      console.error('Error sharing:', error);
      // Final fallback
      try {
        const textArea = document.createElement('textarea');
        textArea.value = shareUrl;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
        alert('Link copied to clipboard!');
      } catch (fallbackError) {
        console.error('Fallback error:', fallbackError);
        alert('Failed to share or copy link');
      }
    }
  };

  if (isCreateCard) {
    return (
      <Card onClick={onClick || onCreate}>
        <GameInfo>
          <GameHeaderInfo>
            <GameName>
              <CreateIcon />
              {customTitle || 'Create New Game'}
            </GameName>
          </GameHeaderInfo>
          <GameDetails>
            <SettingItem>
              {customDescription || 'Start a new poker table'}
            </SettingItem>
          </GameDetails>
        </GameInfo>
        {!onClick && (
          <GameActions>
            <JoinButton onClick={onCreate}>
              <CreateIcon />
              Create Game
            </JoinButton>
          </GameActions>
        )}
      </Card>
    );
  }

  if (!game) return null;

  const isCommunity = !game.gameId && game._id;
  
  const handleJoinClick = () => {
    // Don't allow clicking if game is scheduled
    if (game.status === 'SCHEDULED') {
      return;
    }

    if (onClick) {
      onClick();
      return;
    }

    if (!isLoggedIn) {
      localStorage.setItem('redirectAfterLogin', `/table/${game.gameId}`);
      requireAuth();
      return;
    }
    navigate(`/table/${game.gameId}`);
  };

  const handleInviteClick = () => {
    if (!isLoggedIn) {
      requireAuth();
      return;
    }
    onInvite(game.gameId, `Table #${game.gameId}`);
  };

  const isUserInGame = isLoggedIn && game.players?.some(player => player.userId === userId);

  return (
    <>
      <Card 
        onClick={!isCompleted && game.status !== 'SCHEDULED' ? handleJoinClick : undefined}
        style={{ cursor: game.status === 'SCHEDULED' ? 'default' : 'pointer' }}
      >
        <GameInfo>
          <GameHeaderInfo>
            <GameName>
              Table #{game.gameId.substring(0, 6)}
              {isLoggedIn && game.hostId === userId && !isCompleted && (
                <HostBadge>👑 Host</HostBadge>
              )}
            </GameName>
            <GameStatus status={game.status}>
              {game.status}
            </GameStatus>
          </GameHeaderInfo>

          <GameDetails>
            {isCompleted ? (
              <>
                <SettingItem>
                  <TimeIcon />
                  Started: {formatDateTime(game.createdAt)}
                </SettingItem>
                <SettingItem>
                  <TimeIcon />
                  Ended: {formatDateTime(game.endTime)}
                </SettingItem>
              </>
            ) : (
              <>
                <SettingItem>
                  <ChipsIcon />
                  Buy In: {game.settings.buyIn}
                </SettingItem>
                <SettingItem>
                  <BlindIcon />
                  Blinds: {game.settings.smallBlind}/{game.settings.bigBlind}
                </SettingItem>
                {game.status === 'SCHEDULED' ? (
                  <SettingItem>
                    <TimeIcon />
                    Starts: {formatDateTime(game.scheduledTime)}
                  </SettingItem>
                ) : (
                  <SettingItem>
                    <TimeIcon />
                    {formatTimeAgo(game.createdAt)}
                  </SettingItem>
                )}
              </>
            )}
          </GameDetails>

          {game.status === 'SCHEDULED' && (
            <ScheduledBadge>
              <TimeIcon />
              {formatTimeUntil(game.scheduledTime)}
            </ScheduledBadge>
          )}

          {!isCompleted && (
            <PlayersList>
              {game.players.map(player => (
                <PlayerChip key={player.userId}>
                  {player.username}
                  {player.userId === game.hostId && <HostIndicator>👑</HostIndicator>}
                </PlayerChip>
              ))}
            </PlayersList>
          )}
        </GameInfo>

        <GameActions>
          {isCompleted ? (
            <StatsButton onClick={(e) => {
              e.stopPropagation();
              setShowStatsModal(true);
            }}>
              <StatsIcon />
              View Stats
            </StatsButton>
          ) : (
            <>
              {isLoggedIn && game.hostId === userId && (
                <>
                  <ActionButton 
                    $variant="share" 
                    onClick={handleShare}
                  >
                    <InviteIcon />
                    Share
                  </ActionButton>

                  {game.status === 'SCHEDULED' && (
                    <>
                      <ActionButton 
                        $variant="start" 
                        onClick={(e) => {
                          e.stopPropagation();
                          handleStartGame(e);
                        }}
                      >
                        <TimeIcon />
                        Start Now
                      </ActionButton>
                      <ActionButton 
                        $variant="reschedule" 
                        onClick={(e) => {
                          e.stopPropagation();
                          handleReschedule(e);
                        }}
                      >
                        <TimeIcon />
                        Reschedule
                      </ActionButton>
                    </>
                  )}

                  {(game.status === 'PLAYING' || game.status === 'WAITING') && (
                    <ActionButton 
                      $variant="end" 
                      onClick={(e) => {
                        e.stopPropagation();
                        handleEndGame(e);
                      }}
                    >
                      <EndIcon />
                      Finish
                    </ActionButton>
                  )}
                </>
              )}
              {game.status !== 'SCHEDULED' && (
                <JoinButton>
                  {!isLoggedIn ? 'Join Game' : isUserInGame ? 'Return to Game' : 'Join Game'}
                </JoinButton>
              )}
            </>
          )}
        </GameActions>
      </Card>

      {isCompleted && (
        <StatsModal
          isOpen={showStatsModal}
          onClose={() => setShowStatsModal(false)}
          game={game}
        />
      )}
    </>
  );
};

export default GameCard; 