import React from 'react';
import Footer from './Footer';
import styled from 'styled-components';
import { useAuth } from '../contexts/AuthContext';

const Main = styled.main`
  padding-bottom: ${props => props.$isAuthenticated ? '4rem' : '0'};
`;

const Layout = ({ children, showFooter = true }) => {
  const { user } = useAuth();
  const isAuthenticated = !!user;

  return (
    <>
      <Main $isAuthenticated={isAuthenticated}>
        {children}
      </Main>
      {isAuthenticated && <Footer visible={showFooter} />}
    </>
  );
};

export default Layout; 