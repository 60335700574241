import React, { useState } from 'react';
import styled from 'styled-components';
import { useAuth } from '../contexts/AuthContext';
import Layout from '../components/Layout';
import config from '../config';
import { FiEdit2 } from 'react-icons/fi';
import { BiChip } from 'react-icons/bi';

const ProfilePage = () => {
  const { user, logout, updateUser } = useAuth();
  
  const [isEditing, setIsEditing] = useState(false);
  const [username, setUsername] = useState(user?.username || '');
  const [updateError, setUpdateError] = useState('');
  const [profileImage, setProfileImage] = useState(user?.profileImage || '');
  

  const handleUpdateProfile = async (e) => {
    e.preventDefault();
    setUpdateError('');

    try {
      const response = await fetch(`${config.apiUrl}/api/users/update`, {
        method: 'PUT',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username }),
      });

      const data = await response.json();

      if (data.success) {
        updateUser({ ...user, username });
        setIsEditing(false);
      } else {
        setUpdateError(data.message || 'Failed to update profile');
      }
    } catch (error) {
      setUpdateError('An error occurred while updating profile');
    }
  };

  const handleLogout = async () => {
    try {
      await logout();
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const renderAvatar = () => {
    return profileImage ? (
      <AvatarImage 
        src={profileImage} 
        alt={user?.username}
        onError={(e) => {
          console.error('Image failed to load:', e);
          e.target.onerror = null;
          setProfileImage('');
        }}
      />
    ) : (
      user?.username?.charAt(0).toUpperCase() || 'U'
    );
  };

  return (
    <Layout showFooter={true}>
      <PageContainer>
        <ProfileHeader>
          <UserAvatar>
            {renderAvatar()}
          </UserAvatar>
          {isEditing ? (
            <EditForm onSubmit={handleUpdateProfile}>
              <EditInput
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Enter new username"
              />
              <ButtonGroup>
                <SaveButton type="submit">Save</SaveButton>
                <CancelButton type="button" onClick={() => setIsEditing(false)}>
                  Cancel
                </CancelButton>
              </ButtonGroup>
              {updateError && <ErrorMessage>{updateError}</ErrorMessage>}
            </EditForm>
          ) : (
            <UserInfo>
              <Username>
                {user?.username}
                <EditButton onClick={() => setIsEditing(true)}>
                  <FiEdit2 size={16} />
                </EditButton>
              </Username>
              <UserEmail>{user?.email}</UserEmail>
            </UserInfo>
          )}
        </ProfileHeader>

        <Section>
          <SectionTitle>Balance</SectionTitle>
          <BalanceCard>
            <BalanceIcon>
              <BiChip size={24} />
            </BalanceIcon>
            <BalanceInfo>
              <BalanceLabel>Current Balance</BalanceLabel>
              <BalanceAmount>{user?.balance?.toLocaleString()} chips</BalanceAmount>
            </BalanceInfo>
          </BalanceCard>
        </Section>

        <LogoutButton onClick={handleLogout}>
          Logout
        </LogoutButton>
      </PageContainer>
    </Layout>
  );
};

const PageContainer = styled.div`
  min-height: 100vh;
  background: #1a1a1a;
  color: white;
  padding: 20px;
`;

const ProfileHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 32px;
`;

const UserAvatar = styled.div`
  width: 80px;
  height: 80px;
  background: #2ecc71;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  font-weight: bold;
  color: white;
  overflow: hidden;
`;

const AvatarImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const UserInfo = styled.div`
  flex: 1;
`;

const Username = styled.h1`
  font-size: 1.8rem;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 12px;
`;

const UserEmail = styled.p`
  color: #888;
  margin: 4px 0 0 0;
`;

const EditButton = styled.button`
  background: none;
  border: none;
  color: #2ecc71;
  cursor: pointer;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &:hover {
    color: #27ae60;
  }
`;

const EditForm = styled.form`
  flex: 1;
`;

const EditInput = styled.input`
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  padding: 8px 16px;
  color: white;
  font-size: 1rem;
  width: 100%;
  max-width: 300px;
  
  &:focus {
    outline: none;
    border-color: #2ecc71;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 8px;
`;

const SaveButton = styled.button`
  padding: 8px 16px;
  background: #2ecc71;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  
  &:hover {
    background: #27ae60;
  }
`;

const CancelButton = styled.button`
  padding: 8px 16px;
  background: transparent;
  color: #888;
  border: 1px solid #888;
  border-radius: 8px;
  cursor: pointer;
  
  &:hover {
    color: white;
    border-color: white;
  }
`;

const Section = styled.section`
  margin-bottom: 32px;
`;

const SectionTitle = styled.h2`
  font-size: 1.4rem;
  margin: 0 0 16px 0;
  color: #fff;
`;

const BalanceCard = styled.div`
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(5px);
  border-radius: 12px;
  padding: 20px;
  border: 1.5px solid rgba(46, 204, 113, 0.3);
  display: flex;
  align-items: center;
  gap: 16px;
`;

const BalanceIcon = styled.div`
  color: #2ecc71;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BalanceInfo = styled.div`
  flex: 1;
`;

const BalanceLabel = styled.div`
  color: #888;
  font-size: 0.9rem;
  margin-bottom: 4px;
`;

const BalanceAmount = styled.div`
  font-size: 1.4rem;
  font-weight: bold;
  color: #2ecc71;
`;

const LogoutButton = styled.button`
  width: 100%;
  padding: 12px;
  background: rgba(231, 76, 60, 0.2);
  color: #e74c3c;
  border: 1.5px solid rgba(231, 76, 60, 0.3);
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.2s;
  
  &:hover {
    background: rgba(231, 76, 60, 0.3);
  }
`;

const ErrorMessage = styled.div`
  color: #e74c3c;
  margin-top: 8px;
  font-size: 0.9rem;
`;

export default ProfilePage; 