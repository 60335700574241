import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Modal } from '../ui/Modal';
import config from '../../config';

const BuyinHistory = ({ gameId, onClose, isHost }) => {
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchBuyinHistory = async () => {
    try {
      const response = await fetch(`${config.apiUrl}/api/games/${gameId}/buyins/history`, {
        credentials: 'include'
      });
      
      if (!response.ok) {
        throw new Error('Failed to fetch buy-in history');
      }

      const data = await response.json();
      
      if (!data || !Array.isArray(data.history)) {
        console.error('Invalid data format received:', data);
        setHistory([]);
        return;
      }

      setHistory(data.history);
    } catch (err) {
      console.error('Error fetching buyin history:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBuyinHistory();
  }, [gameId]);

  const handleApproveBuyin = async (buyinId, userId) => {
    try {
      console.log('Approving buyin:', { buyinId, userId, gameId });

      const response = await fetch(`${config.apiUrl}/api/games/${gameId}/buyins/${buyinId}/approve`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          gameId,
          userId: userId,
          requestingUserId: userId
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to approve buy-in');
      }

      // Refresh the history
      fetchBuyinHistory();
    } catch (err) {
      console.error('Error approving buyin:', err);
      setError(err.message);
    }
  };

  const handleRejectBuyin = async (buyinId, userId) => {
    try {
      const response = await fetch(`${config.apiUrl}/api/games/${gameId}/buyins/${buyinId}/reject`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          gameId,
          userId,
          transactionId: buyinId
        })
      });

      if (!response.ok) {
        throw new Error('Failed to reject buy-in');
      }

      // Refresh the history
      fetchBuyinHistory();
    } catch (err) {
      console.error('Error rejecting buyin:', err);
      setError(err.message);
    }
  };

  return (
    <Modal
      isOpen={true}
      onClose={onClose}
      title="Buy-in History"
    >
      <HistoryContainer>
        {loading ? (
          <LoadingText>Loading history...</LoadingText>
        ) : error ? (
          <ErrorText>{error}</ErrorText>
        ) : history.length === 0 ? (
          <NoHistoryText>No buy-in history available</NoHistoryText>
        ) : (
          <HistoryList>
            {history.map((entry) => (
              <HistoryItem key={entry.buyinId} status={entry.status?.toLowerCase()}>
                <UserInfo>
                  <Username>
                    {entry.username}
                    {entry.isFirstBuyin && <FirstBuyinBadge>First Buy-in</FirstBuyinBadge>}
                  </Username>
                  <Amount>{entry.amount?.toLocaleString() || 0} chips</Amount>
                </UserInfo>
                <Details>
                  <Status status={entry.status?.toLowerCase()}>
                    {(entry.status || 'PENDING').toUpperCase()}
                  </Status>
                  <Time>
                    {new Date(entry.timestamp).toLocaleString()}
                    {entry.approvedBy && entry.approvedAt && 
                      ` • Approved by ${entry.approvedBy} ${new Date(entry.approvedAt).toLocaleString()}`}
                  </Time>
                </Details>
                
                {/* Add approval buttons for host */}
                {isHost && entry.status?.toLowerCase() === 'pending' && (
                  <ActionButtons>
                    <ApproveButton
                      onClick={() => handleApproveBuyin(entry.buyinId, entry.requestingUserId)}
                    >
                      Approve
                    </ApproveButton>
                    <RejectButton
                      onClick={() => handleRejectBuyin(entry.buyinId, entry.requestingUserId)}
                    >
                      Reject
                    </RejectButton>
                  </ActionButtons>
                )}
              </HistoryItem>
            ))}
          </HistoryList>
        )}
      </HistoryContainer>
    </Modal>
  );
};

const HistoryContainer = styled.div`
  padding: 20px;
  max-height: 400px;
  overflow-y: auto;
  background: rgba(0, 0, 0, 0.85);
  backdrop-filter: blur(10px);
  border-radius: 12px;
`;

const HistoryList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const HistoryItem = styled.div`
  padding: 15px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  border: 2px solid ${props => {
    switch (props.status) {
      case 'approved': return 'rgba(46, 204, 113, 0.3)';
      case 'pending': return 'rgba(241, 196, 15, 0.3)';
      case 'rejected': return 'rgba(231, 76, 60, 0.3)';
      default: return 'rgba(255, 255, 255, 0.2)';
    }
  }};
`;

const UserInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

const Username = styled.span`
  color: white;
  font-weight: bold;
`;

const Amount = styled.span`
  color: #2ecc71;
  font-weight: bold;
`;

const Details = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Status = styled.span`
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
  ${props => {
    switch (props.status) {
      case 'approved':
        return `
          background: rgba(46, 204, 113, 0.2);
          color: #2ecc71;
        `;
      case 'pending':
        return `
          background: rgba(241, 196, 15, 0.2);
          color: #f1c40f;
        `;
      case 'rejected':
        return `
          background: rgba(231, 76, 60, 0.2);
          color: #e74c3c;
        `;
      default:
        return `
          background: rgba(255, 255, 255, 0.1);
          color: white;
        `;
    }
  }}
`;

const Time = styled.span`
  color: rgba(255, 255, 255, 0.6);
  font-size: 12px;
`;

const LoadingText = styled.div`
  color: white;
  text-align: center;
  padding: 20px;
`;

const ErrorText = styled.div`
  color: #e74c3c;
  text-align: center;
  padding: 20px;
`;

const NoHistoryText = styled.div`
  color: rgba(255, 255, 255, 0.6);
  text-align: center;
  padding: 20px;
`;

const FirstBuyinBadge = styled.span`
  margin-left: 8px;
  padding: 2px 6px;
  font-size: 11px;
  background: rgba(52, 152, 219, 0.2);
  color: #3498db;
  border-radius: 4px;
  font-weight: bold;
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 10px;
  justify-content: flex-end;
`;

const BaseButton = styled.button`
  padding: 6px 12px;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    transform: translateY(-1px);
  }

  &:active {
    transform: translateY(1px);
  }
`;

const ApproveButton = styled(BaseButton)`
  background: rgba(46, 204, 113, 0.2);
  color: #2ecc71;
  border: 2px solid rgba(46, 204, 113, 0.3);
`;

const RejectButton = styled(BaseButton)`
  background: rgba(231, 76, 60, 0.2);
  color: #e74c3c;
  border: 2px solid rgba(231, 76, 60, 0.3);
`;

export default BuyinHistory; 