import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const ButtonContainer = styled.div`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  gap: 12px;
  background: rgba(26, 26, 26, 0.95);
  backdrop-filter: blur(10px);
  padding: 20px;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  z-index: 2000;
  max-width: 95vw;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  @media (max-width: 768px) {
    padding: 15px;
    gap: 10px;
    bottom: 15px;
  }
`;

const Button = styled.button`
  padding: 12px 24px;
  background: rgba(52, 152, 219, 0.2);
  color: #3498db;
  border: 1.5px solid rgba(52, 152, 219, 0.3);
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s;
  backdrop-filter: blur(5px);
  font-size: 18px;
  font-weight: 600;
  min-width: 120px;
  height: 56px;

  &:hover {
    background: rgba(52, 152, 219, 0.3);
    transform: translateY(-1px);
  }

  &:active {
    transform: translateY(1px);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    transform: none;
  }

  ${props => props.variant === 'primary' && `
    background: rgba(46, 204, 113, 0.2);
    color: #2ecc71;
    border: 1.5px solid rgba(46, 204, 113, 0.3);

    &:hover {
      background: rgba(46, 204, 113, 0.3);
    }
  `}

  @media (max-width: 768px) {
    padding: 10px 20px;
    font-size: 16px;
    min-width: 100px;
    height: 48px;
  }

  @media (hover: none) {
    &:hover {
      transform: none;
    }
  }
`;

const TimerButton = styled(Button)`
  background: rgba(231, 76, 60, 0.2);
  color: #e74c3c;
  border: 1.5px solid rgba(231, 76, 60, 0.3);
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: ${props => 100 - props.progress}%;
    height: 100%;
    background: rgba(231, 76, 60, 0.3);
    transition: width 0.1s linear;
    z-index: 0;
  }

  span {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  &:hover {
    background: rgba(231, 76, 60, 0.3);
  }

  ${props => props.progress < 30 && `
    animation: pulse 1s infinite;
    @keyframes pulse {
      0% { transform: scale(1); }
      50% { transform: scale(1.05); }
      100% { transform: scale(1); }
    }
  `}
`;

const RaiseControls = styled.div`
  position: fixed;
  right: 20px;
  bottom: 100px;
  background: rgba(0, 0, 0, 0.85);
  padding: 15px;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  backdrop-filter: blur(5px);
  z-index: 3000;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.4);

  @media (max-width: 768px) {
    right: 10px;
    bottom: 90px;
  }
`;

const VerticalRangeContainer = styled.div`
  height: 200px;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 10px 0;
`;

const VerticalRangeSlider = styled.input`
  writing-mode: bt-lr;
  -webkit-appearance: slider-vertical;
  width: 20px;
  height: 200px;
  padding: 0 10px;
  margin: 0 10px;
  
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 28px;
    height: 28px;
    background: #fff;
    border-radius: 50%;
    cursor: pointer;
    border: 2px solid #3498db;
    box-shadow: 0 2px 6px rgba(0,0,0,0.2);
    margin-left: -10px;
  }
  
  &::-webkit-slider-runnable-track {
    width: 20px;
    cursor: pointer;
    background: rgba(52, 152, 219, 0.2);
    border-radius: 10px;
    border: 1px solid rgba(52, 152, 219, 0.5);
  }

  &::-moz-range-thumb {
    width: 28px;
    height: 28px;
    background: #fff;
    border-radius: 50%;
    cursor: pointer;
    border: 2px solid #3498db;
    box-shadow: 0 2px 6px rgba(0,0,0,0.2);
  }

  &::-moz-range-track {
    width: 20px;
    cursor: pointer;
    background: rgba(52, 152, 219, 0.2);
    border-radius: 10px;
    border: 1px solid rgba(52, 152, 219, 0.5);
  }

  @media (pointer: coarse) {
    width: 40px;
    
    &::-webkit-slider-thumb {
      width: 36px;
      height: 36px;
    }
    
    &::-moz-range-thumb {
      width: 36px;
      height: 36px;
    }
  }
`;

const RaisePresets = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const PresetButton = styled.button`
  padding: 8px 12px;
  background: rgba(52, 152, 219, 0.2);
  color: #3498db;
  border: 1px solid #3498db;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;
  width: 80px;
  
  &:hover {
    background: rgba(52, 152, 219, 0.4);
  }
  
  &.selected {
    background: #3498db;
    color: white;
  }
`;

const RaiseAmount = styled.div`
  color: white;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
`;

const ActionButtons = ({ 
  isActive, 
  onAction, 
  currentBet, 
  playerBet, 
  playerChips, 
  isAllIn,
  gameState,
  potSize
}) => {
  const [showRaiseControls, setShowRaiseControls] = useState(false);
  const [raiseAmount, setRaiseAmount] = useState(currentBet * 2);
  const [progress, setProgress] = useState(100);
  
  const TURN_DURATION = 60;
  const minRaise = Math.max(currentBet * 2, currentBet + 20);
  const maxRaise = playerChips;

  useEffect(() => {
    if (!isActive) {
      setProgress(100);
      return;
    }

    setProgress(100);
    const startTime = gameState?.timer?.startTime || Date.now();
    const duration = gameState?.timer?.duration || 60000;

    const interval = setInterval(() => {
      const elapsed = Date.now() - startTime;
      const remaining = Math.max(0, duration - elapsed);
      const progressPercent = (remaining / duration) * 100;
      setProgress(progressPercent);

      if (remaining === 0) {
        clearInterval(interval);
      }
    }, 100);

    return () => clearInterval(interval);
  }, [isActive, gameState?.timer]);

  const callAmount = currentBet - playerBet;
  const canCheck = currentBet === playerBet;
  const isBetGreaterThanChips = callAmount > playerChips;
  const canRaise = playerChips >= minRaise && !isBetGreaterThanChips;

  const handleAction = (actionType, amount = 0) => {
    const actionPayload = {
      action: actionType,
      amount: Number(amount)
    };
    onAction(actionPayload);
  };

  const handlePresetMultiplier = (multiplier) => {
    if (multiplier === 'all') {
      setRaiseAmount(playerChips);
    } else {
      const potBasedAmount = Math.min(potSize * multiplier, playerChips);
      setRaiseAmount(Math.max(minRaise, potBasedAmount));
    }
  };

  if (isBetGreaterThanChips) {
    return (
      <ButtonContainer>
        <TimerButton 
          onClick={() => handleAction('FOLD', 0)}
          progress={progress}
          disabled={!isActive}
        >
          <span>
            Fold
            <svg width="16" height="16" viewBox="0 0 24 24" fill="currentColor">
              <path d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0zM8 7v10l9-5-9-5z"/>
            </svg>
            {Math.ceil(progress / 100 * TURN_DURATION)}s
          </span>
        </TimerButton>
        <Button onClick={() => handleAction('CALL', playerChips)} variant="primary">
          Call {playerChips}
        </Button>
        
      </ButtonContainer>
    );
  }

  return (
    <>
      <ButtonContainer>
        <TimerButton 
          onClick={() => handleAction('FOLD', 0)}
          progress={progress}
          disabled={!isActive}
        >
          <span>
            Fold
            <svg width="16" height="16" viewBox="0 0 24 24" fill="currentColor">
              <path d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0zM8 7v10l9-5-9-5z"/>
            </svg>
            {Math.ceil(progress / 100 * TURN_DURATION)}s
          </span>
        </TimerButton>
        
        {canCheck ? (
          <Button onClick={() => handleAction('CHECK', 0)}>Check</Button>
        ) : (
          <Button onClick={() => handleAction('CALL', callAmount)}>
            Call {callAmount}
          </Button>
        )}
        
        {canRaise && (
          <Button 
            onClick={() => setShowRaiseControls(!showRaiseControls)}
            variant={showRaiseControls ? "primary" : "default"}
          >
            Raise
          </Button>
        )}
      </ButtonContainer>

      {showRaiseControls && canRaise && (
        <RaiseControls>
          <RaiseAmount>Raise to: {raiseAmount}</RaiseAmount>
          
          <VerticalRangeContainer>
            <VerticalRangeSlider
              type="range"
              min={minRaise}
              max={maxRaise}
              value={raiseAmount}
              onChange={(e) => setRaiseAmount(Number(e.target.value))}
            />
            
            <RaisePresets>
              <PresetButton 
                onClick={() => handlePresetMultiplier(0.5)}
                className={raiseAmount === potSize * 0.5 ? 'selected' : ''}
              >
                ½ Pot
              </PresetButton>
              <PresetButton 
                onClick={() => handlePresetMultiplier(1)}
                className={raiseAmount === potSize ? 'selected' : ''}
              >
                Pot
              </PresetButton>
              <PresetButton 
                onClick={() => handlePresetMultiplier(2)}
                className={raiseAmount === potSize * 2 ? 'selected' : ''}
              >
                2x Pot
              </PresetButton>
              <PresetButton 
                onClick={() => handlePresetMultiplier(3)}
                className={raiseAmount === potSize * 3 ? 'selected' : ''}
              >
                3x Pot
              </PresetButton>
              <PresetButton 
                onClick={() => handlePresetMultiplier('all')}
                className={raiseAmount === playerChips ? 'selected' : ''}
              >
                All In
              </PresetButton>
            </RaisePresets>
          </VerticalRangeContainer>

          <Button 
            variant="primary"
            onClick={() => {
              handleAction('RAISE', raiseAmount);
              setShowRaiseControls(false);
            }}
            disabled={raiseAmount < minRaise || raiseAmount > maxRaise}
          >
            Confirm {raiseAmount}
          </Button>
        </RaiseControls>
      )}
    </>
  );
};

export default ActionButtons; 