import React from 'react';

export function CardPositions() {
  // Community card positions
  const communityPositions = Array(5).fill().map((_, i) => {
    const totalWidth = 3.5;
    const startX = -totalWidth / 2;
    const spacing = 0.8;
    return {
      position: [startX + (i * spacing), 0.11, 0],
      rotation: [-Math.PI / 2, 0, 0]
    };
  });

  return (
    <group>
      {/* Community card positions */}
      {communityPositions.map((pos, index) => (
        <mesh
          key={`community-${index}`}
          position={pos.position}
          rotation={pos.rotation}
        >
          <planeGeometry args={[0.7, 1]} />
          <meshBasicMaterial 
            color={0x00ff00}
            transparent
            opacity={0.1}
            wireframe={true}
          />
        </mesh>
      ))}
    </group>
  );
} 