import React, { useState, useEffect } from 'react';
import styled, { keyframes, css } from 'styled-components';
import Card from '../Card/Card';
import Chip from '../Chip/Chip';
import HandEvaluator from '../../utils/HandEvaluator';

const ChipIcon = styled.svg`
  width: 14px;
  height: 14px;
  margin-right: 4px;
  vertical-align: middle;
  fill: currentColor;
`;

const SeatWrapper = styled.div`
  background: linear-gradient(to bottom, #2c2c2c, #1a1a1a);
  border-radius: ${props => `clamp(4px, 1vw, 6px)`};
  padding: ${props => props.isCurrentPlayer ? 
    `clamp(6px, 1.5vw, 8px)` : 
    `clamp(4px, 1vw, 6px)`};
  width: ${props => props.isCurrentPlayer ? 
    `clamp(120px, 15%, 160px)` : 
    `clamp(100px, 12%, 140px)`};
  color: white;
  text-align: center;
  position: absolute;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  z-index: ${props => props.isCurrentPlayer ? 20 : 10};
  border: ${props => !props.isActive && '1px solid rgba(255,255,255,0.15)'};
  
  &::before {
    content: '';
    position: absolute;
    inset: -2px;
    border: ${props => props.isActive ? 
      (props.isCurrentPlayer ? 
        `3px solid ${props.timeLeft <= 50 ? '#ff4444' : '#0096ff'}` : 
        `2px solid ${props.timeLeft <= 50 ? '#ff4444' : '#0096ff'}`
      ) : 
      'none'
    };
    border-radius: inherit;
    clip-path: ${props => props.isActive ? `polygon(
      0 0,
      100% 0,
      100% ${props.timeLeft}%,
      0 ${props.timeLeft}%
    )` : 'none'};
    transition: clip-path 0.1s linear;
  }

  ${({ position, isCurrentPlayer }) => {
    const getPositions = (isMobile) => ({
      0: { 
        bottom: '5%', 
        left: '50%', 
        transform: 'translateX(-50%)' 
      },
      1: { 
        bottom: '25%', 
        left: '2%'
      },
      2: { 
        top: '25%', 
        left: '2%'
      },
      3: { 
        top: '5%', 
        left: '50%', 
        transform: 'translateX(-50%)' 
      },
      4: { 
        top: '25%', 
        right: '2%'
      },
      5: { 
        bottom: '25%', 
        right: '2%'
      }
    });

    const positions = getPositions(false);
    const mobilePositions = getPositions(true);

    return `
      ${Object.entries(positions[position] || {})
        .map(([key, value]) => `${key}: ${value};`)
        .join('\n')}

      @media (max-width: 768px) {
        width: ${isCurrentPlayer ? 
          `clamp(100px, 20%, 120px)` : // Mobile size relative to table
          `clamp(80px, 18%, 100px)`};  // Mobile size relative to table
        ${Object.entries(mobilePositions[position] || {})
          .map(([key, value]) => `${key}: ${value};`)
          .join('\n')}
      }
    `;
  }}
`;

const PlayerInfo = styled.div`
  .player-name {
    color: #ffd700;
    font-size: ${props => props.isCurrentPlayer ? 
      `clamp(12px, 2.2vw, 16px)` : 
      `clamp(10px, 2vw, 14px)`};
    font-weight: bold;
    margin-bottom: ${props => `clamp(2px, 0.5vw, 3px)`};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .stack {
    color: #00ff00;
    font-size: ${props => props.isCurrentPlayer ? 
      `clamp(11px, 2vw, 14px)` : 
      `clamp(9px, 1.8vw, 12px)`};
    margin-bottom: ${props => `clamp(2px, 0.8vw, 4px)`};
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .status {
    font-size: ${props => props.isCurrentPlayer ? 
      `clamp(10px, 1.8vw, 13px)` : 
      `clamp(8px, 1.6vw, 11px)`};
  }
`;

const zoomCards = keyframes`
  0% {
    transform: scale(1) rotate(var(--rotation));
  }
  100% {
    transform: scale(1.8) rotate(var(--rotation));
  }
`;

const PlayerCards = styled.div`
  display: flex;
  gap: ${props => `clamp(2px, 0.5vw, 4px)`};
  position: relative;
  justify-content: center;
  transform-origin: center center;
  
  > div {
    &:first-child {
      --rotation: -8deg;
      transform: rotate(var(--rotation));
      ${props => props.isShowdown && props.shouldRevealCards && css`
        animation: ${zoomCards} 0.3s ease-out forwards;
      `}
    }
    
    &:last-child {
      --rotation: 8deg;
      transform: rotate(var(--rotation));
      margin-left: ${props => `clamp(-15px, -3vw, -12px)`};
      ${props => props.isShowdown && props.shouldRevealCards && css`
        animation: ${zoomCards} 0.3s ease-out forwards;
      `}
    }
  }

  @media (max-width: 768px) {
    transform: scale(0.9);
    
    > div {
      &:last-child {
        margin-left: -10px;
      }
    }
  }
`;

const BetContainer = styled.div`
  position: absolute;
  top: -45px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 4px;
  z-index: 25;
`;

const DealerButton = styled.div`
  position: absolute;
  top: -20px;
  right: -15px;
  transform: none;
  background: rgba(0, 0, 0, 0.8);
  padding: 2px 6px;
  border-radius: 10px;
  font-size: 10px;
  color: #fff;
  white-space: nowrap;
  z-index: 20;
`;

const EmptySeat = styled(SeatWrapper)`
  background: rgba(0, 0, 0, 0.3);
  border: 2px dashed rgba(255, 255, 255, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
`;

const PlayerName = styled.div`
  font-weight: bold;
  color: #ffd700;
  font-size: 0.8rem;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 120px;
`;

const StatusIndicator = styled.div`
  font-size: ${props => props.isCurrentPlayer ? 
    `clamp(10px, 1.8vw, 13px)` : 
    `clamp(8px, 1.6vw, 11px)`};
  padding: 2px 6px;
  border-radius: 4px;
  margin-top: 2px;
  
  &.sitting-out {
    color: #ff6b6b;
    background: rgba(255, 107, 107, 0.1);
    border: 1px solid rgba(255, 107, 107, 0.2);
  }
  
  &.folded {
    color: #ffd700;
    background: rgba(255, 215, 0, 0.1);
  }
  
  &.all-in {
    color: #2ecc71;
    background: rgba(46, 204, 113, 0.1);
  }
`;

const WinningChips = styled.div`
  position: absolute;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  color: #4caf50;
  font-weight: bold;
  font-size: ${props => props.isCurrentPlayer ? 
    `clamp(14px, 2.2vw, 18px)` : 
    `clamp(12px, 2vw, 16px)`};
  background: rgba(0, 0, 0, 0.8);
  padding: 4px 8px;
  border-radius: 4px;
  border: 1px solid #4caf50;
  opacity: 0;
  z-index: 30;
  display: flex;
  align-items: center;
  gap: 4px;
  
  animation: ${props => props.show ? `
    fadeInUp 0.5s ease-out forwards,
    fadeOut 0.5s ease-out forwards 2s
  ` : 'none'};

  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translate(-50%, 20px);
    }
    to {
      opacity: 1;
      transform: translate(-50%, 0);
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
`;

const HandStrength = styled.div`
  font-size: ${props => props.isCurrentPlayer ? 
    `clamp(10px, 1.8vw, 13px)` : 
    `clamp(8px, 1.6vw, 11px)`};
  padding: 2px 6px;
  border-radius: 4px;
  margin-top: 2px;
  color: ${props => {
    switch (props.rank) {
      case 10: // Royal Flush
      case 9:  // Straight Flush
      case 8:  // Four of a Kind
        return '#00ff00'; // Bright green
      case 7:  // Full House
      case 6:  // Flush
      case 5:  // Straight
        return '#ffd700'; // Gold
      case 4:  // Three of a Kind
      case 3:  // Two Pair
        return '#ffa500'; // Bright orange
      default: // Pair or High Card
        return '#ff4444'; // Bright red
    }
  }};
  background: rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.2);
  font-weight: bold;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
`;

const TimerContainer = styled.div`
  position: absolute;
  top: -35px;
  right: -25px;
  width: 40px;
  height: 40px;
  z-index: 30;
`;

const CircleTimer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #1a1a1a;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 8px rgba(0,0,0,0.3);

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 3px solid ${props => props.timeRunningLow ? '#ff4444' : '#0096ff'};
    animation: ${props => props.timeRunningLow ? 'pulseLow 0.5s ease infinite' : 'pulse 1s ease infinite'};
    box-sizing: border-box;
  }

  @keyframes pulse {
    0% { transform: scale(1); opacity: 1; }
    50% { transform: scale(1.1); opacity: 0.7; }
    100% { transform: scale(1); opacity: 1; }
  }

  @keyframes pulseLow {
    0% { transform: scale(1); opacity: 1; }
    50% { transform: scale(1.2); opacity: 0.8; }
    100% { transform: scale(1); opacity: 1; }
  }
`;

const TimerProgress = styled.div`
  position: absolute;
  inset: 3px;
  border-radius: 50%;
  background: conic-gradient(
    ${props => props.timeRunningLow ? '#ff4444' : '#0096ff'} 
    ${props => props.progress}%, 
    transparent ${props => props.progress}%
  );
`;

const TimerInner = styled.div`
  position: absolute;
  inset: 6px;
  border-radius: 50%;
  background: #1a1a1a;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  color: ${props => props.timeRunningLow ? '#ff4444' : '#0096ff'};
`;

const PlayerSeat = ({ 
  player, 
  position, 
  isActive, 
  isDealer, 
  isCurrentPlayer, 
  seatIndex,
  isShowdown,
  isActivePlayer,
  gameState,
  winningAmount
}) => {
  const [progress, setProgress] = useState(100);
  const [handEvaluation, setHandEvaluation] = useState(null);
  const handEvaluator = new HandEvaluator();

  const {
    username,
    chips,
    currentBet,
    cards,
    folded,
    isAllIn,
    isActive: playerIsActive
  } = player || {};

  useEffect(() => {
    if (!isActive) {
      setProgress(100);
      return;
    }

    const startTime = gameState?.timer?.startTime || Date.now();
    const duration = gameState?.timer?.duration || 60000;

    const interval = setInterval(() => {
      const elapsed = Date.now() - startTime;
      const remaining = Math.max(0, duration - elapsed);
      const progressPercent = (remaining / duration) * 100;
      setProgress(progressPercent);

      if (remaining === 0) {
        clearInterval(interval);
      }
    }, 100);

    return () => clearInterval(interval);
  }, [isActive, gameState?.timer]);

  useEffect(() => {
    if (cards && cards.length === 2 && playerIsActive && !folded) {
      const evaluation = handEvaluator.evaluatePlayerHand(
        cards,
        gameState?.communityCards || []
      );
      setHandEvaluation(evaluation);
    } else {
      setHandEvaluation(null);
    }
  }, [cards, gameState?.communityCards, playerIsActive, folded]);

  if (!player) {
    return (
      <EmptySeat position={position}>
        <div>Seat {seatIndex + 1}</div>
      </EmptySeat>
    );
  }

  const shouldRevealCards = 
    isCurrentPlayer || 
    (isShowdown && isActivePlayer && !folded);

  return (
    <SeatWrapper 
      position={position} 
      isActive={isActive} 
      isCurrentPlayer={isCurrentPlayer}
      timeLeft={progress}
    >
      {isDealer && <DealerButton>D</DealerButton>}
      <PlayerInfo isCurrentPlayer={isCurrentPlayer}>
        <div className="player-name">{username || 'Unknown'}</div>
        <div className="stack">
          <ChipIcon viewBox="0 0 24 24">
            <path d="M12,0A12,12,0,1,0,24,12,12.013,12.013,0,0,0,12,0ZM22,12a10,10,0,0,1-.186,1.9l-1.932-.58a7.442,7.442,0,0,0,0-2.64l1.932-.58A10,10,0,0,1,22,12Zm-.76-3.816-1.93.579A8.048,8.048,0,0,0,15.237,4.69l.579-1.93A10.058,10.058,0,0,1,21.24,8.184ZM12,18a6,6,0,1,1,6-6A6.006,6.006,0,0,1,12,18ZM13.9,2.186l-.58,1.932a7.442,7.442,0,0,0-2.64,0L10.1,2.186a9.8,9.8,0,0,1,3.8,0ZM8.184,2.76l.579,1.93A8.048,8.048,0,0,0,4.69,8.763l-1.93-.579A10.058,10.058,0,0,1,8.184,2.76Z" />
          </ChipIcon>
          {chips || 0}
        </div>
        {!playerIsActive && (
          <StatusIndicator 
            isCurrentPlayer={isCurrentPlayer}
            className="sitting-out"
          >
            Sitting Out
          </StatusIndicator>
        )}
        {playerIsActive && folded && (
          <StatusIndicator 
            isCurrentPlayer={isCurrentPlayer}
            className="folded"
          >
            Folded
          </StatusIndicator>
        )}
        {playerIsActive && isAllIn && (
          <StatusIndicator 
            isCurrentPlayer={isCurrentPlayer}
            className="all-in"
          >
            All In
          </StatusIndicator>
        )}
        {handEvaluation && isCurrentPlayer && playerIsActive && !folded && (
          <HandStrength 
            isCurrentPlayer={isCurrentPlayer}
            rank={handEvaluation.rank}
          >
            {handEvaluation.name}
          </HandStrength>
        )}
      </PlayerInfo>
      
      {playerIsActive && cards && cards.length > 0 && (
        <PlayerCards 
          isShowdown={isShowdown}
          shouldRevealCards={shouldRevealCards}
        >
          {cards.map((card, index) => (
            <Card 
              key={index} 
              rank={card.rank} 
              suit={card.suit}
              size={isCurrentPlayer ? 'large' : 'small'}
              faceDown={!shouldRevealCards && (card.suit === 'hidden' || card.rank === 'hidden')}
            />
          ))}
        </PlayerCards>
      )}
      
      {currentBet > 0 && (
        <BetContainer>
          <Chip amount={currentBet} />
        </BetContainer>
      )}
      
      {winningAmount > 0 && (
        <WinningChips 
          isCurrentPlayer={isCurrentPlayer}
          show={true}
        >
          <ChipIcon viewBox="0 0 24 24">
            <path d="M12,0A12,12,0,1,0,24,12,12.013,12.013,0,0,0,12,0ZM22,12a10,10,0,0,1-.186,1.9l-1.932-.58a7.442,7.442,0,0,0,0-2.64l1.932-.58A10,10,0,0,1,22,12Zm-.76-3.816-1.93.579A8.048,8.048,0,0,0,15.237,4.69l.579-1.93A10.058,10.058,0,0,1,21.24,8.184ZM12,18a6,6,0,1,1,6-6A6.006,6.006,0,0,1,12,18ZM13.9,2.186l-.58,1.932a7.442,7.442,0,0,0-2.64,0L10.1,2.186a9.8,9.8,0,0,1,3.8,0ZM8.184,2.76l.579,1.93A8.048,8.048,0,0,0,4.69,8.763l-1.93-.579A10.058,10.058,0,0,1,8.184,2.76Z" />
          </ChipIcon>
          +{winningAmount}
        </WinningChips>
      )}
      {isActive && (
        <TimerContainer>
          <CircleTimer timeRunningLow={progress <= 50}>
            <TimerProgress 
              progress={progress} 
              timeRunningLow={progress <= 50}
            />
            <TimerInner timeRunningLow={progress <= 50}>
              {Math.ceil((progress / 100) * 60)}
            </TimerInner>
          </CircleTimer>
        </TimerContainer>
      )}
    </SeatWrapper>
  );
};

export default PlayerSeat; 