import React from 'react';
import { Helmet } from 'react-helmet-async';

export const DefaultSEO = () => (
  <Helmet>
    <html lang="en" />
    <meta charSet="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta name="theme-color" content="#1a1a1a" />
    
    {/* Default SEO tags */}
    <title>Online Poker Communities</title>
    <meta name="description" content="Join the best online poker communities. Play poker with friends, join clubs, and compete in tournaments." />
    
    {/* Open Graph / Facebook */}
    <meta property="og:site_name" content="Poker Communities" />
    <meta property="og:type" content="website" />
    
    {/* Twitter */}
    <meta name="twitter:card" content="summary_large_image" />
    
    {/* Favicon */}
    <link rel="icon" href="/favicon.ico" />
  </Helmet>
); 