import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import { communitiesService } from '../services/communitiesService';
import { useAuth } from '../contexts/AuthContext';

const CreateCommunityPage = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [whatsappGroup, setWhatsappGroup] = useState('');

  // Debug log to check user data
  useEffect(() => {
    console.log('Current user:', user);
  }, [user]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsSubmitting(true);

    try {
      if (!user?.id) {
        throw new Error('Please log in to create a community');
      }

      // Validate WhatsApp group link if provided
      if (whatsappGroup && !whatsappGroup.startsWith('https://chat.whatsapp.com/')) {
        throw new Error('Please enter a valid WhatsApp group invite link');
      }

      await communitiesService.createCommunity(
        { 
          name, 
          description,
          createdBy: user.id,
          whatsappGroup
        },
        user.id
      );
      navigate('/communities');
    } catch (err) {
      setError(err.message || 'Failed to create community');
    } finally {
      setIsSubmitting(false);
    }
  };

  // Add a check for user authentication
  if (!user) {
    return (
      <PageWrapper>
        <ContentWrapper>
          <ErrorMessage>
            Please log in to create a community
          </ErrorMessage>
        </ContentWrapper>
      </PageWrapper>
    );
  }

  return (
    <>
      <Helmet>
        <title>Create New Poker Community</title>
        <meta name="description" content="Create your own poker community. Set up your club, invite members, and start playing together." />
        <meta property="og:title" content="Create New Poker Community" />
        <meta property="og:description" content="Create your own poker community. Set up your club, invite members, and start playing together." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta name="twitter:card" content="summary_large_image" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>

      <PageWrapper>
        <ContentWrapper>
          <Header>
            <h1>Create New Community</h1>
          </Header>

          <FormWrapper onSubmit={handleSubmit}>
            {error && <ErrorMessage>{error}</ErrorMessage>}
            
            <FormGroup>
              <label>Community Name</label>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                minLength={3}
                maxLength={50}
                placeholder="Enter community name"
              />
            </FormGroup>

            <FormGroup>
              <label>Description</label>
              <textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
                minLength={10}
                maxLength={500}
                placeholder="Describe your community"
                rows={4}
              />
            </FormGroup>

            <FormGroup>
              <label>WhatsApp Group Link (Optional)</label>
              <input
                type="url"
                value={whatsappGroup}
                onChange={(e) => setWhatsappGroup(e.target.value)}
                placeholder="https://chat.whatsapp.com/..."
              />
              <HelpText>
                Create a WhatsApp group and paste its invite link here
              </HelpText>
            </FormGroup>

            <ButtonGroup>
              <CancelButton type="button" onClick={() => navigate('/communities')}>
                Cancel
              </CancelButton>
              <SubmitButton type="submit" disabled={isSubmitting}>
                {isSubmitting ? 'Creating...' : 'Create Community'}
              </SubmitButton>
            </ButtonGroup>
          </FormWrapper>
        </ContentWrapper>
      </PageWrapper>
    </>
  );
};

const PageWrapper = styled.div`
  min-height: 100vh;
  background: #1a1a1a;
  color: white;
  padding: 20px;
`;

const ContentWrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
`;

const Header = styled.div`
  margin-bottom: 30px;
  
  h1 {
    font-size: 2rem;
    margin: 0;
  }
`;

const FormWrapper = styled.form`
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(5px);
  border-radius: 12px;
  padding: 30px;
  border: 1.5px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
`;

const FormGroup = styled.div`
  margin-bottom: 20px;

  label {
    display: block;
    margin-bottom: 8px;
    color: #ccc;
  }

  input, textarea {
    width: 100%;
    padding: 12px;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 8px;
    color: white;
    font-size: 1rem;

    &:focus {
      outline: none;
      border-color: #2ecc71;
    }

    &::placeholder {
      color: rgba(255, 255, 255, 0.4);
    }
  }

  textarea {
    resize: vertical;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 15px;
  margin-top: 30px;
`;

const Button = styled.button`
  padding: 12px 24px;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.2s;
  
  &:active {
    transform: translateY(1px);
  }
`;

const SubmitButton = styled(Button)`
  flex: 1;
  background: rgba(40, 167, 69, 0.2);
  color: #2ecc71;
  border: 1.5px solid rgba(46, 204, 113, 0.3);
  backdrop-filter: blur(5px);
  
  &:hover {
    background: rgba(40, 167, 69, 0.3);
    transform: translateY(-1px);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const CancelButton = styled(Button)`
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  border: 1.5px solid rgba(255, 255, 255, 0.2);
  
  &:hover {
    background: rgba(255, 255, 255, 0.2);
    transform: translateY(-1px);
  }
`;

const ErrorMessage = styled.div`
  color: #e74c3c;
  background: rgba(231, 76, 60, 0.1);
  border: 1px solid rgba(231, 76, 60, 0.3);
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 20px;
`;

const HelpText = styled.div`
  font-size: 0.8rem;
  color: #ccc;
  margin-top: 8px;
`;

export default CreateCommunityPage;