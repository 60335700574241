import config from '../config';

const apiUrl = config.apiUrl;

export const communitiesService = {
  getAllCommunities: async () => {
    try {
      const response = await fetch(`${apiUrl}/api/communities`, {
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch communities');
      }

      const data = await response.json();
      return data.communities;
    } catch (error) {
      console.error('Error fetching communities:', error);
      throw error;
    }
  },

  createCommunity: async (communityData, userId) => {
    try {
      if (!userId) {
        throw new Error('User ID is required to create a community');
      }

      console.log('Creating community with data:', communityData);

      const response = await fetch(`${apiUrl}/api/communities`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          name: communityData.name,
          description: communityData.description,
          settings: {}
        })
      });

      // If response is not ok, handle the error before trying to parse JSON
      if (!response.ok) {
        const statusCode = response.status;
        const statusText = response.statusText;
        
        // Try to get more error details if available
        let errorMessage;
        try {
          const errorData = await response.json();
          errorMessage = errorData.message || errorData.error;
        } catch {
          errorMessage = `HTTP ${statusCode}: ${statusText}`;
        }
        
        throw new Error(errorMessage);
      }

      const data = await response.json();
      console.log('Success response:', data);
      return data.community;

    } catch (error) {
      console.error('Error creating community:', error);
      throw error;
    }
  },

  getCommunityById: async (communityId) => {
    try {
      const response = await fetch(`${apiUrl}/api/communities/${communityId}`, {
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Failed to fetch community');
      }

      const data = await response.json();
      return data.community;
    } catch (error) {
      console.error('Error fetching community:', error);
      throw error;
    }
  }
}; 