import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Modal } from '../ui/Modal';
import ReactDOM from 'react-dom';
import config from '../../config';

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background: #1a1a1a;
  overflow-y: auto;
`;

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const Header = styled.div`
  padding: 24px;
  text-align: center;
  background: rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  
  h2 {
    color: white;
    font-size: 24px;
    margin: 0;
  }
  
  p {
    color: #888;
    margin: 8px 0 0;
  }
`;

const TabBar = styled.div`
  display: flex;
  gap: 2px;
  padding: 0 24px;
  background: rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`;

const Tab = styled.button`
  padding: 16px 24px;
  background: none;
  border: none;
  color: ${props => props.active ? '#fff' : '#888'};
  font-size: 16px;
  cursor: pointer;
  position: relative;
  
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 3px;
    background: ${props => props.active ? '#2ecc71' : 'transparent'};
    border-radius: 3px 3px 0 0;
  }
`;

const Content = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 24px;
  padding-bottom: 0;
  
  > *:last-child {
    margin-bottom: 24px;
  }
`;

const Overview = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 24px;
  margin-bottom: 32px;
`;

const Podium = styled.div`
  display: flex;
  gap: 16px;
  justify-content: center;
  align-items: flex-end;
  margin: 40px 0;
  padding: 0 24px;
`;

const PodiumPlace = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${props => props.position === 1 ? '200px' : '160px'};

  .platform {
    background: ${props => {
      if (props.position === 1) return 'linear-gradient(to bottom, #f1c40f, #f39c12)';
      if (props.position === 2) return 'linear-gradient(to bottom, #bdc3c7, #95a5a6)';
      return 'linear-gradient(to bottom, #d35400, #e67e22)';
    }};
    height: ${props => props.position === 1 ? '120px' : props.position === 2 ? '90px' : '60px'};
    width: 100%;
    border-radius: 8px 8px 0 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding-top: 12px;
    
    .position {
      font-size: 24px;
      font-weight: bold;
      color: rgba(0, 0, 0, 0.5);
    }
  }

  .player-info {
    background: rgba(255, 255, 255, 0.05);
    width: 100%;
    padding: 16px;
    border-radius: 0 0 8px 8px;
    text-align: center;

    .name {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 8px;
      color: white;
    }

    .profit {
      font-size: 20px;
      font-weight: 600;
      color: #2ecc71;
    }
  }
`;

const StatCard = styled.div`
  background: rgba(255, 255, 255, 0.05);
  border-radius: 16px;
  padding: 24px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  flex-direction: column;
  gap: 8px;

  .title {
    color: #888;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
  }

  .value {
    color: ${props => props.highlight || 'white'};
    font-size: 28px;
    font-weight: 600;
  }

  .subtitle {
    color: #666;
    font-size: 14px;
  }
`;

const PlayerGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 24px;
  margin-top: 24px;
`;

const PlayerCard = styled.div`
  background: rgba(255, 255, 255, 0.05);
  border-radius: 16px;
  padding: 24px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 4px;
    background: ${props => {
      if (props.position === 1) return '#f1c40f';
      if (props.position === 2) return '#bdc3c7';
      if (props.position === 3) return '#d35400';
      return 'rgba(255, 255, 255, 0.1)';
    }};
  }
`;

const PlayerHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 20px;

  .position {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    background: ${props => {
      if (props.position === 1) return '#f1c40f';
      if (props.position === 2) return '#bdc3c7';
      if (props.position === 3) return '#d35400';
      return 'rgba(255, 255, 255, 0.1)';
    }};
    color: ${props => props.position <= 3 ? '#000' : '#fff'};
  }

  .name {
    font-size: 18px;
    font-weight: 500;
    color: white;
  }
`;

const PlayerStats = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;

  .stat {
    .label {
      color: #888;
      font-size: 13px;
      margin-bottom: 4px;
    }
    
    .value {
      color: ${props => props.color || 'white'};
      font-size: 16px;
      font-weight: 500;
    }
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 24px;
  right: 24px;
  background: rgba(255, 255, 255, 0.1);
  border: none;
  color: white;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s;
  
  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
`;

const CloseIcon = () => (
  <svg 
    width="24" 
    height="24" 
    viewBox="0 0 24 24" 
    fill="none" 
    stroke="currentColor" 
    strokeWidth="2" 
    strokeLinecap="round" 
    strokeLinejoin="round"
  >
    <line x1="18" y1="6" x2="6" y2="18"/>
    <line x1="6" y1="6" x2="18" y2="18"/>
  </svg>
);

const LoadingState = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  color: #888;
  font-size: 1.1rem;
`;

const ErrorState = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  color: #e74c3c;
  font-size: 1.1rem;
`;

const StatsModal = ({ isOpen, onClose, game, hideGameDuration }) => {
  const [activeTab, setActiveTab] = useState('overview');
  const [standings, setStandings] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchStandings = async () => {
      if (!isOpen || !game?.gameId) return;
      
      try {
        setLoading(true);
        const response = await fetch(`${config.apiUrl}/api/games/${game.gameId}/standings`, {
          credentials: 'include'
        });
        
        const data = await response.json();
        if (data.success) {
          setStandings(data.standings);
        } else {
          throw new Error(data.message || 'Failed to fetch standings');
        }
      } catch (err) {
        console.error('Error fetching standings:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchStandings();
  }, [isOpen, game?.gameId]);

  if (!game || !isOpen) return null;

  // Use standings data instead of game.finalStandings
  const topThree = standings ? standings.slice(0, 3) : [];

  const modalContent = (
    <ModalWrapper>
      <Container>
        <Header>
          <h2>Game Statistics</h2>
          <p>Table #{game.gameId}</p>
          <CloseButton onClick={onClose}>
            <CloseIcon />
          </CloseButton>
        </Header>

        <TabBar>
          <Tab 
            active={activeTab === 'overview'} 
            onClick={() => setActiveTab('overview')}
          >
            Overview
          </Tab>
          <Tab 
            active={activeTab === 'players'} 
            onClick={() => setActiveTab('players')}
          >
            Players
          </Tab>
          {!hideGameDuration && (
            <Tab 
              active={activeTab === 'hands'} 
              onClick={() => setActiveTab('hands')}
            >
              Game Details
            </Tab>
          )}
        </TabBar>

        <Content>
          {loading ? (
            <LoadingState>Loading statistics...</LoadingState>
          ) : error ? (
            <ErrorState>{error}</ErrorState>
          ) : (
            <>
              {activeTab === 'overview' && (
                <>
                  <Overview>
                    {!hideGameDuration && (
                      <StatCard>
                        <div className="title">Duration</div>
                        <div className="value">
                          {new Date(game.endTime).getTime() - new Date(game.createdAt).getTime() > 3600000
                            ? `${Math.round((new Date(game.endTime) - new Date(game.createdAt)) / 3600000)}h ${Math.round(((new Date(game.endTime) - new Date(game.createdAt)) % 3600000) / 60000)}m`
                            : `${Math.round((new Date(game.endTime) - new Date(game.createdAt)) / 60000)}m`
                          }
                        </div>
                        <div className="subtitle">Game length</div>
                      </StatCard>
                    )}
                    {/* ... other stat cards ... */}
                  </Overview>

                  <Podium>
                    {[2, 1, 3].map(position => {
                      const player = topThree[position - 1];
                      if (!player) return null;
                      
                      return (
                        <PodiumPlace key={position} position={position}>
                          <div className="platform">
                            <div className="position">{position}</div>
                          </div>
                          <div className="player-info">
                            <div className="name">{player.username}</div>
                            <div className="profit" style={{
                              color: player.netResult > 0 ? '#2ecc71' : '#e74c3c'
                            }}>
                              {player.netResult > 0 ? '+' : ''}{player.netResult.toLocaleString()}
                            </div>
                          </div>
                        </PodiumPlace>
                      );
                    })}
                  </Podium>
                </>
              )}

              {activeTab === 'players' && (
                <PlayerGrid>
                  {standings?.map((player, index) => (
                    <PlayerCard key={player.userId} position={index + 1}>
                      <PlayerHeader position={index + 1}>
                        <div className="position">{index + 1}</div>
                        <div className="name">{player.username}</div>
                      </PlayerHeader>
                      <PlayerStats>
                        <div className="stat">
                          <div className="label">Net Profit</div>
                          <div className="value" style={{ 
                            color: player.netResult > 0 ? '#2ecc71' : '#e74c3c'
                          }}>
                            {player.netResult > 0 ? '+' : ''}{player.netResult.toLocaleString()}
                          </div>
                        </div>
                        <div className="stat">
                          <div className="label">Hands Won</div>
                          <div className="value">{player.handsWon}</div>
                        </div>
                        <div className="stat">
                          <div className="label">Win Rate</div>
                          <div className="value">{player.winRate}%</div>
                        </div>
                        <div className="stat">
                          <div className="label">Total Buyins</div>
                          <div className="value">{player.totalBuyins || 1}</div>
                        </div>
                        <div className="stat">
                          <div className="label">Biggest Pot</div>
                          <div className="value">{player.biggestPot?.toLocaleString() || 0}</div>
                        </div>
                        <div className="stat">
                          <div className="label">Profit/Hand</div>
                          <div className="value">{player.profitPerHand}</div>
                        </div>
                      </PlayerStats>
                    </PlayerCard>
                  ))}
                </PlayerGrid>
              )}

              {activeTab === 'hands' && (
                <div>
                  <StatCard>
                    <div className="title">Buy In</div>
                    <div className="value">{game.settings.buyIn}</div>
                    <div className="subtitle">Starting chips</div>
                  </StatCard>
                  <StatCard>
                    <div className="title">Blinds</div>
                    <div className="value">{game.settings.smallBlind}/{game.settings.bigBlind}</div>
                    <div className="subtitle">Small/Big blind</div>
                  </StatCard>
                </div>
              )}
            </>
          )}
        </Content>
      </Container>
    </ModalWrapper>
  );

  return ReactDOM.createPortal(modalContent, document.body);
};

export default StatsModal; 