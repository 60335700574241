import React, { useState, useEffect } from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, Html } from '@react-three/drei';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { GameProvider } from './context/GameContext';
import { TableTop } from './TableParts/TableTop';
import { TableEdge } from './TableParts/TableEdge';
import { TableLegs } from './TableParts/TableLegs';
import { Cards } from './TableParts/Cards';
import { ChipStacks } from './TableParts/ChipStacks';
import { CardPositions } from './TableParts/CardPositions';
import { PlayerCards, VIEW_LAYOUTS } from './TableParts/PlayerCard';
import io from 'socket.io-client';
import config from '../config';

const ViewControls = ({ viewLayout, setViewLayout }) => (
  <Html position={[20, 4, 0]}>
    <div style={{
      background: 'rgba(0, 0, 0, 0.7)',
      padding: '10px',
      borderRadius: '5px',
      color: 'white',
      position: 'relative',
      right: '100%',
      whiteSpace: 'nowrap'
    }}>
      <div style={{ marginBottom: '5px' }}>View Layout:</div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
        {Object.entries(VIEW_LAYOUTS).map(([key, value]) => (
          <button
            key={key}
            onClick={() => setViewLayout(value)}
            style={{
              background: viewLayout === value ? '#4CAF50' : '#2196F3',
              color: 'white',
              border: 'none',
              padding: '5px 10px',
              borderRadius: '3px',
              cursor: 'pointer',
              width: '100%',
              textAlign: 'left'
            }}
          >
            {key.replace('_', ' ')}
          </button>
        ))}
      </div>
    </div>
  </Html>
);

const PokerTable = () => {
  const { gameId } = useParams();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [gameState, setGameState] = useState(null);
  const [socket, setSocket] = useState(null);
  const [error, setError] = useState(null);
  const [viewLayout, setViewLayout] = useState(VIEW_LAYOUTS.CIRCULAR);

  useEffect(() => {
    if (!user?.isAdmin) {
      navigate('/lobby');
      return;
    }

    const newSocket = io(config.apiUrl, {
      withCredentials: true,
      query: { gameId, userId: user.id, isAdmin: true }
    });

    newSocket.on('connect', () => {
      newSocket.emit('joinAdminView', { gameId, userId: user.id });
    });

    const gameStateEvents = ['gameState', 'hostGameState', 'gameStateUpdate'];
    gameStateEvents.forEach(event => {
      newSocket.on(event, (state) => {
        console.log(`Received ${event}:`, state);
        setGameState(state);
      });
    });

    newSocket.on('error', (error) => {
      setError(error.message);
    });

    newSocket.emit('requestGameState', { gameId });

    setSocket(newSocket);

    return () => {
      if (newSocket) {
        gameStateEvents.forEach(event => newSocket.off(event));
        newSocket.disconnect();
      }
    };
  }, [gameId, user, navigate]);

  if (error) return <div>Error: {error}</div>;
  if (!gameState) return <div>Loading...</div>;

  return (
    <Canvas
      camera={{ position: [0, 5, 5], fov: 75 }}
      style={{ width: '100%', height: '100vh' }}
    >
      <ambientLight intensity={1.4} />
      <pointLight position={[0, 8, 0]} intensity={4.6} />
      <pointLight position={[8, 4, 8]} intensity={0.4} />
      <pointLight position={[-8, 4, 8]} intensity={0.4} />
      <OrbitControls 
        enableZoom={true}
        enablePan={true}
        enableRotate={true}
        minDistance={2}
        maxDistance={20}
        minPolarAngle={0}
        maxPolarAngle={Math.PI / 2}
      />
      <GameProvider>
        <group>
          <TableTop />
          <TableEdge />
          <TableLegs />
          <Cards gameState={gameState} />
          <ChipStacks gameState={gameState} />
          <CardPositions gameState={gameState} />
          <PlayerCards gameState={gameState} viewLayout={viewLayout} />
          <ViewControls viewLayout={viewLayout} setViewLayout={setViewLayout} />
        </group>
      </GameProvider>
    </Canvas>
  );
};

export default PokerTable; 