import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import { communitiesService } from '../services/communitiesService';
import { useAuth } from '../contexts/AuthContext';
import Footer from '../components/Footer';

const CardFooter = styled.div`
  display: flex;
  justify-content: space-between;
  color: rgba(255, 255, 255, 0.6);
`;

const HeroSection = styled.div`
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
              url('/images/poker-hero.jpg') center/cover;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
`;

const LogoText = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: 24px;
  font-weight: bold;
  color: #2ecc71;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
`;

const HeroContent = styled.div`
  max-width: 800px;
  padding: 0 20px;
`;

const HeroTitle = styled.h1`
  font-size: 3.5rem;
  margin-bottom: 20px;
  color: #fff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
`;

const HeroSubtitle = styled.p`
  font-size: 1.5rem;
  color: #ccc;
  margin-bottom: 30px;
`;

const HeroButtons = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
`;

const Button = styled.button`
  padding: 12px 24px;
  border-radius: 8px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: all 0.2s;
  border: none;
  
  &:hover {
    transform: translateY(-2px);
  }
`;

const PrimaryButton = styled(Button)`
  background: #2ecc71;
  color: white;
  
  &:hover {
    background: #27ae60;
  }
`;

const SecondaryButton = styled(Button)`
  background: transparent;
  color: white;
  border: 2px solid #2ecc71;
  
  &:hover {
    background: rgba(46, 204, 113, 0.1);
  }
`;

const MainContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
`;

const SearchSection = styled.div`
  margin-bottom: 40px;
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  border: none;
  font-size: 1rem;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  transition: all 0.2s;
  
  &:focus {
    outline: none;
    background: rgba(0, 0, 0, 0.7);
  }
`;

const LoadingSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
`;

const Spinner = styled.div`
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid #fff;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const ErrorSection = styled.div`
  color: #e74c3c;
  margin-top: 40px;
`;

const FeaturedSection = styled.div`
  margin-bottom: 40px;
`;

const SectionTitle = styled.h2`
  font-size: 2rem;
  margin-bottom: 20px;
  color: #fff;
`;

const FeaturedGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
`;

const FeaturedCard = styled(Link)`
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(5px);
  border-radius: 12px;
  padding: 20px;
  border: 1.5px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  text-decoration: none;
  color: inherit;
  transition: all 0.2s;
  
  &:hover {
    background: rgba(0, 0, 0, 0.7);
    transform: translateY(-2px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  }

  h2 {
    margin: 0 0 10px 0;
    color: #fff;
  }

  p {
    color: #ddd;
    margin-bottom: 15px;
  }
`;

const FeaturedContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
`;

const Stats = styled.div`
  display: flex;
  justify-content: space-between;
  color: rgba(255, 255, 255, 0.6);
  font-size: 0.9rem;
`;

const StatItem = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const StatIcon = styled.span`
  font-size: 1.2rem;
`;

const CommunitiesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
`;

const CommunityCard = styled(Link)`
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(5px);
  border-radius: 12px;
  padding: 20px;
  border: 1.5px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  text-decoration: none;
  color: inherit;
  transition: all 0.2s;
  
  &:hover {
    background: rgba(0, 0, 0, 0.7);
    transform: translateY(-2px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  }

  h2 {
    margin: 0 0 10px 0;
    color: #fff;
  }

  p {
    color: #ddd;
    margin-bottom: 15px;
  }
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
`;

const Description = styled.p`
  color: #ddd;
  margin-bottom: 15px;
`;

const CreatorInfo = styled.span`
  color: #fff;
`;

const MemberCount = styled.span`
  color: #fff;
`;

const PageWrapper = styled.div`
  min-height: 100vh;
  background: #1a1a1a;
  color: white;
`;

const CommunitiesPage = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [communities, setCommunities] = useState([]);
  const [featuredCommunities, setFeaturedCommunities] = useState([]);
  const [communityGames, setCommunityGames] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchCommunityData = async () => {
      try {
        const data = await communitiesService.getAllCommunities();
        setCommunities(data);
        const featured = data.slice(0, 3);
        setFeaturedCommunities(featured);

        // Updated API call with better error handling
        const gamesData = {};
        await Promise.all(
          featured.map(async (community) => {
            try {
              const response = await fetch(`/api/games/community/${community._id}`);
              if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
              }
              const data = await response.json();
              if (data.success) {
                gamesData[community._id] = data.games.filter(game => 
                  ['WAITING', 'PLAYING'].includes(game.status)
                ).length;
              }
            } catch (err) {
              console.error(`Failed to fetch games for community ${community._id}:`, err);
              gamesData[community._id] = 0; // Set default value on error
            }
          })
        );
        setCommunityGames(gamesData);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCommunityData();
  }, []);

  const handleCreateCommunity = () => {
    if (!user) {
      localStorage.setItem('redirectAfterLogin', '/communities/new');
      navigate('/auth', { state: { from: '/communities/new' } });
      return;
    }
    navigate('/communities/new');
  };

  const filteredCommunities = communities.filter(community =>
    community.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    community.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <Helmet>
        <title>Poker Communities | Find Your Perfect Poker Club</title>
        <meta name="description" content="Join exclusive poker communities, create your own club, and play with like-minded players. Discover the best poker communities now." />
        <meta property="og:title" content="Poker Communities | Find Your Perfect Poker Club" />
        <meta property="og:description" content="Join exclusive poker communities, create your own club, and play with like-minded players." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta name="twitter:card" content="summary_large_image" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>

      <PageWrapper>
        {/* Hero Section */}
        <HeroSection>
          <LogoText>HNI POKER</LogoText>
          <HeroContent>
            <HeroTitle>Find Your Poker Community</HeroTitle>
            <HeroSubtitle>Join clubs, play games, connect with players</HeroSubtitle>
            <HeroButtons>
              <PrimaryButton onClick={handleCreateCommunity}>
                Create Community
              </PrimaryButton>
              {!user && (
                <SecondaryButton onClick={() => navigate('/auth')}>
                  Join Now
                </SecondaryButton>
              )}
            </HeroButtons>
          </HeroContent>
        </HeroSection>

        {/* Main Content */}
        <MainContent>
          {/* Search and Filter Section */}
          <SearchSection>
            <SearchInput
              type="text"
              placeholder="Search communities..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </SearchSection>

          {loading ? (
            <LoadingSection>
              <Spinner />
              <span>Loading communities...</span>
            </LoadingSection>
          ) : error ? (
            <ErrorSection>{error}</ErrorSection>
          ) : (
            <>
              {/* Featured Communities */}
              {featuredCommunities.length > 0 && (
                <FeaturedSection>
                  <SectionTitle>Featured Communities</SectionTitle>
                  <FeaturedGrid>
                    {featuredCommunities.map(community => (
                      <FeaturedCard key={community._id} to={`/communities/${community.slug}`}>
                        <FeaturedContent>
                          <h2>{community.name}</h2>
                          <p>{community.description}</p>
                          <Stats>
                            <StatItem>
                              <StatIcon>👥</StatIcon>
                              {community.members.length} members
                            </StatItem>
                            <StatItem>
                              <StatIcon>🎮</StatIcon>
                              {communityGames[community._id] || 0} active games
                            </StatItem>
                          </Stats>
                        </FeaturedContent>
                      </FeaturedCard>
                    ))}
                  </FeaturedGrid>
                </FeaturedSection>
              )}

              {/* All Communities */}
              <SectionTitle>All Communities</SectionTitle>
              <CommunitiesGrid>
                {filteredCommunities.map(community => (
                  <CommunityCard key={community._id} to={`/communities/${community.slug}`}>
                    <CardContent>
                      <h2>{community.name}</h2>
                      <Description>{community.description}</Description>
                      <CardFooter>
                        <CreatorInfo>
                          Created by {community.createdBy.username}
                        </CreatorInfo>
                        <MemberCount>
                          {community.members.length} members
                        </MemberCount>
                      </CardFooter>
                    </CardContent>
                  </CommunityCard>
                ))}
              </CommunitiesGrid>
            </>
          )}
        </MainContent>
      </PageWrapper>
      <Footer />
    </>
  );
};

export default CommunitiesPage; 