import React from 'react';
import styled from 'styled-components';

const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  margin: 24px 0;
  width: 100%;
`;

const PageButton = styled.button`
  padding: 8px 16px;
  background: ${props => props.active ? 'rgba(46, 204, 113, 0.2)' : 'rgba(255, 255, 255, 0.1)'};
  color: ${props => props.active ? '#2ecc71' : '#fff'};
  border: 1.5px solid ${props => props.active ? 'rgba(46, 204, 113, 0.3)' : 'rgba(255, 255, 255, 0.2)'};
  border-radius: 8px;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  opacity: ${props => props.disabled ? 0.5 : 1};
  transition: all 0.2s;

  &:hover:not(:disabled) {
    background: ${props => props.active ? 'rgba(46, 204, 113, 0.3)' : 'rgba(255, 255, 255, 0.2)'};
    transform: translateY(-1px);
  }
`;

const PageInfo = styled.span`
  color: #ccc;
  font-size: 0.9rem;
`;

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  return (
    <PaginationContainer>
      <PageButton
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        Previous
      </PageButton>

      <PageInfo>
        Page {currentPage} of {totalPages}
      </PageInfo>

      <PageButton
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        Next
      </PageButton>
    </PaginationContainer>
  );
};

export default Pagination; 